import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { Chip, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CustomerForm } from './ProfileFormComponent';


export const UserProfileForm: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Stack padding={2} paddingLeft={3} paddingRight={3} spacing={4}>
            <Chip
                    avatar={<ArrowCircleLeftIcon/>}
                    label="Back"
                    variant="outlined"
                    sx={{
                        width: '100px',
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                    onClick={()=>{navigate("/profile")}}
                />
            <CustomerForm/>
        </Stack>
    )
}