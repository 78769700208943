import { useMemo } from "react";
import { FoodMenuItem } from "../interfaces/FoodItem";
import { MenuPageCardSection } from "../interfaces/MenuPageCardSection";
import { useFoodItemsByOfferId } from "./useFoodItemsByOfferId";

export const useFoodItemsSectionsByOfferId = (offerId: string) => {
    console.log(offerId)
    const {filteredItemsByOfferId: offerItems, isLoading, error} = useFoodItemsByOfferId(offerId);
    const foodItemsBySection: MenuPageCardSection[] = useMemo(() => {
        return getFoodItemsBySection(offerItems || []);
      }, [offerItems, isLoading, offerItems, offerId]);
      console.log(error)
      return {foodItemsBySection, isLoading, error};
    
}

export const getFoodItemsBySection = (foodItems: FoodMenuItem[]): MenuPageCardSection[] => {
    const sectionsMap: Map<string, MenuPageCardSection> = new Map<string, MenuPageCardSection>();
    for (const foodItem of Object.values(foodItems)) {
        for (const section of Object.values(foodItem.sections)) {
            if (!sectionsMap.has(section)) {
                const foodItemSection: MenuPageCardSection = {title: section, foodItems: [], titleId: ""}
                sectionsMap.set(section, foodItemSection);
            }
            sectionsMap.get(section)!.foodItems.push(foodItem);
        }
    }
    // Convert the grouped sections to the desired interface format
    const sections: MenuPageCardSection[] = [];
    sectionsMap.forEach( (k: MenuPageCardSection, v: string) => (sections.push({
        title: k.title,
        foodItems: k.foodItems,
        titleId: ''
    })));
    return sections;
}