import { useDispatch, useSelector } from "react-redux";
import useCookie from "./useCookie";
import { selectCustomerFavorites } from "../redux/customerFavourites/CustomerFavouritesSelectors";
import { FoodMenuItem } from "../interfaces/FoodItem";
import { optimisticAddToFavorites, optimisticRemoveFromFavorites } from "../redux/customerFavourites/CustomerFavouritesSlice";
import { AppDispatch } from "../redux/stores/mainStore";
import { addFavorite, removeFavorite } from "../redux/customerFavourites/CustomerFavouriteThunks";

export const useAddToFavourite = ():[(foodItem: FoodMenuItem) => boolean, (foodItem: FoodMenuItem, action: 'add' | 'remove') => void] => {
    const {getCookie} = useCookie();
    const storeId = getCookie('storeId') || "";
    const favorites = useSelector(selectCustomerFavorites);
    const dispatch = useDispatch<AppDispatch>();

    const isFavorite = (foodItem: FoodMenuItem) : boolean => favorites.some(favorite => favorite.dish_item_id == foodItem.id);

    const handleFavoriteClick = (foodItem: FoodMenuItem, action: 'add' | 'remove') => {
        const favoriteItem = {
            dish_item_name: foodItem.dishName,
            dish_item_id: foodItem.id,
            dish_item_price: foodItem.dishPriceText,
            sections: [...foodItem.sections],
            store_id: storeId
        };

        if (action == 'remove') {
            dispatch(optimisticRemoveFromFavorites(favoriteItem.dish_item_id));
            dispatch(removeFavorite(favoriteItem)); // Revert if API call fails
        } else {
            dispatch(optimisticAddToFavorites(favoriteItem));
            dispatch(addFavorite(favoriteItem)); // Revert if API call fails
        }
    };
    return [isFavorite, handleFavoriteClick];
}