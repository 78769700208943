import { useBaseMutation } from "../../../../hooks/useBaseMutation";


export const usePutStoreMenuItems = () => {
    
    return useBaseMutation(
        'put',
        'https://api.menufy.link/restaurantBackend/stores/menuItems', // Endpoint
        // 'storeInfo',
        // Data transformation function
    );
};
