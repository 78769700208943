import React from 'react';
import { Stack, Skeleton } from "@mui/material";

interface ListStoreSkeletonProps {
    count: number; // Number of Skeletons to display
}

export const ListStoreSkeleton: React.FC<ListStoreSkeletonProps> = ({ count }) => {
    return (
        <Stack spacing={2}>
            {Array.from({ length: count }, (_, index) => (
                <Skeleton key={index} variant="rectangular" width="100%" height={30} />
            ))}
        </Stack>
    );
};
