import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { NotFoundPage } from "./components/common/NotFoundPage"
import { Logout } from "./redirectApp/pages/Logout";
import Redirect from "./redirectApp/pages/Redirect";
import { OauthLogin } from "./redirectApp/pages/OauthLogin";

export const RedirectApp = () => {

	return (
		<BrowserRouter>
			<Authenticator.Provider>
				<Routes>
					<Route path="/oauthlogin" element={<OauthLogin/>} />
					<Route path="/redirect" element={<Redirect/>} />
					<Route path="/logout" element={<Logout/>} />
					<Route path="/*" element={<NotFoundPage/>} />
				</Routes>
			</Authenticator.Provider>
		</BrowserRouter>
	)
}