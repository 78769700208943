// export const ListStoresForPayment: React.FC = () => {
//     return (
//         <>s</>
//     )
// }

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box } from '@mui/material';
import { useFetchUserStores } from '../../stores/hooks/useFetchUserStores';
import { ListStoreSkeleton } from './ListStoresSkeletonPage';

export interface StoresInfo {
    restaurant_name: string;
    store_name: string;
    store_id: string;
}


export const ListStoresForPayment: React.FC = () => {
    
    const [searchText, setSearchText] = useState('');
    const { data, isLoading } = useFetchUserStores();

    const navigate = useNavigate();
    const userStores = data as StoresInfo[];


    // Filter restaurants by search text
    const filteredStores = useMemo(() => {
        if (userStores) {
            return userStores.filter(store => 
                store.store_name.toLowerCase().includes(searchText.toLowerCase()) || 
                store.restaurant_name.toLowerCase().includes(searchText.toLowerCase())
            )
        } else return [];
    }, [userStores, searchText]);

    // Handle row click
    const handleRowClick = (storeId: string) => {
        navigate(`/payments/stores/${storeId}`);
    };

    if (isLoading) {
        return <ListStoreSkeleton count={30}/>
    }

    return (
        <>
            <Box mb={2}>
                <TextField 
                    label="Search by Name" 
                    variant="outlined" 
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Store Name</TableCell>
                            <TableCell>Restaurant Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredStores.map((store) => (
                            <TableRow key={store.store_id} hover onClick={() => handleRowClick(store.store_id)}>
                                <TableCell component="th" scope="row">
                                    {store.store_name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {store.restaurant_name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

