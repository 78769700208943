import { AppBar, ListItemAvatar, Divider, IconButton, InputAdornment, ListItemText, Paper, Stack, TextField, Typography, styled, Fade, useScrollTrigger } from "@mui/material"
import WidgetsIcon from '@mui/icons-material/Widgets';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useEffect, useState } from "react";
import React from "react";
import { useAutocomplete } from '@mui/base/useAutocomplete';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useMenuItemsQuery } from "../../../../hooks/useMenuItemsQuery";
import { FoodMenuItem } from "../../../../interfaces/FoodItem";
import { useNavigate } from "react-router-dom";
import { ITEM_DETAIL_PATH } from "../../../../constants/pathConstants";
import { Null } from "./LandingPageBody";
import { Diversity2Outlined } from "@mui/icons-material";
import { VegNonVegIcon } from "../common/CustomIcons";
import { ShareCartOptions } from "../common/ShareCartOptions";
import { TransitionGroup } from 'react-transition-group';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';


const GrayTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#49454F',
    textAlign: 'left',
  }));

const BlackTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left',
}));

export const DineInLandingPageNavBar = () => {
  
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    const [showSecondSearchBar, setShowSecondSearchBar] = useState(false);

    const handleClick = () => {
        const val = showSecondSearchBar;
        setShowSecondSearchBar(!val);
    }

    return (
        <AppBar position="sticky" sx={{backgroundColor:'#fff', border: '0px', boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)'}}>
            <TransitionGroup>
                <Stack direction='row' padding={3} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Stack direction='row' spacing={2}>
                        <Avatar sx={{ backgroundColor: '#1C1C1E'}}><WidgetsIcon/></Avatar>
                        <Stack direction='column'>
                            <GrayTitleText>Get Ready to</GrayTitleText>
                            <BlackTitleText>Grab your today's meal</BlackTitleText>
                        </Stack>
                    </Stack>
                    {trigger && (<SearchRoundedIcon sx={{color: 'black'}} onClick={handleClick}/>)}
                </Stack>
            </TransitionGroup>
            <TransitionGroup>
                <Stack direction='column' padding={3} spacing={3} sx={{paddingY: '0px', display: trigger? 'none' : 'flex'}}  marginBottom={2} >
                    {/* <div style={{ position: 'relative' }}> */}
                    <ShareCartOptions/>
                    <SearchComponent/>
                </Stack>
                <Stack direction='column' padding={3} spacing={3} marginBottom={2} sx={{paddingY: '0px', display: trigger && showSecondSearchBar? 'flex': 'none'}}>
                    <SearchComponent isFocus={true}/>
                </Stack>
            </TransitionGroup>
        </AppBar>
    )
}
export interface SearchComponentProps {
    isFocus?: boolean;
}
export const SearchComponent: React.FC<SearchComponentProps> = ({isFocus}) => {
    const [value, setValue] = useState<string>("");
    const {data: foodItems, isLoading} = useMenuItemsQuery();
    const [suggestions, setSuggestions] = useState<FoodMenuItem[]>([]);

    const handleInputChange = (value: string) => {
        setValue(value);
        if (value.length > 0) {
            const filteredSuggestions = foodItems?.filter(item => item.dishName.toLowerCase().startsWith(value.toLowerCase())) || [];
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>
                    
                    <TextField
                        id="filled-search"
                        label="Search"
                        type="text"
                        // {...getInputProps()}
                        value={value}
                        sx={{
                            width: '100%',
                            '& label.Mui-focused' : {
                                color:"#6750A4",
                                borderColor: '#6750A4'
                                },
                                '& label.MuiFormLabel-root' : {
                                    color: '#6750A4'
                                }
                                ,'& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#6750A4',
                                    },
                                    '&:hover fieldset': {
                                    borderColor: '#6750A4',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#6750A4',
                                    },
                                },
                        }}
                        // onChange={handleInputChange}
                        onChange={(e) => {handleInputChange(e.target.value)}}
                        variant="outlined"
                        InputProps={{
                            // ...getInputProps(),
                            startAdornment: (<InputAdornment position="start"> <ManageSearchIcon  /> </InputAdornment>),
                            endAdornment: value && (
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setValue("")}
                                ><CancelOutlinedIcon sx={{color:'#49454F'}}/></IconButton>
                                )
                            }}
                    />
                    {suggestions.length > 0 && value && (<FoodItemCardAutoComplete suggestions={suggestions} />) }
                </div>
    )
}

export type FoodItemCardAutoCompleteProps = {
    suggestions: FoodMenuItem[]
}

export const MenuItemTitle = styled('span')(({ theme }) => ({
    fontSize: '16px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight:600,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left'
  }));

export const MenuItemPrice = styled('span')(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#218D0F'
}));

const MenuItemRatingNumber = styled('span')(({ theme }) => ({
  fontFamily: 'Google Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#F5A61D',
  textAlign: 'center',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
}));

export const FoodItemCardAutoComplete: React.FC<FoodItemCardAutoCompleteProps> = ({ suggestions }) => {
    const navigate = useNavigate();
    return (
        <List sx={{ position: 'absolute', width: '100%', zIndex: 1,margin: '0!important', padding: '0!important', backgroundColor: '#fffff', maxHeight: '30vh', overflowY: 'scroll'}}>
                    {suggestions.map((item, index) => (
                        <ListItem alignItems="flex-start" disableGutters={true} sx={{backgroundColor: '#ffffff!important'}} divider={true} onClick={()=>{navigate(ITEM_DETAIL_PATH.replace(":itemId", item.id))}}>
                            <ListItemAvatar><Avatar src={item.dishImageURL} variant="square"/></ListItemAvatar>
                            <ListItemText
                                sx={{backgroundColor:'#ffffff', color:'black'}}
                                primary={<MenuItemTitle sx={{display: 'flex', alignItems:'center', lineHeight:'20px'}}><VegNonVegIcon foodType={item.dishType}/> {item.dishName}</MenuItemTitle>} 
                                secondary={
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                    <MenuItemRatingNumber sx={{display: 'inline-flex', alignItems: 'center'}}>
                                    <StarsRoundedIcon sx={{color: '#288D0F', fontWeight: '700', height: '20px'}}/> {item.dishRatingText}
                                    </MenuItemRatingNumber>
                                    {<span style={{marginLeft: '2px', marginRight: '5px'}}>&nbsp;<Null/>&nbsp;</span>} 
                                    <MenuItemPrice>

                                    {item.dishPriceText}
                                    </MenuItemPrice>
                                    </div>
                                }
                                />
                        </ListItem>

                    ))}
        </List>)
}