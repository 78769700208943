import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from "@mui/material"
import { CustomizationStep, StepOption } from "../../../../../../interfaces/FoodItem"
import { FieldArray, useField, useFormikContext } from "formik";
import { v4 as uuidv4 } from 'uuid';
import { CustomizationDetailsModal } from "./CustomizationForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FoodMenuItemInput } from "../../hooks/useMenuForm";


export interface ListCustomizationProps {
    index: number;
}

export interface CustomizationStepOptionWithCurrentstep extends StepOption {
    page: string;
}


const getCustomizations = (customizationSteps: CustomizationStep[] | undefined): CustomizationStepOptionWithCurrentstep[] => {
    const steps: CustomizationStepOptionWithCurrentstep[] = [];
    if (customizationSteps && customizationSteps.length) {
        customizationSteps.forEach(option => {
            option.stepOptions.forEach(stepOption => {
                steps.push({...stepOption, page: option.step.toString()})
            })
        });
    }
    return steps;
}

export const ListCustomization: React.FC<ListCustomizationProps> = ( {index} ) => {
    const [dishCustomizationField, ,] = useField<CustomizationStep[]>(`foodMenuItemsInputs.${index}.customizeOptions`);
    const customizationOptions = getCustomizations(dishCustomizationField.value);
    const { setFieldValue } = useFormikContext<{ foodMenuItemsInputs: FoodMenuItemInput[] }>(); // useFormikContext to get setFieldValu
      
    const navigate = useNavigate();
    const { id: storeId, foodMenuItemId,  } = useParams();

    // console.log()
    const handleRowClick = (customizationOption: CustomizationStepOptionWithCurrentstep): void => {
        const stepIndex = dishCustomizationField.value.findIndex(option => option.step.toString() == customizationOption.page);
        const customizationIndexInThatStep = dishCustomizationField.value[stepIndex].stepOptions.findIndex(stepOption => stepOption.id === customizationOption.id)
        // const customizationOptionPath = `foodMenuItemsInputs.${index}.customizeOptions.${stepIndex}.stepOptions.${customizationIndexInThatStep}`;
        // setSelectedCustomizationPath(customizationOptionPath);
        // setModalOpen(true);
        navigate(`/menu/stores/${storeId}/editItem/${foodMenuItemId}/foodItemIndex/${index}/customization/stepIndex/${stepIndex}/position/${customizationIndexInThatStep}`);
    }
    const newCustomizationStep = (): StepOption => ({
        id: uuidv4() ,// Example ID generation, adjust as needed
        title: "New Customization Step",
        minSelect: 1,
        maxSelect: 1,
        truncateAt: "5",
        items: [], // Start with an empty array or predefined items
    });

    const addNewStepOptionToStep = (stepNumber: number) => {
        const stepIndex = dishCustomizationField.value ? dishCustomizationField.value.findIndex(option => option.step === stepNumber) : -1;
        if (stepIndex != -1) {
            const newCustomizeOptions = [...dishCustomizationField.value];
            newCustomizeOptions[stepIndex].stepOptions.push(newCustomizationStep());
            setFieldValue(`foodMenuItemsInputs[${index}].customizeOptions`, newCustomizeOptions);
        } else {
            const newCustomizeOptions = dishCustomizationField.value && dishCustomizationField.value.length ? [...dishCustomizationField.value]: [{step: 1, stepOptions: []}];
            console.log(newCustomizeOptions)
            newCustomizeOptions[0].stepOptions.push(newCustomizationStep());
            setFieldValue(`foodMenuItemsInputs[${index}].customizeOptions`, newCustomizeOptions);
        }
    }

    const moveCustomizationOption = (action: 'up' | 'down' | 'prevPage' | 'nextPage' | 'remove', stepIndex: number, selectedId: string) => {
        let customizationOptionsCopy = [...dishCustomizationField.value];
        const optionIndex = customizationOptionsCopy[stepIndex].stepOptions.findIndex(option => option.id == selectedId);
        console.log('finding option ...' + optionIndex)
        switch (action) {
            case 'up':
            case 'down': {
                const targetIndex = optionIndex + (action === 'up' ? -1 : 1);
                console.log('step' + stepIndex);
                console.log('new index' + targetIndex + 'current ' + optionIndex);
                if (targetIndex == customizationOptionsCopy[stepIndex].stepOptions.length || targetIndex < 0) return;
                if (targetIndex >= 0 && targetIndex < customizationOptionsCopy[stepIndex].stepOptions.length) {
                    // Swap the stepOptions within the same step
                    const temp = customizationOptionsCopy[stepIndex].stepOptions[optionIndex];
                    customizationOptionsCopy[stepIndex].stepOptions[optionIndex] = customizationOptionsCopy[stepIndex].stepOptions[targetIndex];
                    customizationOptionsCopy[stepIndex].stepOptions[targetIndex] = temp;
                }
                break;
            }
            case 'prevPage':
            case 'nextPage': {
                const targetStepIndex = action === 'prevPage' ? stepIndex - 1 : stepIndex + 1;
                const newStep = stepIndex + (action === 'prevPage' ? -1 : 1);
            
                // Handle moving to a new next step not currently in the list
                if (targetStepIndex > customizationOptionsCopy.length || targetStepIndex < 0 || customizationOptionsCopy[stepIndex].stepOptions.length == 1) {
                    return  // Create a new step at the end
                }

                const optionToMove = customizationOptionsCopy[stepIndex].stepOptions.splice(optionIndex, 1)[0];


                if (targetStepIndex == customizationOptionsCopy.length) {
                    console.log('new step addin..' + newStep)
                    customizationOptionsCopy.push({ step: newStep + 1, stepOptions: [optionToMove] });
                    break;
                }
      
                // Move the option to the target step
                // const targetStepOptionIndex = customizationOptionsCopy.findIndex(opt => opt.step === newStep);
                customizationOptionsCopy[targetStepIndex].stepOptions.push(optionToMove);
            
                // Re-sort steps after moving
                // 
                break;
            }
            case 'remove': {
                customizationOptionsCopy[stepIndex].stepOptions.splice(optionIndex, 1);
                // Optionally remove the step if it's empty now
                if (customizationOptionsCopy[stepIndex].stepOptions.length === 0) {
                    customizationOptionsCopy.splice(stepIndex, 1);
                }
                break;
            }
            default:
                break;
        }
        customizationOptionsCopy.sort((a, b) => a.step - b.step);
        // Finally, update the formik state
        setFieldValue(`foodMenuItemsInputs[${index}].customizeOptions`, customizationOptionsCopy);
        
    };

    const findPosition = (stepId: number, selectionId: string) => {
        let customizationOptionsCopy = [...dishCustomizationField.value];
        return customizationOptionsCopy[stepId].stepOptions.findIndex(option => option.id == selectionId);
    }
      


    return (
        <Stack padding={2} spacing={2}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%'}}>
                <h4>Add Customization Step</h4>
                <Button variant="contained" onClick={() => addNewStepOptionToStep(1)}>
                    Add New Customization Step
                </Button>
            </Box>
            <FieldArray
                name={`foodMenuItemsInputs.${index}.customizeOptions`}
                render={({push}) => (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Customization Name</TableCell>
                                    <TableCell>Customization Page Step</TableCell>
                                    <TableCell>Position in current step</TableCell>
                                    <TableCell>Move Page Up</TableCell>
                                    <TableCell>Move Page Down</TableCell>
                                    <TableCell>Move Choice up</TableCell>
                                    <TableCell>Move Choice Down</TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {customizationOptions.map((customizationOption, optionIndex) => (
                                    <TableRow key={customizationOption.id} hover onClick={() => handleRowClick(customizationOption)} style ={ optionIndex % 2? { background : "#fdffe0" }:{ background : "white" }}>
                                        <TableCell component="th" scope="row">
                                            {customizationOption.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {customizationOption.page}
                                        </TableCell>
                                        <TableCell>
                                            {findPosition(Number(customizationOption.page) - 1, customizationOption.id)}
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={(event) => { event.stopPropagation(); moveCustomizationOption('up', Number(customizationOption.page) - 1, customizationOption.id)}}>Move Up</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={(event) => { event.stopPropagation(); moveCustomizationOption('down', Number(customizationOption.page) - 1, customizationOption.id)}}>Move Down</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={(event) => { event.stopPropagation(); moveCustomizationOption('prevPage', Number(customizationOption.page) - 1, customizationOption.id)}}>Prev Page</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={(event) => { event.stopPropagation(); moveCustomizationOption('nextPage', Number(customizationOption.page) - 1, customizationOption.id)}}>Next Page</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={(event) => { event.stopPropagation(); moveCustomizationOption('remove', Number(customizationOption.page) - 1, customizationOption.id)}} color="error">Remove</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            />
            <CustomizationDetailsModal/>
        </Stack>
    )
}


