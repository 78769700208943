import React, { useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { FoodMenuItem } from '../../../../interfaces/FoodItem';
import { useNavigate } from 'react-router-dom';
import { MENU_ITEM_CUSTOMIZATION_PATH } from '../../../../constants/pathConstants';

export interface AddButtonProps {
  incrementHandler: (...args: any[]) => void,
  decrementHandler: (...args: any[]) => void,
  count: number
  color?: string
  backgroundColor? : string
}
const AddButton: React.FC<AddButtonProps> = ({ count,  incrementHandler, decrementHandler, color, backgroundColor }) => {
    // const [count, setCount] = useState(0);
    // const navigate = useNavigate();
  
    // const handleIncrement = () => {
    //   setCount(count + 1);
    // };
  
    // const handleDecrement = () => {
    //   if (count > 0) {
    //     setCount(count - 1);
    //   }
    // };
  
    // const handleIncrementBase = () => {
    //   if (item.customizeOptions && item.customizeOptions.length > 0) {
    //     navigate(MENU_ITEM_CUSTOMIZATION_PATH.replace(':itemId', item.id).replace(":stepId", item.customizeOptions[0].step))
    //   }
    //   if (count == 0) {
    //     setCount(count + 1);
    //   }
    // };
    return (
      <div
        style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            background: backgroundColor || 'inherit',
            padding: '8px 8px',
            border: '1px solid #ccc',
            color: color || '#6750A4', 
            textAlign: 'center',
            fontFamily:'Google Sans',
            fontSize: '16px',
            fontStyle:'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            minWidth: '80px',
            borderRadius: '10px',
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16)'
        }}
      //   onClick={handleIncrement}
      >
        <RemoveCircleRoundedIcon onClick={decrementHandler} sx={{color: count === 0? backgroundColor || '#fff' : 'inherit' }}/>
        <div style={{margin: 'auto'}} onClick={incrementHandler}>{ count === 0 ? 'Add' : count }</div>
        <AddCircleRoundedIcon onClick={incrementHandler} sx={{color: count === 0? backgroundColor || '#fff' : 'inherit' }}/>
      </div>
    );
  };
  
  export default AddButton;