import { Box, Skeleton, Stack } from "@mui/material"

export const LandingPageSkeleton = () => {
    const rows = 3, columns = 10, heroColumn = 10;
    return (
        <Stack spacing={2}>
            <Box sx={{
                            display: 'flex',
                            paddingLeft: '2px',
                            overflowX: 'auto', // Enable horizontal scrolling
                            gap: '1rem', // Adjust the gap between menu items
                            paddingY: '8px', // Add some padding on the top and bottom
                            // '-ms-overflow-style': 'none', // Hide the scrollbar for IE and Edge
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                width: '0px', // Hide the scrollbar for Chrome, Safari, and Opera
                            },
                        }}
            >
                <Stack direction="row" gap={0.8}>
                {Array.from(new Array(heroColumn)).map((_, index) => (
                    <Skeleton key={index} variant="rounded" width={230} height={230} />
                ))}
            </Stack>
            </Box>
            {/* <Stack direction="row" gap={0.8}>
                {Array.from(new Array(heroColumn)).map((_, index) => (
                    <Skeleton variant="rounded" width={230} height={230} />
                ))}
            </Stack> */}
            {Array.from(new Array(rows)).map((_, index) => (
                <Box sx={{
                    display: 'flex',
                    paddingLeft: '2px',
                    overflowX: 'auto', // Enable horizontal scrolling
                    gap: '1rem', // Adjust the gap between menu items
                    paddingY: '8px', // Add some padding on the top and bottom
                    // '-ms-overflow-style': 'none', // Hide the scrollbar for IE and Edge
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0px', // Hide the scrollbar for Chrome, Safari, and Opera
                    },
                }}
                key={index}
                >
                    <Stack direction="row" gap={0.8}>
                        {Array.from(new Array(columns)).map((_, index) => (
                            <Skeleton key={index} variant="rounded" width={130} height={130} />
                        ))}
                    </Stack>
                </Box>
            ))}
        </Stack>
    )
}