import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { QueryClientProvider, useQueryClient } from 'react-query'; // Import useQueryClient
import { Navigate, useNavigate } from 'react-router-dom';
import { useFetchUserSubscriptions } from '../hooks/useFetchUserSubscriptions';
import { LoaderPage } from '../rootDomain/LoderPage';
import { RestaurantAdminHomePage } from './RestuarantAdminHomePage';
import { Auth } from 'aws-amplify';
import { LoggedInBuyPlan } from './pages/buyplan/loggedIn/LoggedInBuyPage';

const RequireAdminAuth = (): JSX.Element => {
	const location = window.location.href;
	let { route, user } = useAuthenticator((context) => [context.route]);

	const queryClient = useQueryClient(); // Get the queryClient reference
	// const navigate = useNavigate();

	const [subscriptions, setSubscriptions] = useState<any>([]);

	const { data, isLoading } = useFetchUserSubscriptions();

	useEffect(() => {
		if (user != undefined) {
			// refetch()
			queryClient.invalidateQueries(['userSubscriptions'])
			// console.log('will refetch...')
		}
	}, [route, user]);

	useEffect(() => {
		if (data && data.length) {
			setSubscriptions(data);
		}
	}, [data, isLoading]);


	if (isLoading || route === 'idle') {
		return <LoaderPage text="Checking user info..." />;
	}

	if (user == undefined) return <Navigate to="/login" state={{ from: location }} />;

  // if (isError) {
  // return <Navigate to="/ErrorPage" state={{ from: location }} />;
  // }

	if (route === 'authenticated' && !isLoading  && (!subscriptions || subscriptions.length === 0)) {
		// return <Navigate to="/loggedInBuyPlan" state={{ from: location }} />;
		console.log(user)
		console.log(user.attributes?.email)
		return <LoggedInBuyPlan/>
	}

	return (
		<RestaurantAdminHomePage/>
	);
};

export default RequireAdminAuth;
