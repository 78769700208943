import { Box, Button, CardMedia, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Stack, TextField } from "@mui/material"
import { FieldInputProps, useField } from "formik"
import { HiddenInput } from "../../../../components/HiddenInput";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from "react";
import { useImageUpload } from "../../../restaurant/hooks/useImageUpload";
import { v4 as uuidv4 } from 'uuid';
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";

export interface DescriptionImageSectionProps {
    index: number
}

export const DescriptionImageSection: React.FC<DescriptionImageSectionProps> = ({ index }) => {

    const [dishImageField, , dishImageHelpers] = useField(`foodMenuItemsInputs.${index}.dishImageURL`);
    const [dishImageUploadedUrl, , dishImageUploadedUrlHelpers] = useField(`foodMenuItemsInputs.${index}.food_image_input_url`);
    const [dishImageUploadedKey, , dishImageUploadedKeyHelpers] = useField(`foodMenuItemsInputs.${index}.food_image_input_url_key`);

    const [dishImageAltTextField, , dishImageAltTextHelpers] = useField(`foodMenuItemsInputs.${index}.dishImageAltText`);

    const [dishDescriptionField, , dishDescriptionHelpers] = useField(`foodMenuItemsInputs.${index}.dishDescription`);
    
    const { setSnackbarMessage } = useSnackbarContext();

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dishImageHelpers.setValue(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dishDescriptionHelpers.setValue(event.target.value);
    };

    const handleImageAltTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dishImageAltTextHelpers.setValue(event.target.value);
    };

    const { uploadFile, isUploading: isImageUploading } = useImageUpload();

    const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const ret = await uploadFile(file, ['image/jpeg', 'image/png'], 10485760);
            await dishImageUploadedKeyHelpers.setValue(ret?.key);
            await dishImageUploadedUrlHelpers.setValue(ret?.viewUrl);
            await dishImageHelpers.setValue(ret?.viewUrl);
        } else {
            setSnackbarMessage({ key: uuidv4(), message: "No file chosen", type: 'error' });
        }
    }

    const removeImage = () => {
        dishImageHelpers.setValue('');
        dishImageUploadedUrlHelpers.setValue('');
        dishImageUploadedKeyHelpers.setValue('');
    }

    return (
        <Grid container spacing={3} padding={1}>
            <Grid item xs={5}>
                <Stack spacing={1}>
                    {dishImageField.value ? (
                        <Stack spacing={1}>
                            <CardMedia sx= {{ borderRadius: 4, height: '300px'}} component="img" image={dishImageField.value} title={dishImageAltTextField.value} />
                            {/* <img width={400} height={400} alt="Restaurant" src={imageUrl} style={{ marginBottom: '20px' }} /> */}
                            <Button variant='contained' color='error' fullWidth={false} onClick={removeImage} style={{ width: 'fit-content' }}>Remove Image</Button>
                        </Stack>
                    ) : <Skeleton variant="rounded" sx= {{ borderRadius: 4, height: '300px'}} animation={false}/>}
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} disabled={isImageUploading}>
                        {isImageUploading ? "Uploading.." : "Upload Image"}
                        <HiddenInput type="file" onChange={handleFileChangeAndUpload} />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <Stack spacing={7}>
                    <Stack spacing={1}>
                        <InputLabel id="dishDescription">Description</InputLabel>
                        <TextField
                            id="dishDescription"
                            defaultValue="Add Description Here"
                            value={dishDescriptionField.value}
                            onChange={handleDescriptionChange}
                            variant="standard"
                            multiline
                            rows={4}
                            sx={{width: '80%'}}
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <InputLabel id="dishImageAltText">Alt text for image</InputLabel>
                        <TextField
                            id="dishImageAltText"
                            defaultValue="Add Alt text for image here"
                            value={dishImageAltTextField.value}
                            onChange={handleImageAltTextChange}
                            variant="standard"
                            multiline
                            rows={1}
                            sx={{width: '80%'}}
                        />
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}