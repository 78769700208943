import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import { AppDispatch } from '../redux/stores/mainStore';
import useCookie from './useCookie';
import { loadCartItems } from '../redux/cartItems/cartItemsThunks';
import { fetchAllFavorites } from '../redux/customerFavourites/CustomerFavouriteThunks';
import { useCartSession } from './useCartSession';
import { useSnackbarContext } from '../components/restaurantAdmin/contexts/SnackbarContext';
import { selectCustomerFavorites } from '../redux/customerFavourites/CustomerFavouritesSelectors';
import { selectResponseState } from '../redux/cartItems/cartItemsSelectors';
import { loadCartItemOrders } from '../redux/cartOrder/CartOrderThunks';

export const useDataLoader = () => {
    let {route} = useAuthenticator((context) => [context.route]);
    // const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch<AppDispatch>();

    const { data: cartSessionInfo, isLoading: isCartSessionInfoLoading } = useCartSession(route != 'authenticated');
    const { getCookie, setCookie } = useCookie();

    const store_id_from_cookie = getCookie('storeId') || "";
	const table_id_from_cookie = getCookie('tableId') || "";

    const { setSnackbarMessage } = useSnackbarContext();


    const cartItemResponseState = useSelector(selectResponseState)

    const userFavourites = useSelector(selectCustomerFavorites)


    useEffect(() => {
        
        if (route === 'idle') {
            setLoading(true);
        } else setLoading(false);

        if (route === 'authenticated') {
            if (store_id_from_cookie && table_id_from_cookie && cartSessionInfo && cartSessionInfo.cart_session_id) {
                console.log('fetching saved cart items..')
                setCookie('cartSessionId', cartSessionInfo.cart_session_id);
                dispatch(loadCartItems({ cart_session_id: cartSessionInfo.cart_session_id, table_id: table_id_from_cookie, store_id: store_id_from_cookie }));
                dispatch(loadCartItemOrders({ cartSessionId: cartSessionInfo.cart_session_id, tableId: table_id_from_cookie, storeId: store_id_from_cookie }));
            }

            dispatch(fetchAllFavorites(store_id_from_cookie))
        }

        if (cartItemResponseState.message != '') {
			setSnackbarMessage({ key: uuidv4(), message: cartItemResponseState.message, type: cartItemResponseState.type })
		}

    }, [route, cartSessionInfo, cartItemResponseState]);

    return isCartSessionInfoLoading || loading
}


// export default AuthDataLoader;