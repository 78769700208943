// import { string } from "yup";

import { v4 } from "uuid";
import { FoodMenuItem } from "../interfaces/FoodItem";
import { CartItem } from "../redux/cartItems/cartItemsSlice";
import { CartItemSummary } from "../redux/cartItems/cartItemsSelectors";
import { getTotalSumInNumberAndString } from "./priceCalculatorUtil";

// export const compressItemsCountsInCart = (Record<string, cartItem[]> records) {

// }
export const getCartItemFromFoodItem = (foodItem: FoodMenuItem, addedByEmail: string, addedByName: string) : CartItem => {
    const [totalPriceWithCustomization, totalPriceWithCustomizationAndCurrency] = getTotalSumInNumberAndString(foodItem);
    return {
        cartItemId: v4(),
        addedByEmail: addedByEmail,
        addedByName: addedByName,
        dishName: foodItem.dishName,
        menu_item_id: foodItem.id,
        dishType: foodItem.dishType,
        dishPriceText: foodItem.dishPriceText,
        dishPrice: foodItem.dishPrice,
        // dishImageURL: foodItem.dishImageURL,
        // dishImageAltText: foodItem.dishImageAltText,
        customizeOptions: foodItem.customizeOptions,
        addedOnTimestamp: new Date().getTime(),
        totalPriceWithCustomization,
        totalPriceWithCustomizationAndCurrency
    }
}

export const getNewOrExistingCartItemfromCartItemSummary = (cartItemSummary: CartItemSummary, name: string, email: string, uuid?: string) : CartItem => {
    
    return {
        cartItemId: uuid || v4(),
        addedByEmail: email,
        addedByName: name,
        dishName: cartItemSummary.foodItemName,
        menu_item_id: cartItemSummary.foodItemId,
        dishType: cartItemSummary.dishType,
        dishPriceText: cartItemSummary.dishPriceText,
        dishPrice: cartItemSummary.dishPrice,
        customizeOptions: cartItemSummary.customizationStep || [],
        addedOnTimestamp: cartItemSummary.addedOnTimestamp, //check impact
        totalPriceWithCustomization: cartItemSummary.itemUnitPrice,
        totalPriceWithCustomizationAndCurrency: cartItemSummary.itemUnitPriceText
    }
}

export const getCloneCartItem = (cartItem: CartItem): CartItem => {
    const clone: CartItem = JSON.parse(JSON.stringify(cartItem));
    clone.cartItemId = v4();
    return clone;
}