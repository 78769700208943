import { AppBar, Button, Container, Stack, Toolbar, Typography, styled } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

export const LoginPageText = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontFamily: 'Google Sans',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#1C1B1F',
    marginTop:'50px',
    textAlign:'left'
}));

        
export const LoggedInBuyPlan = () => {
    const navigate = useNavigate();
    return (
        <Stack>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Admin Portal
                    </Typography>
                    <Button color="inherit" onClick={() => navigate('/logout')}><LogoutIcon/> Logout</Button>
                </Toolbar>
            </AppBar>
            <Stack padding={10}>
                <Container>
                    <LoginPageText>Hey There, do have a look at our plans. Seems like you dont have a valid plan, or ask your store/restaurant owner to add you.</LoginPageText>

                </Container>
            </Stack>
        </Stack>
    )
}