import React from 'react';
import { Box, CardMedia, Container, Stack, Typography, styled } from '@mui/material';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import { useOfferIdCards } from '../../../../hooks/useOfferIdCards';
import { useLandingPageSections } from '../../../../hooks/useLandingPageSections';
import { useNavigate } from 'react-router-dom';
import { DineInStickyFooter } from '../common/DineInStickyFooter';
import { ITEM_DETAIL_PATH } from '../../../../constants/pathConstants';
import { DishCategory } from '../../../../interfaces/FoodItem';
import { VegNonVegIcon } from '../common/CustomIcons';
import useCookie from '../../../../hooks/useCookie';
import { LandingPageSkeleton } from './LandingPageSkeleton';
import UserProfileModal from './DineInUserProfileFillPrompt';

const HeroCardTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '14px',
    textTransform: 'uppercase',
    fontStyle: 'normal',
    opacity: '0.5',
    fontWeight: '700',
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#FFF'
}));

const HeroCardOfferText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    width: '60%',
    textAlign: 'left',
    color: '#FFF'
}));

const HeroCardOfferSubText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    width: '60%',
    fontWeight: '400',
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#FFF'
}));

const HeroCards: React.FC<{ title: string; deal: string; description: string, offerId: string }> = ({ title, deal, description, offerId }) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                minWidth: '230px',
                height: '230px',
                borderRadius: '30px',
                p: 3,
                paddingTop: 2,
                bgcolor: 'white',
                // borderRadius: '8px',
                boxShadow: 1,
                justifyContent: 'space-around',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'center',
                alignItems: 'left', // Align content in the center horizontally
                textAlign: 'left',
                background: 'linear-gradient(136deg, #6750A4 0%, #B3261E 100%)'
            }}
            onClick={()=>{navigate(`offerDetail/${offerId}`)}}>
            <HeroCardTitleText>
                {title}
            </HeroCardTitleText>
            <HeroCardOfferText sx={{whiteSpace: 'pre-line'}}>
                {deal}
            </HeroCardOfferText>
            <HeroCardOfferSubText>{description}</HeroCardOfferSubText>
            <ArrowCircleRightRoundedIcon sx={{color: '#fff'}}/>
        </Box>
    );
};
  
const LandingPageCategoryTitle = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left'
}));

const HeroPageContent = () => {

    const offerCards = useOfferIdCards()
    return(
        <>
        <LandingPageCategoryTitle gutterBottom>Offers</LandingPageCategoryTitle>
            <Box
                sx={{
                    display: 'flex',
                    paddingLeft: '2px',
                    overflowX: 'auto', // Enable horizontal scrolling
                    gap: '8px', // Adjust the gap between menu items
                    paddingY: '8px', // Add some padding on the top and bottom
                    // '-ms-overflow-style': 'none', // Hide the scrollbar for IE and Edge
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0px', // Hide the scrollbar for Chrome, Safari, and Opera
                    },
                }}
            >
                {
                    offerCards.map(offerCard => (<HeroCards key={offerCard.offerId} offerId={offerCard.offerId} title={offerCard.offerTitle} deal={offerCard.offerName} description={offerCard.offerDescription}/>))
                }
            </Box>
        </>
    )
}


const ThumbnailItemCardTitle = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left'
}));

const ThumbnailItemPrice = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#79747E'
}));
export const Null = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
            <circle cx="2.40881" cy="2.16211" r="2" fill="#79747E"/>
        </svg>
    )
}

export type ThumbnailCardProps = {
    foodItemImageUrl: string; 
    foodItemTitle: string;
    price: string;
    rating: string;
    foodItemId: string;
    vegNonVeg: DishCategory;
}

const ThumbnailCards: React.FC<ThumbnailCardProps> = ({ vegNonVeg, foodItemImageUrl, foodItemTitle, price, rating, foodItemId }) => { 
    const navigate = useNavigate()
    return (
        <Stack direction="column" gap={0.8}>
            <Stack onClick={()=>{navigate(`${ITEM_DETAIL_PATH.replace(":itemId",foodItemId)}`)}} spacing={0.5}>
                <Box
                    sx={{
                        minWidth: '120px',
                        height: '130px',
                         borderRadius: '20px',
                         p: 3,
                         paddingTop: 2,
                         bgcolor: 'white',
                         // borderRadius: '8px',
                         boxShadow: 1,
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'center',
                         backgroundRepeat: 'no-repeat',
                         backgroundSize: 'cover',
                         alignItems: 'left',  //Align content in the center horizontally
                         textAlign: 'left',
                         backgroundImage: `url('${foodItemImageUrl}')`,
                         backgroundPosition: 'center'
                     }}
                     />
                    {/* <CardMedia sx= {{ borderRadius: 4, height:'130px', maxWidth: '150px', boxShadow: 1}} component="img" image={foodItemImageUrl} /> */}
                {/*  </Box> */}
                <ThumbnailItemCardTitle sx={{display: 'flex', alignItems: 'center', width:'100%', maxWidth: '150px'}}><VegNonVegIcon foodType={vegNonVeg}/><span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{foodItemTitle}</span></ThumbnailItemCardTitle>
                <Stack direction="row" gap={1}>
                    {/* <StarsRoundedIcon sx={{color: '#288D0F', fontWeight: '700', height: '20px'}}/> */}
                    <ThumbnailItemPrice>
                        {/* {rating} {<span style={{marginLeft: '2px', marginRight: '5px'}}>&nbsp;<Null/>&nbsp;</span>}  */}
                        {price}</ThumbnailItemPrice>
                </Stack>
            </Stack>
        </Stack>
        
    )
}

const FoodThumbNailContent = () => {
    const {isLoading,tagToThumbnailsMap: landingPageSections} = useLandingPageSections();
    return isLoading?(<LandingPageSkeleton/>) :(
        <Stack spacing={1}>
            {Array.from(landingPageSections).map(([category, thumbnailCards]) => (
                <React.Fragment key={category}>
                    <LandingPageCategoryTitle gutterBottom>{category}</LandingPageCategoryTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            paddingLeft: '2px',
                            overflowX: 'auto', // Enable horizontal scrolling
                            gap: '1rem', // Adjust the gap between menu items
                            paddingY: '8px', // Add some padding on the top and bottom
                            // '-ms-overflow-style': 'none', // Hide the scrollbar for IE and Edge
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                width: '0px', // Hide the scrollbar for Chrome, Safari, and Opera
                            },
                        }}
                    >
                        {
                        thumbnailCards.map(thumbnailCard => (
                            <ThumbnailCards key={thumbnailCard.foodItemId} vegNonVeg={thumbnailCard.vegNonVeg} foodItemId={thumbnailCard.foodItemId} foodItemImageUrl={thumbnailCard.foodItemImageUrl} foodItemTitle={thumbnailCard.foodItemTitle} price={thumbnailCard.price} rating={thumbnailCard.rating}/>
                        ))
                        }
                    </Box>
                </React.Fragment>
            ))}      
        </Stack>
    )
}

export const LandingPageBody = () => (
    <Stack direction='column' paddingLeft={3} spacing={2} paddingTop={3}>
        <HeroPageContent/>
        <FoodThumbNailContent/>
        <UserProfileModal/>
    </Stack>

)
