import dayjs from 'dayjs';
import { useBaseMutation } from '../components/hooks/useBaseMutation';
import { CustomerInfo } from '../components/dinein/pages/profile/ProfileFormComponent';

export const usePutCustomerInfo = () => {
    const transformUserData = (userData: CustomerInfo) => ({
        // first_name: userData.first_name,
        // last_name: userData.last_name,
        ...userData,
        date_of_birth: dayjs(userData.date_of_birth).format('DD-MM-YYYY'),
    });

    return useBaseMutation(
        'put',
        'https://api.menufy.link/dineIn/customers/info', // Endpoint
        // 'userData',                                              // Query key
        transformUserData                                        // Data transformation function
    );
};
