import React, { useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { StoreFormData } from '../hooks/useStoreForm';

const StoreAddressComponent = () => {
  const { values, handleChange, touched, errors } = useFormikContext<StoreFormData>();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Address Line 1"
          name="store_address_first_line"
          value={values.store_address_first_line}
          onChange={handleChange}
          error={touched.store_address_first_line && Boolean(errors.store_address_first_line)}
          helperText={touched.store_address_first_line && errors.store_address_first_line}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Address Line 2"
          name="store_address_second_line"
          value={values.store_address_second_line}
          onChange={handleChange}
          error={touched.store_address_second_line && Boolean(errors.store_address_second_line)}
          helperText={touched.store_address_second_line && errors.store_address_second_line}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="City"
          name="store_address_city"
          value={values.store_address_city}
          onChange={handleChange}
          error={touched.store_address_city && Boolean(errors.store_address_city)}
          helperText={touched.store_address_city && errors.store_address_city}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Postcode"
          name="store_address_postcode"
          value={values.store_address_postcode}
          onChange={handleChange}
          error={touched.store_address_postcode && Boolean(errors.store_address_postcode)}
          helperText={touched.store_address_postcode && errors.store_address_postcode}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Country"
          name="store_address_country"
          value={values.store_address_country}
          onChange={handleChange}
          error={touched.store_address_country && Boolean(errors.store_address_country)}
          helperText={touched.store_address_country && errors.store_address_country}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default StoreAddressComponent;
