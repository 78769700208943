import { ListItem, ListItemButton, List, ListItemIcon, ListItemText } from "@mui/material"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { SidebarProps, dashboardProps } from "../configs/SideBarOptions"
import { useFetchCoreTeamUsers } from "../hooks/useFetchCoreTeamUsers";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

const isActiveLink = (link: string, path: string) => {
    if (link === '/') {
        return path === '/';
    }
    return path.startsWith(link);
}

interface SidebarListProps {
    sideBarList: SidebarProps[];
}


export const SidebarList: React.FC<SidebarListProps> = (sideBarListProps: SidebarListProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data: coreTeamData, isLoading, status} = useFetchCoreTeamUsers();
    const [ isCoreTeam, setIsCoreteam ] = useState<boolean>(false);
    useEffect( () => {
        if (status === 'success') {
            if (coreTeamData && coreTeamData.length) setIsCoreteam(true);
        }
    }, [status]);

    const showItem = (item: SidebarProps) : boolean => {
        if (!item.coreTeam) return true;
        return isCoreTeam;
    }
    return (
        <List>
        { sideBarListProps.sideBarList.map((item, index) => (
        <ListItem key={item.title} disablePadding sx={{width: 'auto', display: 'block'}}>
            <NavLink to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
            { showItem(item) &&
                <ListItemButton sx={ {...isActiveLink(item.link, location.pathname) ?  { backgroundColor: 'primary.main', color: 'white' } :  (item.color ? { color: item.color } : undefined)}}>
                    <ListItemIcon>
                        <item.icon sx={item.color ? { color: item.color } : undefined}/>
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                </ListItemButton>
            }    
            </NavLink>
        </ListItem>
        )) }
        </List>
    )
}