import { useQuery } from 'react-query'
import axiosInstance from '../../common/axiosInstance';

const getUserStoresData = async () => {
    const response = await axiosInstance.get('https://api.menufy.link/coreTeam/users/info'); // Use the custom axios instance
    return response.data;
};

export const useFetchCoreTeamUsers = () => {
    return useQuery('CoreTeam', getUserStoresData, {
        refetchOnMount: 'always',
    });
};
