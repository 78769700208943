import React from 'react';
import { Button, Card, CardContent, Skeleton, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { HiddenInput } from '../../../components/HiddenInput';

interface ImageUploadComponentProps {
    imageUrl: string;
    setImageUrl: (url: string) => void;
    handleFileChangeAndUpload: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    isImageUploading: boolean;
    removeImage: () => void;
}

const ImageUploadComponent: React.FC<ImageUploadComponentProps> = ({
    imageUrl,
    setImageUrl,
    handleFileChangeAndUpload,
    isImageUploading,
    removeImage,
}) => {
    return (
        <Card variant="outlined">
            <CardContent>
                <span>Restaurant Login Page Image:</span>
                <Stack spacing={2}>
                    {imageUrl ? (
                        <>
                            <img width={400} height={400} alt="Restaurant" src={imageUrl} style={{ marginBottom: '20px' }} />
                            <Button variant='contained' color='error' fullWidth={false} onClick={removeImage} style={{ width: 'fit-content' }}>Remove Image</Button>
                        </>
                    ) : <Skeleton variant="rectangular" width={400} height={400} animation={false}/>}
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} disabled={isImageUploading}>
                        {isImageUploading ? "Uploading.." : "Upload Image"}
                        <HiddenInput type="file" onChange={handleFileChangeAndUpload} />
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ImageUploadComponent;
