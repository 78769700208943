import { useQuery } from "react-query";
import axiosInstance from "../../../../common/axiosInstance";

// Modify the hook to accept a storeId and adjust the query key accordingly
export const useFetchStoreUsers = (storeId: string | null | undefined ) => {
    return useQuery(['storeUsers', storeId], () => getStoreUsers(storeId), {
        enabled: !!storeId // Fetch data only if storeId is present
    });
};

const getStoreUsers = async (storeId: string | null | undefined) => {
    if (!storeId) {
        throw new Error("storeId is required");
    }
    const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/stores/users?storeId=${storeId}`);
    return response.data;
};
