import { useDispatch, useSelector } from "react-redux";
import { addCartItem, removeCartItem, resetCartItems } from "../redux/cartItems/cartItemsSlice";
import { useSnackbarContext } from "../components/restaurantAdmin/contexts/SnackbarContext";
import { v4 as uuidv4 } from 'uuid';
import { SelectCartItems, selectCartItemsState } from "../redux/cartItems/cartItemsSelectors";
import { moveCartItemsToOrder } from "../redux/cartOrder/CartOrderSlice";
import { loadCartItemOrders } from "../redux/cartOrder/CartOrderThunks";
import useCookie from "./useCookie";
import { useCartSession } from "./useCartSession";
import { AppDispatch } from "../redux/stores/mainStore";
import { sleep } from "react-query/types/core/utils";

interface CartItemRemovePayload{
    type: string;
    message: string;
    payload: {
        email: string;
        cart_item_id: string;
        main_item_id: string;
    }
}

interface CartItemRemovePayload{
    type: string;
    message: string;
    payload: {
        email: string;
        cart_item_id: string;
        main_item_id: string;
    }
}

interface WebSocketPayload {
    type: string;
    message: string;
    payload: any;
}
// interface CartItemAddPayload

export const useProcessWebSocketMessage = () => {


    const { getCookie } = useCookie();
    // const { data: cartSessionInfo, isLoading: isCartSessionInfoLoading } = useCartSession();

    const store_id_from_cookie = getCookie('storeId') || "";
	const table_id_from_cookie = getCookie('tableId') || "";
    const cart_session_id_from_cookie = getCookie('cartSessionId') || "";
    
    const dispatch = useDispatch<AppDispatch>();

    const { setSnackbarMessage } = useSnackbarContext();
    // const cartItemsState = useSelector(selectCartItemsState);

    const processMessage = (jsonMessage: string) => {
        let obj: WebSocketPayload = JSON.parse(jsonMessage);
        const action = obj.type;
        const message = obj.message;
        switch (action) {
            case 'removeCartItem':
                const { email, cart_item_id, main_item_id } = obj.payload;
                const payload = { mainItemId: main_item_id, cartItemUUID: cart_item_id, email };
                console.log(payload);
                dispatch(removeCartItem(payload));
                break;
            // Add more cases as needed for different types of actions
            case 'addCartItem':
                let { email: payloadEmail, cartItem } = obj.payload;
                const dispatchPayload = {
                    mainItemId: cartItem.cartItem.menu_item_id, // Assuming this maps to mainItemId
                    cartItem: {
                        cartItemId: cartItem.cartItem.cartItemId,
                        addedByEmail: cartItem.cartItem.addedByEmail,
                        addedByName: cartItem.cartItem.addedByName,
                        dishName: cartItem.cartItem.dishName,
                        menu_item_id: cartItem.cartItem.menu_item_id,
                        dishType: cartItem.cartItem.dishType,
                        dishPriceText: cartItem.cartItem.dishPriceText,
                        dishPrice: cartItem.cartItem.dishPrice,
                        customizeOptions: cartItem.cartItem.customizeOptions,
                        addedOnTimestamp: cartItem.cartItem.addedOnTimestamp,
                        totalPriceWithCustomization: cartItem.cartItem.totalPriceWithCustomization,
                        totalPriceWithCustomizationAndCurrency: cartItem.cartItem.totalPriceWithCustomizationAndCurrency
                    },
                };
                console.log(dispatchPayload);
                dispatch(addCartItem(dispatchPayload));
                break;
            case 'userJoin':
                break;
            case 'orderPlace':
                // let { email: payloadEmailForOrderPlaced, itemsToRemove } = obj.payload;
                // console.log(obj.payload)
                // while(isCartSessionInfoLoading) sleep(1000);
                dispatch(loadCartItemOrders({storeId: store_id_from_cookie, tableId: table_id_from_cookie, cartSessionId: cart_session_id_from_cookie}))
                dispatch(resetCartItems({}));
                // const cartItems = useSelector(SelectCartItems);
                // dispatch(moveCartItemsToOrder({cartItems}))
                // dispatch(resetCartItems({}))
                break;
            default:
                console.log('Unhandled action type:', action);
        }

        // console.log(cartItemsState.cartItems)


        // Set snackbar message
        if (message) {
            setSnackbarMessage({ key: uuidv4(), message, type: 'info' });
        }

    };

    return { processMessage };
};

