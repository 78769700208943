import { DineInStickyFooter } from "../common/DineInStickyFooter";
import { DineInLandingPageNavBar } from "./DineInLandingPageNavBar";
import { LandingPageBody } from "./LandingPageBody";

export const DineInLandingPage = () => {
    // const { data: items, isLoading, error } = useItemsQuery();
    
    return (
        <>
            <DineInLandingPageNavBar/>
            <LandingPageBody/>
            <DineInStickyFooter page='home'/>
        </>
    )
}