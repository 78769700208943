import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetchStoreInfo } from "../../stores/hooks/useFetchStoreInfo";
import { useBreadcrumb } from "../../../contexts/BreadcrumbContext";
import { useFetchStorePaymentInfo } from "../hooks/useFetchStorePaymentInfo";
import { useCreateStoreAccount } from "../hooks/useCreateStoreAccount";
import { Button, Stack } from "@mui/material";
import { useStoreStripeAccountLink } from "../hooks/useFetchStoreStripeAccountLink";
import { Loader } from "@aws-amplify/ui-react";
import { LoaderPage } from "../../../../rootDomain/LoderPage";

export const ViewEditStorePayment: React.FC = () => {
    const {id: storeId} = useParams() || null;
    const { data: storeData, isLoading: isStoreInfoLoading, refetch } = useFetchStoreInfo(storeId);
    const { setBreadcrumbName } = useBreadcrumb();
    const { data: storePaymentData, isLoading: isPaymentInfoLoading } = useFetchStorePaymentInfo(storeId);
    const { data: stripeAccountLinkData, isSubmitting: isStripeAccountLinkSubmitting, mutate: getStripAccountLink } =  useStoreStripeAccountLink();

    const { mutate: createStore, isSubmitting: isStoreAccountCreateSubmitting, data: result } = useCreateStoreAccount();

    useEffect(() => {
        if (storeId && storeData && storeData.length == 1) {
            setBreadcrumbName(storeId, storeData[0].store_name);
        }
    }, [storeId, isStoreInfoLoading])

    useEffect(() => { 
        console.log(storePaymentData)
    }, [isPaymentInfoLoading])

    const createAccount = () => {
        if (storeId) {
            createStore({variables: {storeId}, key: ['storePaymentInfo', storeId], invalidate: true});
        }
    }

    const updateAccount = () => {

        console.log('ouch..')
        if (storeId)
            getStripAccountLink({ variables: {storeId, refreshUrl: window.location.href, returnUrl:`${window.location.href}?update=true`}, key: ['accountLink', storeId], invalidate: true})
    }

    useEffect(() => {
        if(stripeAccountLinkData) {
            if (stripeAccountLinkData.url) {
                const timeout = setTimeout(() => {
                    window.location.replace(stripeAccountLinkData.url);
                }, 100);
                return () => clearTimeout(timeout);
            }
            console.log(stripeAccountLinkData)
        }
    }, [stripeAccountLinkData]);

    if (isPaymentInfoLoading || isStoreInfoLoading ) return <LoaderPage text="loading payment settings.."/>
    return (
        storePaymentData && storePaymentData.accountInfo ? 
        <Stack>
            <Button variant="contained" onClick={updateAccount} disabled={isStripeAccountLinkSubmitting}>Update your stripe account (goto Stripe)</Button>
        </Stack>
        :
        <Stack>
            <Button variant="contained" onClick={createAccount} disabled={isStoreAccountCreateSubmitting}>Create your stripe Account</Button>
        </Stack>
    )
}