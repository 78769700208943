import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Grid, Skeleton, Stack } from "@mui/material"
import { useCartSession } from "../../../../hooks/useCartSession";
import { useCartSessionOrder } from "../../../../hooks/useCartSessionOrder";
import { VegNonVegIcon } from "../common/CustomIcons";
import { AddedByText, CartItemDishNameText, PriceText } from "./ItemSummarySection";
import { CheckoutCustomizationSection } from "./CheckoutCustomizationSection";
import { CartItemSummaryWithNameEmailTimeStamp } from "../../../../redux/cartItems/cartItemsSelectors";

export interface ItemOrderedSummarySectionProps {
    cartItemSummaryWithNameEmailTimeStamp: CartItemSummaryWithNameEmailTimeStamp[];
}

export const ItemsOrderedSummarySection: React.FC<ItemOrderedSummarySectionProps> = ({cartItemSummaryWithNameEmailTimeStamp}) => {
    const getFirstName = (user: string): string => {
        return user.split(" ")[0];
    }

    const { user } = useAuthenticator((context) => [context.route]);

    const myEmail = user.attributes?.email || '';
    const myName = user.attributes? user.attributes['given_name'] : "John Doe";


    // const {data: cartSessionInfo, isLoading: isCartSessionInfoLoading} = useCartSession();

    // const {data: cartOrder, isLoading: isCartOrderLoading} = useCartSessionOrder(true, cartSessionInfo?.cart_session_id || "");

    const isSameUser = (userEmail: string) : Boolean => userEmail == myEmail
    const getAddedByUser = (userEmail: string, userName: string): string => isSameUser(userEmail) ? 'You' : getFirstName(userName)

    // if (isCartSessionInfoLoading) return (
    //     <Stack spacing={1} style={{'backgroundColor': '#EADDFF', 'borderRadius': '30px'}}>
    //         <Skeleton variant="rounded" width={210} height={60}/>
    //     </Stack>
    // )

    return (
        <Stack spacing={1} padding={2} style={{'backgroundColor': '#EADDFF', 'borderRadius': '30px'}}>
            {
                cartItemSummaryWithNameEmailTimeStamp && cartItemSummaryWithNameEmailTimeStamp.map((cartItemSummary) => (
                    <Stack direction='row' style={{'display': 'flex', 'alignItems': 'flex-start', 'justifyContent': 'space-between'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                    <Stack direction='row' spacing={1}>
                                        <VegNonVegIcon foodType={cartItemSummary.dishType}></VegNonVegIcon>
                                        <Stack direction='column'>
                                            <CartItemDishNameText>{cartItemSummary.foodItemName}</CartItemDishNameText>
                                            <AddedByText>{`Added by ${getAddedByUser(cartItemSummary.email, cartItemSummary.name)}`}</AddedByText>
                                            {
                                                cartItemSummary.customizations && cartItemSummary.customizations.length > 0 &&
                                                    <CheckoutCustomizationSection cartItemSummary={ cartItemSummary } />
                                            }
                                        </Stack>
                                    </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction='column' spacing={2}>
                                    {/* <AddButton backgroundColor='inherit' color={'#7D5260'} count={cartItemSummary.quantity} incrementHandler={() => {incrementItem(getNewOrExistingCartItemfromCartItemSummary(cartItemSummary, myName, myEmail))}} decrementHandler={() => {decrementItem(getNewOrExistingCartItemfromCartItemSummary(cartItemSummary, cartItemSummary.name, cartItemSummary.email, cartItemSummary.sampleCartItemIUUID))}}/> */}
                                    <Box display='flex' justifyContent='space-between' padding={1}>
                                        <CartItemDishNameText>X</CartItemDishNameText>
                                        <CartItemDishNameText>{cartItemSummary.quantity}</CartItemDishNameText>
                                    </Box>
                                    <PriceText>{cartItemSummary.totalAmountString}</PriceText>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                ))
            }
        </Stack>
    )
}