import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { RestaurantFormData } from '../hooks/useRestaurantForm';
import { useFetchUserSubscriptions } from '../../../../hooks/useFetchUserSubscriptions';

interface SubscriptionInfo {
    plan_id: string;
    subscription_id: string;
    plan_name: string;
}

interface SubscriptionDropdownComponentProps {
    disabled: boolean;
}

const SubscriptionDropdownComponent: React.FC<SubscriptionDropdownComponentProps> = ({disabled}) => {//({ subscriptions }) => {
    const { values, touched, errors, handleChange } = useFormikContext<RestaurantFormData>();
    const { data: subscriptions } = useFetchUserSubscriptions();
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo[]>([]);


    useEffect(() => {
        if (subscriptions) {
            const subs = subscriptions.map((sub: any) => ({
                plan_id: sub.plan_id,
                subscription_id: sub.subscription_id,
                plan_name: sub.plan_name
            }));
            setSubscriptionInfo(subs);
        }
    }, [subscriptions])

    useEffect(() => {
        console.log(values)
    }, [values]);

    console.log(subscriptions);
    return (
        <FormControl fullWidth error={touched.subscription_id && Boolean(errors.subscription_id)}>
            <InputLabel id="subscription-select-label">Subscription Plan</InputLabel>
            <Select
                labelId="subscription-select-label"
                label="Subscription Plan"
                name="subscription_id"
                value={values.subscription_id}
                onChange={handleChange}
                disabled={disabled}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {subscriptionInfo.map((sub) => (
                    <MenuItem key={sub.subscription_id} value={sub.subscription_id}>
                        {sub.plan_name}
                    </MenuItem>
                ))}
            </Select>
            {touched.subscription_id && errors.subscription_id && (
                <FormHelperText>{errors.subscription_id}</FormHelperText>
            )}
        </FormControl>
    );
};

export default SubscriptionDropdownComponent;
