import { Button, Card, CardContent, Stack } from "@mui/material";
import QRCodeGenerator from "../../../components/QRCodeGenerator";
import { HiddenInput } from "../../../components/HiddenInput";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { RestaurantFormData } from "../hooks/useRestaurantForm";
import { useImageUpload } from "../hooks/useImageUpload";
import { v4 as uuidv4 } from 'uuid';

import { useSnackbarContext } from "../../../contexts/SnackbarContext";

export interface CustomizeQRCodeComponentProps {
    logo: string | undefined
}
export const CustomizeQRCodeComponent: React.FC<CustomizeQRCodeComponentProps> = ({logo}) => {
    const { setFieldValue, setSubmitting } = useFormikContext<RestaurantFormData>();
    const { uploadFile, isUploading: isImageUploading } = useImageUpload();
    const { setSnackbarMessage } = useSnackbarContext();

    const [logoUrl, setLogoURL] = useState("");

    useEffect(()=> {
        if (logo) {
            setLogoURL(logo);
        }
    }, [logo]);

    const removeImage = async () => {
        setLogoURL("");
        await setFieldValue('restaurant_qr_logo', '');
        await setFieldValue('qr_logo_key', '');
    }

    const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        
        await setSubmitting(true);
        if (event.target.files && event.target.files[0]) {
            
            const file = event.target.files[0];
            const ret = await uploadFile(file, ['image/jpeg', 'image/png'], 10485760);
            await setFieldValue('qr_logo_key', ret?.key);
            setLogoURL(ret?.viewUrl);
        } else {
            setSnackbarMessage({ key: uuidv4(), message: "No file chosen", type: 'error' });
        }
        await setSubmitting(false);
    };
    // const formik
    return (
        <Card variant="outlined">
            <span>Upload a custom logo in your QR:</span>
            <CardContent sx={{display: 'flex', alignItems:'flex-end'}}>
                <Stack spacing={2}>
                    <QRCodeGenerator url="he" text="Sample QR for your store" logo={logoUrl}/>
                    {logoUrl && <Button variant='contained' color='error' fullWidth={false} onClick={removeImage} style={{ width: 'fit-content' }}>Remove Logo</Button> }
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} disabled={isImageUploading}>
                        {isImageUploading ? "Uploading.." : "Upload Logo"}
                        <HiddenInput type="file" onChange={handleFileChangeAndUpload} />
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}