import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCartSession } from './useCartSession';
import { FoodMenuItem } from '../interfaces/FoodItem';
import { getCartItemFromFoodItem } from '../utils/CartItemsUtils';
import { CartItem, addCartItem, removeCartItem } from '../redux/cartItems/cartItemsSlice';
import useCookie from './useCookie';
import { AppDispatch } from '../redux/stores/mainStore';
import { AddToCartPayload, persistCartItem, removeCartItemThunk } from '../redux/cartItems/cartItemsThunks';
import { resetSelection } from '../redux/FoodItemCustomization/customizationSlice';
import { getTotalSumInNumberAndString } from '../utils/priceCalculatorUtil';

export const useAddToCart = (email: string, name: string) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {getCookie} = useCookie();
    const table_id = getCookie('tableId') || "";
    const { data: cartSessionInfo, isLoading: isCartSessionLoading } = useCartSession();

    const addToCartHandler = (main_item_id: string, item?: FoodMenuItem, dontNaviagate?: boolean, cartItemCloned?: CartItem) => {
        if (isCartSessionLoading) {
            console.error('Cart session is loading, please wait...');
            return;
        }
        if (!cartSessionInfo) {
            console.error('Cart session info is not available');
            return;
        }

        if (item && item.customizeOptions && item.customizeOptions.length > 0 && !dontNaviagate) {
            navigate(`/menu/customize/item/${main_item_id}/step/${item.customizeOptions[0].step}`)
        } else {
            let cartItem: CartItem | undefined = undefined;
            if (!cartItemCloned && !item) throw Error('you need either cart item or food item');
            if (!cartItemCloned && item) {
                cartItem = getCartItemFromFoodItem(item, email, name);
                [cartItem.totalPriceWithCustomization, cartItem.totalPriceWithCustomizationAndCurrency] = getTotalSumInNumberAndString(cartItem)
             } else {
                console.log(cartItemCloned)
                cartItem = cartItemCloned;
            }
            if (!cartItem) throw Error('error creating cart item');
            const payload = {
                cart_session_id: cartSessionInfo.cart_session_id || "",
                store_id: cartSessionInfo.store_id || "",
                email_id: email,
                table_id: table_id,
                cart_item_id: cartItem.cartItemId,
                cartItem: cartItem,
            };
            // Dispatch the async thunk to call the API
            dispatch(persistCartItem(payload));
            console.log('Calling persist item...');
            dispatch(addCartItem({ mainItemId: main_item_id, cartItem }));
            if (item && item.customizeOptions && item.customizeOptions.length > 0) {
                dispatch(resetSelection({mainItemId: item.id}))
                navigate('/menu') 
            }
        }

        
  };

  const removeFromCartHandler = (cartItem: CartItem) => {
    if (isCartSessionLoading) {
        console.error('Cart session is loading, please wait...');
        return;
    }
    if (!cartSessionInfo) {
        console.error('Cart session info is not available');
        return;
    }
    const payload = {
        cart_session_id: cartSessionInfo.cart_session_id,
        email_id: cartItem.addedByEmail, 
        dish_name: cartItem.dishName,
        cart_item_id: cartItem.cartItemId,
        cart_item: cartItem,
        store_id: cartSessionInfo.store_id,
        table_id: table_id
    }
    dispatch(removeCartItemThunk(payload));
    console.log('Calling delete item...');
    dispatch(removeCartItem({ mainItemId: cartItem.menu_item_id, cartItemUUID: cartItem.cartItemId, email: cartItem.addedByEmail }));
  }

  return {
    addToCartHandler,
    isCartSessionLoading,
    removeFromCartHandler
  };
};
