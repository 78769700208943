import { Button, Stack } from "@mui/material"
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import { CategoryFormValue, FoodMenuItemInput, getCategories, storeDineInMenuSchema } from "../../hooks/useMenuForm";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useFetchStoreInfo } from "../../../stores/hooks/useFetchStoreInfo";
import { HiddenInput } from "../../../../components/HiddenInput";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import { v4 as uuidv4 } from 'uuid';

export const DownLoadOrUploadMenu: React.FC = () => {
    const {values, errors, setFieldValue} = useFormikContext<{ foodMenuItemsInputs: FoodMenuItemInput[], categories: CategoryFormValue[]}>();
    const {id: storeId} = useParams();
    const {data, isLoading} = useFetchStoreInfo(storeId);
    const [processing, setProcessing] = useState(false);
    const [storeName, setStoreName] = useState('store');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { setSnackbarMessage } = useSnackbarContext();
    
    useEffect(()=> {
        if(data && data.length) {
            setStoreName(data[0].store_name);
        }
    }, [storeId, isLoading]);

    const downloadMenu = () => {
        const jsonString = JSON.stringify(values.foodMenuItemsInputs, null, 2);
        const blob = new Blob([jsonString], {type: "application/json"})
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${storeName}.json`; // File name for download
        document.body.appendChild(anchor); // Required for Firefox
        anchor.click(); // Trigger the download
        document.body.removeChild(anchor); // Clean up
        URL.revokeObjectURL(url); // Free up resources
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            setProcessing(true);
            reader.onload = async (e) => {
                try {
                    const json = JSON.parse(e.target?.result as string);
                    // Perform validation to check if json is of type FoodMenuItemInput[]
                    // This validation needs to be adjusted according to your FoodMenuItemInput structure
                    try {
                        // Ensure the parsed JSON conforms to the structure expected by the schema
                        await storeDineInMenuSchema.validate(json, { abortEarly: false });
                        // If validation passes, reset the foodMenuItemsInputs values
                        const foodItemMenuInputs = json as FoodMenuItemInput[];
                        await setFieldValue('foodMenuItemsInputs', json);
                        await setFieldValue('categories', getCategories(foodItemMenuInputs));
                        setSnackbarMessage({ key: uuidv4(), message: 'Copied menu successfully..', type: 'success' });
                    } catch (validationError) {
                        setSnackbarMessage({ key: uuidv4(), message: 'Improper format of file..', type: 'error' });
                    }
                } catch (error) {
                    console.log("Error parsing JSON:", error);
                } finally {
                    setProcessing(false);
                }
            };
            reader.readAsText(file);
        }
    };

    return (
        <Stack spacing={4} direction='row'>
            <Button variant="contained" onClick={downloadMenu} disabled={Object.keys(errors).length > 0 || values.foodMenuItemsInputs.length == 0}>Download Menu Saved <DownloadForOfflineRoundedIcon/></Button>
            <Button variant="contained" onClick={handleUploadClick} disabled={processing}>
                Upload from disk <BackupRoundedIcon/>
                <HiddenInput
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".json"
                    onChange={handleFileChange}
                />
             </Button>
        </Stack>
    )
}