import { Chip, Stack } from '@mui/material';
import React from 'react';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SelectCartItemOrderSummaryWithNameEmailTimeStamp } from '../../../../redux/cartOrder/CartOrderSelectors';
import CheckoutForm from './CheckoutForm';
import { ItemsOrderedSummarySection } from './ItemsOrderedSummarySection';


const CheckoutAndPay: React.FC = () => {
    const cartItemOrderSummaryWithNameEmailTimeStamp = useSelector(SelectCartItemOrderSummaryWithNameEmailTimeStamp);
    const navigate = useNavigate();

    
    return (
        <Stack spacing={3} padding={3}>
            <Chip
                    avatar={<ArrowCircleLeftIcon/>}
                    label="Back"
                    variant="outlined"
                    sx={{
                        width: '100px',
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                    onClick={()=>{navigate("..")}}
            />
            <Stack spacing={4}>
            {
                                cartItemOrderSummaryWithNameEmailTimeStamp && cartItemOrderSummaryWithNameEmailTimeStamp.length > 0 &&
                                    <Stack spacing={3}>
                                        {/* <LockCartAndPlaceOrder/>
                                    <BillAndSummary/>
                                    <PayAndCheckout/> */}
                                        <ItemsOrderedSummarySection cartItemSummaryWithNameEmailTimeStamp={cartItemOrderSummaryWithNameEmailTimeStamp} />
                                    </Stack>
            }
            </Stack>
            <CheckoutForm />
        </Stack>
        
    );
};

export default CheckoutAndPay;
