import { AddOnItem } from "../../interfaces/FoodItem";
import { calculateTotalSum, getTotalSumInNumberAndString } from "../../utils/priceCalculatorUtil";
import { CartItemSummary, CartItemSummaryWithNameEmailTimeStamp } from "../cartItems/cartItemsSelectors";
import { RootState } from "../stores/mainStore";
import { CartOrderItem } from "./CartOrderSlice";

export const SelectCartItemOrderSummaryWithNameEmailTimeStamp = (state: RootState): CartItemSummaryWithNameEmailTimeStamp[] => {
    const cartItemsByUserAndTimeStamp: CartItemSummaryWithNameEmailTimeStamp[] = [];
    for (const email of Object.keys(state.cartOrderItems.cartOrderItems)) {
        let name = ""
        for (const itemId of Object.keys(state.cartOrderItems.cartOrderItems[email])) {
            const cartItems: CartOrderItem[] = [];
            state.cartOrderItems.cartOrderItems[email][itemId].forEach(cartItem => {
                cartItems.push(cartItem);
            })
            const [addedByName, itemsSummarized]= squashAndSummarize(cartItems);
            name = addedByName
            itemsSummarized.forEach(item => cartItemsByUserAndTimeStamp.push({...item, name, email, addedOnTimestamp: itemsSummarized[0].addedOnTimestamp}));
        }
    }
    return cartItemsByUserAndTimeStamp.sort((a,b) => a.addedOnTimestamp - b.addedOnTimestamp);
    // return ret;
}

const squashAndSummarize = (cartItems: CartOrderItem[]) : [string, CartItemSummary[]] => {
    const name = cartItems[0].addedByName
    const cartCustomizationMap: {[key: string]: CartItemSummary} = {}
    const cartItemSummaries: CartItemSummary[] = []
    cartItems.forEach(cartItem => {
        const [customizationHash, cartItemSummary] = getCustomizationHash(cartItem);
        if (!(customizationHash in cartCustomizationMap)) {
            cartCustomizationMap[customizationHash] = cartItemSummary
        } else {

            cartCustomizationMap[customizationHash].addedOnTimestamp = Math.min(cartItemSummary.addedOnTimestamp, cartCustomizationMap[customizationHash].addedOnTimestamp)
            cartCustomizationMap[customizationHash].quantity += 1
            cartCustomizationMap[customizationHash].totalAmount += cartItemSummary.itemUnitPrice
            cartCustomizationMap[customizationHash].totalAmountString = calculateTotalSum([cartItemSummary.itemUnitPriceText, cartCustomizationMap[customizationHash].totalAmountString])
        }
    })
    for (const hash in cartCustomizationMap) cartItemSummaries.push(cartCustomizationMap[hash])
    return [name, cartItemSummaries]
}

const getCustomizationHash = (cartItem: CartOrderItem) : [string, CartItemSummary] => {
    const hash = getHash(cartItem);
    const foodItemId = cartItem.menu_item_id
    const dishType = cartItem.dishType
    const foodItemName = cartItem.dishName
    const quantity = 1
    const customizationId = hash
    const sampleCartItemIUUID = cartItem.cartItemId
    const customizations: AddOnItem[] = []
    const [itemUnitPrice, itemUnitPriceText] = getTotalSumInNumberAndString(cartItem);
    const customizationStep = cartItem.customizeOptions;
    const dishPriceText = cartItem.dishPriceText;
    const dishPrice = cartItem.dishPrice;
    const addedOnTimestamp = cartItem.addedOnTimestamp;
    cartItem.customizeOptions?.forEach(step => step.stepOptions.forEach(choice => choice.items.forEach(item => customizations.push({...item}))))
    const [totalAmount, totalAmountString] = getTotalSumInNumberAndString(cartItem)
    return [hash, {foodItemId,foodItemName, addedOnTimestamp, dishType, quantity, customizationId, dishPrice, customizations, totalAmount, totalAmountString, customizationStep, sampleCartItemIUUID, itemUnitPriceText, itemUnitPrice, dishPriceText}]
}

const getHash = (cartItem: CartOrderItem) : string => {
    let hash: string[] = [cartItem.menu_item_id];
    cartItem.customizeOptions?.forEach(step => step.stepOptions.forEach(choice => choice.items.forEach(item => hash.push(`${step.step}£${choice.id}£${item.name.replace(" ", '')}.${item.id}`))))
    return hash.sort((a,b) => a.localeCompare(b)).join("$");
}
