import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import { useLocation, useNavigate,} from 'react-router';
import { Link,  useSearchParams } from 'react-router-dom';
import '../../../../css/Login.css';
import { react } from "@babel/types";
import { getStoreName } from "../../../../utils/storeUtils";
import { HeroBannerImage } from "./HeroBannerImage";
import { LoaderPage } from "../../../rootDomain/LoderPage";



const DineInLogin = () => {
    //for reduirection
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();

    let from = location.state?.from?.pathname || '/';
    // console.log(from + "," + window.location.host)
    // useEffect(() => {
    //     if (route === 'authenticated') {
    //         navigate(from, { replace: true });
    //     }
    // }, [route, navigate, from]);

    const [loading, setLoading] = useState<boolean>(true);

    // const navigate = useNavigate();

    useEffect(() => {
        const checkUserAuthentication = async () => {
            try {
                await Auth.currentAuthenticatedUser();
                navigate('/'); // Redirect to home if user is already logged in
            } catch (error) {
                console.error('Not authenticated:', error);
                setLoading(false); // Show login page if not authenticated
            }
        };

        checkUserAuthentication();
    }, [navigate]);

    if (loading) {
        return <LoaderPage text="Loading..." />;
    }


    return (
        <Container>
            <HeroBannerImage imageUrl = 'https://d26eb5y2jukpbz.cloudfront.net/ebs/archive/2019/media/OS_DE19085M_10.jpg' bgColor = '#278938' />
            <Typography
                style={{
                    fontStyle: 'normal',
                    fontFamily: 'Google Sans',
                    fontWeight: 700,
                    fontSize: '28px',
                    lineHeight: '35.62px',
                    color: '#1C1B1F',
                    marginTop:'40px',
                    textAlign:'left'
                }}>Explore our digital menu!</Typography>

            <Typography
                style={{
                    fontStyle: 'normal',
                    fontFamily: 'Google Sans',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#625B71',
                    marginTop:'10px',
                    textAlign:'left'
                }}>Find the most delicious food based on your interest</Typography>


            <Typography
                style={{
                    fontStyle: 'normal',
                    fontFamily: 'Google Sans',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#1C1B1F',
                    marginTop:'50px',
                    textAlign:'left'
                }}>Login Using</Typography>
                <a href = {process.env.REACT_APP_LOGIN_URL}  ><button type="button" className="google-button">
                    <span className="google-button__text">Google</span>
                </button>
                </a>
        </Container>
    );

    
    
}
export default DineInLogin;