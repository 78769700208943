import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { useFormikContext } from "formik";
import { CustomizationStep, FoodMenuItem } from "../../../../interfaces/FoodItem";
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MENU_ITEM_CUSTOMIZATION_PATH, MENU_PAGE_ROOT_PATH } from '../../../../constants/pathConstants';
import { addCurrentPage, resetSelection } from '../../../../redux/FoodItemCustomization/customizationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomizedFoodState } from '../../../../redux/FoodItemCustomization/customizationSelectors';
import { CartItem, addCartItem } from '../../../../redux/cartItems/cartItemsSlice';
import { v4 } from "uuid";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { getCartItemFromFoodItem } from '../../../../utils/CartItemsUtils';
import { useAddToCart } from '../../../../hooks/useAddToCart';

const SubmitButtonText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 700,
	lineHeight: 'normal',
	textAlign: 'left',
	color: '#FFFFFF',
  }));

  const NextStepText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: 500,
	lineHeight: 'normal',
	textAlign: 'left',
	color: '#1C1B1F',
  }));

export interface StepDetails {
	nextStepId: string;
	nextStepUrl: string;
    previousStepId: string;
	previousStepUrl: string;
	currentStep: string;
	totalStep: string;
	isLastStep: boolean;
};

export interface CustomizationSectionProps {
    step: string,
	itemId: string,
    customizationSteps: CustomizationStep[],
	existingCartItem?: CartItem,
	foodItem: FoodMenuItem,
}

const getNextStepDetails = (stepId: string, customizationSteps: CustomizationStep[], itemId: string): StepDetails => {
	let currentStep = 1, nextStepId = "", nextStepUrl = "", previousStepId = "", previousStepUrl="", isLastStep = false;
	for(var i = 0; i < customizationSteps.length; i++) {
		if (stepId == customizationSteps[i].step.toString()) {
			if (i + 1 < customizationSteps.length) {
				nextStepId = customizationSteps[i + 1].step.toString();
				nextStepUrl = MENU_ITEM_CUSTOMIZATION_PATH.replace(":stepId", nextStepId).replace(":itemId", itemId);
			}
			if (i == customizationSteps.length - 1) {
				nextStepUrl = MENU_PAGE_ROOT_PATH;
				isLastStep = true
			}
            if (i - 1 >= 0) {
                previousStepId = customizationSteps[i - 1].step.toString();
                previousStepUrl = MENU_ITEM_CUSTOMIZATION_PATH.replace(":stepId", previousStepId).replace(":itemId", itemId);
            }
			break;
		} else currentStep++;
	}

    console.log(nextStepId)

	return {
		nextStepId: nextStepId.toString(),
		nextStepUrl: nextStepUrl,
        previousStepUrl: previousStepUrl,
        previousStepId: previousStepId,
		currentStep: currentStep.toString(),
		totalStep: customizationSteps.length.toString(),
		isLastStep
	}
}


const CustomizeSubmitSection: React.FC<CustomizationSectionProps> = (props: CustomizationSectionProps) => {
    // const { values, errors } = useFormikContext();
	const [ isFormValid , setFormValid ] = useState(false);
	const selectedCustomization = useSelector(selectCustomizedFoodState);
    const [buttonText, setButtonText] = useState<string>("");
    const [nextStepDetails, setNextStepDetails] = useState<StepDetails | undefined>(undefined);
	const { user } = useAuthenticator((context) => [context.route]);

	const email: string = user.attributes?.email || '';
	const name: string = user.attributes? user.attributes['given_name'] : "John Doe";
    const {addToCartHandler, isCartSessionLoading} = useAddToCart(email, name)
    const navigate = useNavigate();
	const dispatch = useDispatch();
    const formikContext = useFormikContext();
	
    useEffect(() => {
        const nextStep = getNextStepDetails(props.step, props.customizationSteps, props.itemId)
        setNextStepDetails(nextStep);
        setButtonText(nextStep.nextStepId.length > 0 ? "CONTINUE" : "ADD TO CART");
    }, [props.step])

	const addToCartAndNavigate = () => {
		// const cutomizedSteps:CustomizationStep[] = selectedCustomization.choices[props.itemId];
		const customizedSteps = selectedCustomization.choices[props.itemId];
		const cartItem: CartItem = props.existingCartItem || getCartItemFromFoodItem(props.foodItem, email, name);// {...props.foodItem!, customizeOptions: cutomizedSteps, cartItemId: v4(), addedByEmail: email, addedByName: name};
		// cartItem.customizeOptions = cutomizedSteps;
		const mainItemId = props.itemId;
		console.log(cartItem)
		console.log('adding cart time customization..');

		const foodItem = {...props.foodItem}
		foodItem.customizeOptions = customizedSteps;
		// dispatch(addCartItem({ mainItemId, cartItem }))
		addToCartHandler(mainItemId, foodItem, true);
		
		navigate(nextStepDetails!.nextStepUrl);
	}

    const handleSubmit = () => {
		if (props.step) {
			dispatch(addCurrentPage({mainItemId: props.itemId, stepId: props.step}));
		}
        
		if (formikContext.isValid && nextStepDetails?.isLastStep ) {
			console.log("last page")
			addToCartAndNavigate()
        }
		if (formikContext.isValid && nextStepDetails?.nextStepUrl) {
            navigate(nextStepDetails.nextStepUrl);
        }
        console.log(formikContext.values);
    }

	useEffect(() => {
		setFormValid(formikContext.isValid)
	}, [props.step, props.itemId, formikContext.values, formikContext.isValid, formikContext.isValidating])
	

    const goBack = () => {
        if (nextStepDetails?.previousStepUrl) {
            navigate(nextStepDetails.previousStepUrl);
        }
        console.log(formikContext.values);
    }
	
    return ( !nextStepDetails ? <></>:
		<Box sx={{ boxSizing: 'border-box', position: 'sticky', bottom: '0', backgroundColor:'#EADDFF'}}>
			<Stack padding={3} spacing={1} direction="row">
				<Box width="50%" textAlign="left" sx={{ display: 'flex', alignItems: 'center'}}>
					<span style={{display: 'flex', alignItems: 'center'}}>{parseInt(nextStepDetails.currentStep) > 1 && <span><ArrowBackIcon onClick={()=>{ goBack() }}/>&nbsp;&nbsp;</span>}<NextStepText>Step {nextStepDetails.currentStep}/{nextStepDetails.totalStep}</NextStepText></span>
				</Box>
				<Box width="50%" textAlign="center" sx={{ display: 'flex', alignItems: 'center'}}>
					<Button sx={{ height: '50px', padding: '10px 24px', borderRadius: '25px', width: '100%',
						backgroundColor: '#6750A4', color: 'white', textTransform: 'none', ':hover': {backgroundColor: '#6750A4', color: 'white'}, '&.Mui-disabled': {backgroundColor: '#6750A4', color: 'white', 'opacity': '0.1'}}} onClick={()=>{ handleSubmit() }} disabled={!isFormValid || isCartSessionLoading}><SubmitButtonText>{buttonText}</SubmitButtonText></Button>
				</Box>
			</Stack>
		</Box>
	);
}

export default CustomizeSubmitSection