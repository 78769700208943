import { useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

export interface RestaurantFormData {
    restaurant_name: string;
    restaurant_prefix: string;
    restaurant_picture: string;
    restaurant_id: string;
    subscription_id: string;
    picture_key: string;
    restaurant_qr_logo?: string;
    qr_logo_key?: string;
}

interface RestaurantData {
    restaurant_name: string;
    restaurant_prefix: string;
    subscription_id: string;
    restaurant_picture: string;
    restaurant_id: string;
    restaurant_qr_logo: string;
}

export const useRestaurantForm = (
    onSubmit: (values: RestaurantFormData, formikHelpers: FormikHelpers<RestaurantFormData>) => void | Promise<any>,
    restaurantData: RestaurantData[] | null,
) => {
    const [initialFormValues, setInitialFormValues] = useState<RestaurantFormData>({
        restaurant_name: '',
        restaurant_prefix: '',
        restaurant_picture: '',
        restaurant_id: uuidv4(),
        subscription_id: '',
        picture_key: '',
        qr_logo_key: '',
        restaurant_qr_logo: ''
    });

    useEffect(() => {
        if (restaurantData && restaurantData.length) {
            const data = restaurantData[0];
            setInitialFormValues({
                ...initialFormValues,
                restaurant_name: data.restaurant_name,
                restaurant_prefix: data.restaurant_prefix,
                subscription_id: data.subscription_id,
                restaurant_picture: data.restaurant_picture,
                restaurant_id: data.restaurant_id,
                restaurant_qr_logo: data.restaurant_qr_logo,
            });
        }
    }, [restaurantData]);

    const restaurantInfoschema = Yup.object().shape({
        restaurant_name: Yup.string().required('Restaurant name is required'),
        restaurant_prefix: Yup.string().required('Restaurant prefix is required'),
        restaurant_prefix_final: Yup.string().required(),
        subscription_id: Yup.string().required('Subscription ID is required'),
        picture_key: Yup.string().optional(),
        restaurant_picture: Yup.string().optional(),
        restaurant_qr_logo: Yup.string().optional(),
        qr_logo_key: Yup.string().optional(),
    });

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: restaurantInfoschema,
        onSubmit: onSubmit,
        enableReinitialize: true,
        validateOnMount: true
    });

    return formik;
};
