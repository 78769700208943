import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Radio, Stack, Typography, collapseClasses, styled } from "@mui/material";
import { ErrorMessage, Field, FieldProps, FormikContext, useFormikContext } from "formik";
import { AddOnItem, FoodMenuItem, StepOption } from "../../../../interfaces/FoodItem";
import AutoTouchField from "./IntersectionObserver";
import { VegNonVegIcon } from "./CustomIcons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedOption } from "../../../../redux/FoodItemCustomization/customizationSlice";
import { selectCustomizedFoodState } from "../../../../redux/FoodItemCustomization/customizationSelectors";

const CustomizeChoicesTitle = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: 700,
	lineHeight: 'normal',
	color: '#1C1B1F',
  }));

const CustomizeOptionText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: 'normal',
	textAlign: 'left',
	color: '#79747E',
}));

const ShowMoreText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '12px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: 'normal',
	textAlign: 'left',
	color: '#6750A4',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#B3261E',
}));


export interface CustomizeFormOptionsProps {
    choice: StepOption;
    mainItemId: string;
    stepId: string;
}

export const CustomizeFormOptions: React.FC<CustomizeFormOptionsProps> = ({ choice, mainItemId, stepId }) => {
    const shouldShowMore = choice.truncateAt && choice.items.length > parseInt(choice.truncateAt);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const selectedCustomization = useSelector(selectCustomizedFoodState);
    const dispatch = useDispatch();
    const formikContext = useFormikContext();
    const collapsedItemCount = shouldShowMore ? choice.items.length - parseInt(choice.truncateAt!) : 0
    const [choiceCopy, setChoiceCopy] = useState({...choice});
    const exapandedList = choice.items.map(i => ({...i}));
    const collapsedList = choice.items.slice(0, parseInt(choice.truncateAt || choice.items.length.toString())).map(i => ({...i}));
    //show more useEffect
    useEffect(() => { 
        let currentItems:AddOnItem[] = []
        if (shouldShowMore && choice.truncateAt && isCollapsed) {
            currentItems = collapsedList
        } else {
            currentItems = exapandedList
        }
        setChoiceCopy({...choiceCopy, items: currentItems})
    }, [isCollapsed])
    
    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    const isSelected = (itemId: string): boolean => {
        const mainItem = selectedCustomization.choices[mainItemId];
        if (!mainItem) return false;
        const stepPresent = mainItem.find((v) => v.step.toString() == stepId) 
        if (!stepPresent) return false;
        const choicePresent = stepPresent.stepOptions.find(i => i.id == choice.id);
        if (!choicePresent) return false;
        const item = choicePresent.items.find(i=> i.id == itemId);
        return item != undefined;    
    }

    const handleOptionChange = (stepId: string, choice: StepOption, item: AddOnItem) => {
        console.log(formikContext.values)
        dispatch(updateSelectedOption({ mainItemId, stepId, choice, item }));
    }

    return (
        <Stack spacing={1}>
            <CustomizeChoicesTitle variant="h6">{`${choiceCopy.title} (${choiceCopy.minSelect}/${choiceCopy.maxSelect})`}</CustomizeChoicesTitle>
            <Stack key={choiceCopy.id} sx={{border: '1px solid #E4E3E5', borderRadius:'30px'}} padding={3}>
                
                <Field name={choiceCopy.id}>
                {({ field, form }: FieldProps<FoodMenuItem>) => (
                    <FormGroup>
                        { choiceCopy.items.map((item) => {
                            return (
                                    // <Stack direction='row' sx={{justifyContent:"space-between", alignItems: 'space-between', textAlign: 'center'}}>
                                        <AutoTouchField name={choiceCopy.id} key={item.id}>
                                            <FormControlLabel
                                            key={item.id}
                                            onClick={()=> handleOptionChange(stepId, choiceCopy, item)}
                                            control={
                                                choiceCopy.minSelect == 1 && choiceCopy.maxSelect == 1 ? (
                                                <Radio
                                                    sx={{
                                                        '&, &.Mui-checked': {
                                                            color: '#6750A4',
                                                        },
                                                    }}
                                                    checked={isSelected(item.id)}
                                                    {...field}
                                                    value={item.id}
                                                    name={choiceCopy.id}
                                                />
                                                ) : (
                                                <Checkbox
                                                    sx={{
                                                        '&, &.Mui-checked': {
                                                            color: '#6750A4',
                                                        },
                                                    }}
                                                    {...field}
                                                    name={choiceCopy.id}
                                                    value={item.id}
                                                    checked={isSelected(item.id)}
                                                />
                                                )
                                            }
                                            label={<Grid container sx={{width:'100%'}}>
                                                    <Grid item xs={2}><VegNonVegIcon foodType={item.dishType} /></Grid>
                                                    <Grid item xs={7}><CustomizeOptionText sx={{ whiteSpace: 'pre-line', flex: '1' }}>{item.name}</CustomizeOptionText></Grid>
                                                    <Grid item xs={3}><CustomizeOptionText>{item.price}</CustomizeOptionText></Grid>
                                                </Grid>
                                            }
                                            sx={{justifyContent: 'space-between',
                                                display: 'flex',
                                                marginLeft: '0',
                                                alignItems: 'baseline',
                                                '& span.MuiTypography-root ': {
                                                    width: '100%',
                                                    display: 'flex',
                                                    justiyContent: 'space-between',
                                                },
                                                '& span.MuiButtonBase-root': {
                                                    padding: '0'
                                                }
                                            }}
                                            labelPlacement="start"
                                            />
        
                                        </AutoTouchField>
                                    // </Stack>
                        )})}
                        {  shouldShowMore && <span onClick={handleCollapse} style={{display:'flex', justifyContent: 'center'}}><ShowMoreText>{isCollapsed ? `+${collapsedItemCount} More`: "Show Less -"}</ShowMoreText></span>}
                    </FormGroup>
                )}
                </Field>
                <ErrorMessage name={choiceCopy.id}>
                {(msg) => <FormHelperText error><ErrorText>{msg}</ErrorText></FormHelperText>}
                </ErrorMessage>
            </Stack>
        </Stack>
    )
}