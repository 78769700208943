import { useQuery } from 'react-query';
import axiosInstance from '../../../../common/axiosInstance';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbarContext } from '../../../contexts/SnackbarContext';

export const useFetchPresignedUrl = () => {
    const { setSnackbarMessage } = useSnackbarContext();

    const fetchPresignedUrl = async (fileType: string) => {
        try {
            const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/restaurants/uploadImage?fileType=${fileType}`);
            return response.data; // Assuming this returns the URL
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to get pre-signed url'
            setSnackbarMessage({ key: uuidv4(), message: errorMessage, type: 'error' });
            throw err;
        }
    };

    return { fetchPresignedUrl };
};