import React, { createContext, useContext, useState } from 'react';

export type SnackbarMessage = {
    key: string;
    message: string;
    type: 'success' | 'info' | 'warning' | 'error';
};

const SnackbarContext = createContext({
    snackbarMessage: null as SnackbarMessage | null,
    setSnackbarMessage: (message: SnackbarMessage | null) => {}
});

export const useSnackbarContext = () => useContext(SnackbarContext);


interface SnackbarProviderProps {
    children: React.ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null);

    return (
        <SnackbarContext.Provider value={{ snackbarMessage, setSnackbarMessage }}>
            {children}
        </SnackbarContext.Provider>
    );
};
