import { useQuery } from 'react-query'
import axiosInstance from '../../../../common/axiosInstance';

const getUserInfo = async () => {
    const response = await axiosInstance.get('https://api.menufy.link/restaurantBackend/users/info'); // Use the custom axios instance
    return response.data;
};

export const useFetchUserInfo = () => {
    return useQuery('userData', getUserInfo);
};
