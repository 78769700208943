import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { CustomerForm } from '../profile/ProfileFormComponent';
import { useURLQuery } from '../../../../hooks/useURLQuery';
import { useFetchCustomerInfo } from '../../../../hooks/useCustomerInfo';
import { Dialog, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const BlackTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left',
}));

const UserProfileModal = () => {
    const navigate = useNavigate();
    const query = useURLQuery();
    const userverify = query.get('userverify') === 'true';
    const [open, setOpen] = useState(false);
    const {data:customerData, isLoading} = useFetchCustomerInfo();
    useEffect(() => {

        if (userverify && !isLoading && customerData && !customerData.complete_form_later && !customerData.phone_number) {
            setOpen(true);
        }
    
    }, [query, customerData ]);

    const handleClose = () => {
        setOpen(false);
        navigate('/');
    };

    return (
        // <Modal
        //     open={open}
        //     onClose={handleClose}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        //     sx={{width: '80%', height: '80%'}}
        // >
        <Dialog open={open} onClose={handleClose}>
            
                
            {/* </Box> */}
            <DialogTitle><Box display='flex' justifyContent='space-between'><BlackTitleText>Tell us about you!</BlackTitleText><CancelIcon onClick={handleClose}/></Box></DialogTitle>
            <DialogContent>
                <CustomerForm showRemindLater={true} setModalOpen={setOpen}/>
            </DialogContent>
        </Dialog>
    );
};

export default UserProfileModal;
