import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Stack, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useBreadcrumb } from '../../../contexts/BreadcrumbContext';
import { useSnackbarContext } from '../../../contexts/SnackbarContext';
import { useFetchRestaurantInfo } from '../hooks/useFetchRestaurantInfo';
import { usePutRestaurantInfo } from '../hooks/usePutRestaurantInfo';
import { useDeleteRestaurantInfo } from '../hooks/useDeleteRestaurant';
import { useImageUpload } from '../hooks/useImageUpload';
import { useRestaurantForm } from '../hooks/useRestaurantForm';
import ImageUploadComponent from '../components/ImageUploadComponent';
import ActionButtonsComponent from '../components/ActionButtonComponent';
import { FormikProvider } from 'formik';
import SubscriptionDropdownComponent from '../components/SubscripitionDropdownComponent';
import { RestaurantPrefixComponent } from '../components/RestaurantPrefixComponent';
import { CreateUpdateSkeleton } from './CreateUpdateSkeleton';
import { CustomizeQRCodeComponent } from '../components/CustomizeQRCodeComponent';

export const CreateUpdateRestaurant:React.FC = () => {
    const { pathname } = useLocation();
    const isCreateOperation = pathname.endsWith('/create');
    const restaurantId = isCreateOperation ? null : pathname.split('/').pop();

    const { setBreadcrumbName } = useBreadcrumb();
    const { setSnackbarMessage } = useSnackbarContext();
    const { data: restaurantData, isLoading } = useFetchRestaurantInfo(restaurantId);
    const { mutate: updateRestaurant, isSubmitting: isUpdating } = usePutRestaurantInfo();
    const { mutate: deleteRestaurant, isSubmitting: isDeleting } = useDeleteRestaurantInfo();
    const { uploadFile, isUploading: isImageUploading } = useImageUpload();
    const [imageUrl, setImageUrl] = useState<string>("");

    const handleUserDataSubmit = (values: any) => {
        console.log(values);
        updateRestaurant({variables: values, key: ['restaurantInfo', values.restaurant_id], invalidate: true});
    };

    const formik = useRestaurantForm(handleUserDataSubmit, restaurantData);

    useEffect(() => {
        if (restaurantData && restaurantData.length === 1) {
            const data = restaurantData[0];
            setImageUrl(data.restaurant_picture);
            setBreadcrumbName(restaurantId || 'new', data.restaurant_name);
        } else {
            setBreadcrumbName(restaurantId || 'new', isCreateOperation ? 'Create' : 'Not found');
        }
    }, [restaurantData, restaurantId, isCreateOperation]);

    const handleFileChangeAndUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const ret = await uploadFile(file, ['image/jpeg', 'image/png'], 10485760);
            formik.setFieldValue('picture_key', ret?.key);
            setImageUrl(ret?.viewUrl);
        } else {
            setSnackbarMessage({ key: uuidv4(), message: "No file chosen", type: 'error' });
        }
    };

    const removeImage = () => {
        setImageUrl("");
        formik.setFieldValue('picture_key', '');
        formik.setFieldValue('restaurant_picture', '');
    };
    if (isLoading) return (<CreateUpdateSkeleton/>);
    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ImageUploadComponent
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            handleFileChangeAndUpload={handleFileChangeAndUpload}
                            isImageUploading={isImageUploading}
                            removeImage={removeImage}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Stack spacing={2}>
                            <TextField
                                label="Restaurant Name"
                                name="restaurant_name"
                                value={formik.values.restaurant_name}
                                onChange={formik.handleChange}
                                disabled={!isCreateOperation}
                                error={formik.touched.restaurant_name && Boolean(formik.errors.restaurant_name)}
                                helperText={formik.touched.restaurant_name && formik.errors.restaurant_name}
                            />
                            <SubscriptionDropdownComponent disabled={!isCreateOperation}/>
                            <RestaurantPrefixComponent />
                            <CustomizeQRCodeComponent logo={formik.values.restaurant_qr_logo}/>
                        </Stack>
                    </Grid>
                </Grid>

                <ActionButtonsComponent
                    isUpdating={isUpdating}
                    isImageUploading={isImageUploading}
                    isCreateOperation={isCreateOperation}
                    isDeleting={isDeleting}
                    deleteRestaurant={() => deleteRestaurant({variables: { restaurant_id: restaurantId }, key: ['restaurantInfo', formik.values.restaurant_id], invalidate: true})}
                    restaurantName={restaurantData?.[0]?.restaurant_name || ''}
                />
            </form>
        </FormikProvider>
    );
};

