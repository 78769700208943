import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, FilledTextFieldProps, FormControlLabel, OutlinedTextFieldProps, Stack, StandardTextFieldProps, Switch, TextField, TextFieldVariants } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFetchCustomerInfo } from '../../../../hooks/useCustomerInfo';
import { usePutCustomerInfo } from '../../../../hooks/usePutCustomerInfo';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { LoaderPage } from '../../../rootDomain/LoderPage';

export interface CustomerInfo {
    first_name: string;
    last_name: string;
    email_id: string;
    date_of_birth: string;
    phone_number: string;
    consent_to_advertising: boolean;
    complete_form_later: boolean;
}

export interface UserProfileFormProps {
    showRemindLater?: boolean
    setModalOpen?: (close: boolean) => void
}

export const CustomerForm: React.FC<UserProfileFormProps> = ({showRemindLater, setModalOpen}) => {
    const navigate = useNavigate();
    const { user } = useAuthenticator((context) => [context.route]);
    // const { data: userData, isLoading: isUserDataLoading } = useFetchUserInfo();
    // const { mutate: updateUser, isSubmitting } = usePutUserInfo();
    const {data: userData, isLoading: isUserDataLoading} = useFetchCustomerInfo();
    const {mutate: updateCustomerInfo, isSubmitting} = usePutCustomerInfo();
    // const isUserDataLoading = false, isSubmitting= false;

    const userInfoSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email_id: Yup.string().required('Last name is required'),
        date_of_birth: Yup.date().nullable().required('Date of birth is required'),
        consent_to_advertising: Yup.boolean().required('value for consent needed'),
        phone_number: Yup.string().optional().test(
            'isValidPhoneNumber',
            'Phone not valid',
            value => !value || isValidPhoneNumber(value)
        ),
    });

    const [initialFormValues, setInitialFormValues] = useState({
        first_name: '',
        last_name: '',
        email_id: '',
        date_of_birth: dayjs('01-01-1991','DD-MM-YYYY').toDate(),
        phone_number: '',
        consent_to_advertising: true,
        complete_form_later: false
    });

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: userInfoSchema,
        onSubmit: (values) => {
            // updateUser(values);
            // updateStoreInfo({variables: {"storeId": id, "foodMenuItemsInput": values.foodMenuItemsInputs}, key: ['storeMenu', id], invalidate: true})

            updateCustomerInfo({variables: values, key: ['customerData'], invalidate: true})
            // console.log(values);
            if(setModalOpen) {
                navigate("/")
                setModalOpen(false);
            } 
        },
        enableReinitialize: true,
    });


    

    useEffect(() => {
        if (userData) {
            const [firstName, ...lastNameParts] = (user.attributes ? user.attributes['given_name'] : "John Doe").split(' ');
            const lastName = lastNameParts.join(' ');

            setInitialFormValues({
                first_name: userData.first_name || firstName,
                last_name: userData.last_name || lastName,
                email_id: userData.email_id || user.attributes?.email || "",
                date_of_birth: dayjs(userData.date_of_birth,'DD-MM-YYYY').toDate(),
                consent_to_advertising: userData.consent_to_advertising,
                phone_number: userData.phone_number || '',
                complete_form_later: userData.complete_form_later
            });
        }
    }, [userData, formik.submitCount]);

    

    
    useEffect(() => {
        console.log(formik.values);
    }, [formik.values])

    if (isUserDataLoading) {
        return <LoaderPage text="loading profile info.."/>
    }



    return (
        
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2} sx={{ mt: 1 }}>
                    <TextField
                        fullWidth
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                        helperText={formik.touched.first_name && formik.errors.first_name}
                    />
                    <TextField
                        fullWidth
                        id="last_name"
                        name="last_name"
                        label="Last Name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                        helperText={formik.touched.last_name && formik.errors.last_name}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disableFuture
                            label="Date of birth"
                            name="date_of_birth"
                            format="DD-MM-YYYY"
                            value={dayjs(formik.values.date_of_birth)}
                            onChange={(value: string | number | Date | dayjs.Dayjs | null | undefined) => {
                                formik.setFieldValue('date_of_birth', value)
                            }}
                            slots={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <MuiPhoneNumber
                        fullWidth
                        defaultCountry={'ae'}
                        disableAreaCodes={true}
                        inputMode="numeric"
                        inputProps={{ inputMode: 'numeric' }}
                        id="phone_number"
                        name="phone_number"
                        label="Phone Number"
                        value={formik.values.phone_number}
                        onChange={(value) => {formik.setFieldValue('phone_number', value); console.log(value)}}
                        error={formik.touched.phone_number && Boolean
                            (formik.errors.phone_number)}
                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.consent_to_advertising}
                                    onChange={formik.handleChange}
                                    name="consent_to_advertising"
                                    color="primary"
                                />
                            }
                            label="Consent to Advertising"
                        />
                        {showRemindLater && <FormControlLabel
                            control={
                                <Switch
                                    checked={formik.values.complete_form_later}
                                    onChange={formik.handleChange}
                                    name="complete_form_later"
                                    color="primary"
                                />
                            }
                            label="Fill later/Don't remind again"
                        />}

                        </Stack>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isSubmitting}
                        >
                        Save Changes
                        </Button>
                    
                </form>
    )
}