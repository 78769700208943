import { useAuthenticator } from "@aws-amplify/ui-react";
import { Typography } from "@mui/material"
import { Auth } from "aws-amplify";

export const HomePage: React.FC = () => {
    const { user } = useAuthenticator((context) => [context.route]);
    const name = user.attributes ? user.attributes['given_name'] : "John Doe"

    return (
         <Typography variant="h4">Hello {name}, Welcome</Typography>
    )
}


// {!(userInfo.length && userInfo[0].first_name.length) && <EditUserInfo/>}