import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Container, FilledTextFieldProps, OutlinedTextFieldProps, Stack, StandardTextFieldProps, TextField, TextFieldVariants } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFetchUserInfo } from '../hooks/useFetchUserInfo';
import { usePutUserInfo } from '../hooks/usePutUserInfo';

const userInfoSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    date_of_birth: Yup.date().nullable().required('Date of birth is required'),
    phone_number: Yup.string().required('Phone number is required'),
});

const EditUserInfo = () => {

    const { user } = useAuthenticator((context) => [context.route]);

    const { data: userData, isLoading: isUserDataLoading } = useFetchUserInfo();
    const { mutate: updateUser, isSubmitting } = usePutUserInfo();

    const [firstName, ...lastNameParts] = (user.attributes ? user.attributes['given_name'] : "John Doe").split(' ');
    const lastName = lastNameParts.join(' ');
    
    const [initialFormValues, setInitialFormValues] = useState({
        first_name: '',
        last_name: '',
        date_of_birth: dayjs('01-01-1991','DD-MM-YYYY').toDate(),
        phone_number: '',
    });

    let initialValuesSet = false;

    
    useEffect(() => {
        const fetchData = async () => {
            if (userData && userData.length > 0 && !initialValuesSet) {
                setInitialFormValues({
                    first_name: userData[0].first_name || firstName,
                    last_name: userData[0].last_name || lastName,
                    date_of_birth: dayjs(userData[0].date_of_birth || '01-01-1991','DD-MM-YYYY').toDate(), //convertAPIDateToDayjs(userData[0].date_of_birth).format('DD-MM-YYYY'),//userData[0].date_of_birth || '',
                    phone_number: userData[0].phone_number || '',
                });
                initialValuesSet = true;
                console.log("came here");
            }
        }
        fetchData();
    }, [userData]);

    const handleUserDataSubmit = (formData: any) => {
        updateUser({variables: formData, optimisticUpdate: true, key:['userData']});
    };


    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: userInfoSchema,
        onSubmit: async (values) => {
            handleUserDataSubmit(values);
        },
        enableReinitialize: true
    });

   
    if (isUserDataLoading ) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Container>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            label="First Name"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={(e) => { console.log(formik.values); formik.handleChange(e)}}
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                            margin="normal"
                            helperText={formik.touched.first_name && formik.errors.first_name}
                        />
                        <TextField
                            label="Last Name"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                            margin="normal"
                            helperText={formik.touched.last_name && formik.errors.last_name}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disableFuture
                                label="Date of birth"
                                name="date_of_birth"
                                format="DD-MM-YYYY"
                                value={dayjs(formik.values.date_of_birth)}
                                onChange={(value: string | number | Date | dayjs.Dayjs | null | undefined) => {
                                    formik.setFieldValue('date_of_birth', value)
                                }}
                                slots={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                                    />
                                )}
                            />
                        </LocalizationProvider>

                        <MuiPhoneNumber
                            defaultCountry={'ae'}
                            variant="outlined"
                            disableAreaCodes
                            label="Phone Number"
                            name="phone_number"
                            value={formik.values.phone_number}
                            onChange={(value) => {
                                formik.setFieldValue('phone_number', value);
                            }}
                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                        />
                    </Stack>

                    <Button variant="contained" type="submit" sx={{ marginTop: 2 }} disabled={isSubmitting || !formik.isValid || formik.isSubmitting}>
                        Save Changes
                    </Button>
                </Container>
            </form>
        </>
    );
};

export default EditUserInfo;