import React, { useState } from 'react';
import { TextField, Button, Stack } from '@mui/material';

interface ConfirmDeleteComponentProps {
    onConfirmDelete: () => void;
    confirmationText: string;
    isDeleting: boolean;
}

const ConfirmDeleteComponent: React.FC<ConfirmDeleteComponentProps> = ({ onConfirmDelete, confirmationText, isDeleting }) => {
    const [inputText, setInputText] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setInputText(text);
        setIsButtonEnabled(text === confirmationText);
    };

    const [showConfirmationOptions, setShowConfirmationOptions] = useState(false);

    const toggleConfirmationOptions = () => {
        const currentState = showConfirmationOptions;
        setShowConfirmationOptions(!currentState);
    }

  return (
    <Stack spacing={1}>
        <Button variant="contained" color={showConfirmationOptions ? "primary" : "error"} onClick={toggleConfirmationOptions}>
            {showConfirmationOptions ? "Cancel Delete": "Delete"}
        </Button>
        {
            showConfirmationOptions &&  (<>
                <TextField
                    label="Confirmation Text"
                    variant="outlined"
                    value={inputText}
                    onChange={handleInputChange}
                    helperText={`Type "${confirmationText}" to confirm.`}
                />
                <Button
                    onClick={onConfirmDelete}
                    disabled={!isButtonEnabled || isDeleting}
                    variant="contained"
                    color="error"
                >
                Delete
                </Button>
            </>
                
            )
        }
        
    </Stack>
  );
};

export default ConfirmDeleteComponent;
