import { Box, Typography, styled } from "@mui/material";
import { useField } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import { SectionCategory } from "../cateogories/CategoryList";
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import { FoodMenuItemInput } from "../../../hooks/useMenuForm";

export interface SideListItemProps {
    sectionInfo: SectionCategory;
    editMode?: boolean;
    onClick: () => void;
    index: number;
}

const DishesListItemText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px', // Increased font size for better visibility
    fontWeight: 500, // Make it slightly bolder
    textAlign: 'left',
    color: '#1C1B1F',
    padding: theme.spacing(2), // Add padding for more space
    cursor: 'pointer', // Change cursor to indicate clickability
    '&:hover': {
        backgroundColor: theme.palette.action.hover, // Add hover effect
    }
}));

interface DishesListItemProps {
    index: number; // Index in the Formik array
    onClick: () => void;
    foodMenuItemInput: FoodMenuItemInput;
    movePosition: (index: number, direction: 'up' | 'down') => void;
}
  
export const DishesListItem: React.FC<DishesListItemProps> = ({ index, onClick, foodMenuItemInput: foodMenuItemInput, movePosition }) => {

    const { foodMenuItemId } = useParams();
    const isActiveLink = foodMenuItemId != undefined && foodMenuItemInput.id == foodMenuItemId;
    
    return (
        <Box sx={{ width: '100%', backgroundColor: isActiveLink ? 'primary.main': '#fff', display: 'flex', alignItems: 'center' }} onClick={onClick}> 
             <Box sx={{ width: '10%', display: 'flex', marginRight: '20px' }} onClick={onClick}>
                
                <ArrowCircleUpRoundedIcon onClick={ (e) => { e.stopPropagation(); movePosition(index, 'up')}}/>
                <ArrowCircleDownRoundedIcon onClick={(e) => { e.stopPropagation(); movePosition(index, 'down')}}/>
             </Box>
            <DishesListItemText>
                {foodMenuItemInput.dishName}
            </DishesListItemText>
        </Box>
    );

};