import { Avatar, Badge, BottomNavigation, BottomNavigationAction, Box, Chip, Paper } from "@mui/material";
import { useLocation, Link, Outlet } from 'react-router-dom';
import { useState } from "react";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { SvgIconComponent } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectTotalCartCount } from "../../../../redux/cartItems/cartItemsSelectors";

interface CustomChipProps {
    isActive: boolean;
    icon: React.ReactElement<SvgIconComponent>;
    iconActive: React.ReactElement<SvgIconComponent>;
  }
  
  const CustomChip: React.FC<CustomChipProps> = ({ isActive, icon, iconActive }) => {
    return (
      <Chip
        label={isActive ? iconActive: icon}
        variant="outlined"
        size="small"
        sx={{
            borderRadius: '50px',
            backgroundColor: isActive ? '#6750A4' : 'transparent',
            color: isActive ? '#fff' : 'inherit',
            padding: '1rem',
            border: '0',
            marginBottom: '2px',
            'a > span.MuiBottomNavigationAction-label': {
                color: '#6750A4',
            },
            '> .MuiChip-label': {
                overflow:'visible!important'
            }
        }}
      />
    );
};

interface FooterProps {
    page: string;
}

export const DineInStickyFooter:React.FC<FooterProps> = ({ page }) => {
    const [active, setActive] = useState<string>(page);
    const cartItemCount = useSelector(selectTotalCartCount);
    const handleChange = (event: React.ChangeEvent<{}>, val: string) => {
        setActive(val);
    };
    return (
        <>
        <Outlet/>
        <Box sx={{ height:'8Rem'}}></Box>
        <BottomNavigation
            value={useLocation().pathname}
            showLabels
            onChange={handleChange}
            sx={{position: 'fixed', zIndex: 99, bottom: '0', fontSize: '1rem', paddingY: '1em', width: '100%', backgroundColor:'#F3EDF7', boxShadow:'0', color: '#6750A4'
            ,'& .MuiBottomNavigationAction-label': {
                fontSize: '12px',
              },
              '& .Mui-selected': {
                fontSize: '12px!important',
              },
              '&. MuiBottomNavigationAction-label': {
                color: '#6750A4'
              },
              opacity: 0.92,
              paddingTop: '3px',
              paddingBottom: '0px'
        }}
        >
            <BottomNavigationAction
                label="Home"
                value={'home'}
                component={Link}
                to='/'
                sx={{padding:'0', 'span.MuiBottomNavigationAction-label': {
                    color: active === 'home' ? '#6750A4': 'inherit'
                }}}
                icon={<CustomChip isActive={active === 'home'} icon={<WidgetsOutlinedIcon/>} iconActive={<WidgetsIcon/>} />}
            />
            {/* <Link to='/'> */}
            <BottomNavigationAction
                label="Menu"
                component={Link}
                to='/menu'
                value={'menu'}
                sx={{padding:'0', 'span.MuiBottomNavigationAction-label': {
                    color: active === 'menu' ? '#6750A4': 'inherit'
                }}}
                icon={<CustomChip isActive={active === 'menu'}  icon={<RestaurantMenuIcon/>} iconActive={<RestaurantMenuIcon/>} />}
            />
            {/* </Link> */}
            {/* <Link to='/profile'> */}
            <BottomNavigationAction
                label="Profile"
                component={Link}
                to='/profile'
                value='profile'
                sx={{padding:'0', 'span.MuiBottomNavigationAction-label': {
                    color: active === 'profile' ? '#6750A4': 'inherit'
                }}}
                icon={<CustomChip isActive={active === 'profile'} icon={<AccountCircleOutlinedIcon />} iconActive={<AccountCircleIcon/>} />}
            />
            {/* </Link> */}
            <BottomNavigationAction
                label="Cart"
                component={Link}
                to='/checkout'
                value={'cart'}
                icon={<CustomChip isActive={active === 'cart'} icon={<Badge badgeContent={cartItemCount} color="primary"><ShoppingCartOutlinedIcon /></Badge>} iconActive={<Badge badgeContent={cartItemCount} color="primary"><ShoppingCartIcon/></Badge>}/>}
                sx={{padding:'0', 'span.MuiBottomNavigationAction-label': {
                    color: active === 'cart' ? '#6750A4': 'inherit',
                }}}
            />
    </BottomNavigation>
    </>
    );
}