// hooks/useItemsQuery.ts
import { useQuery } from 'react-query';
import axios from 'axios';
import { FoodMenuItem } from '../interfaces/FoodItem';
import useCookie from './useCookie';
import axiosInstance from '../components/common/axiosInstance';

export interface CartSessionInfo {
    store_id: string,
    cart_session_id: string,
    table_id: string,
    ttl_key: string,
    join_code: number
}

const fetchItems = async (storeId: string, tableId: string, tableNumber: string, setCookie: (string: any, data: any) => void): Promise<CartSessionInfo> => {
  const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/stores/cartSession?storeId=${storeId}&tableId=${tableId}&tableNumber=${tableNumber}`);
  console.log(response.data)
  if (!response.data) {
    throw new Error('Network response was not ok');
  }
  if (response.data && response.data.cart_session_id) setCookie('cartSessionId', response.data.cart_session_id)
  return response.data || [];
};

export const useCartSession = (disabled?: boolean) => {
  const { getCookie } = useCookie();
  const store_id_from_cookie = getCookie('storeId') || "";
  const table_id_from_cookie = getCookie('tableId') || "";
  const table_number_from_cookie = getCookie('tableNumber') || "";
  const {setCookie} = useCookie();
  return useQuery<CartSessionInfo, Error>(['cartSession'], () => fetchItems(store_id_from_cookie, table_id_from_cookie, table_number_from_cookie, setCookie), {enabled: !disabled});
}
