import { Button, Divider, Stack } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { CategoryNameSaveSection } from "../../editItem/CategoryNameSaveComponent";
import { DescriptionImageSection } from "../../editItem/DescriptionImageComponent";
import { TagsSection } from "../../editItem/TagsSection";
import { PriceSection } from "../../editItem/PriceSection";
import { ListCustomization } from "../../editItem/CustomizationStepSearch";
import { useParams } from "react-router-dom";
import { FoodMenuItemInput } from "../../../hooks/useMenuForm";
import { ErrorsSection } from "../../editItem/ErrorsSections";
import { DownLoadOrUploadMenu } from "../../editItem/DownloadOrUploadMenu";


export const DishItemEdit: React.FC = () => {
    // if (!index) return <>Nothing chosen!</>
    const { foodMenuItemId } = useParams<{ foodMenuItemId: string | undefined }>();
    const { errors, values, } = useFormikContext<{ foodMenuItemsInputs: FoodMenuItemInput[] }>();
    const index = (values && values.foodMenuItemsInputs && values.foodMenuItemsInputs.length) ? values.foodMenuItemsInputs.findIndex(item => item.id == foodMenuItemId): -1;
    
    if (index < 0) return (
        <Stack spacing={3}>
            <h4>Nothing chosen..click a dish to view/edit</h4>
            <h4> --- or --- </h4>
            <DownLoadOrUploadMenu/>
        </Stack>
    );
    return (
        <Stack spacing={2} sx={{border: '1px solid black', minHeight: '600px', borderRadius: '10px'}} padding={1} divider={<Divider sx={{marginY: 2}}/>}>
            {(Object.keys(errors).length > 0) && <ErrorsSection/> }
            <CategoryNameSaveSection index={index}/>
            <DescriptionImageSection index={index}/>
            <TagsSection index={index}/>
            <PriceSection index={index}/>
            <ListCustomization index={index} />
        </Stack>
    )
}