import { AddOnItem, CustomizationStep } from '../../interfaces/FoodItem';
import { calculateTotalSum, getTotalSumInNumberAndString } from '../../utils/priceCalculatorUtil';
import { RootState } from '../stores/mainStore';
import { CartItem } from './cartItemsSlice';

export const selectCartItemsState = (state: RootState) => state.cartItems;

export interface CartItemSummary {
    foodItemId: string,
    foodItemName: string,
    dishType: 'veg' | 'non-veg',
    quantity: number,
    customizationId: string,
    customizations?: AddOnItem[]
    totalAmount: number,
    totalAmountString: string,
    itemUnitPriceText: string,
    itemUnitPrice: number,
    dishPriceText: string,
    dishPrice: number,
    sampleCartItemIUUID: string,
    addedOnTimestamp: number,
    customizationStep?: CustomizationStep[]
}

export interface CartItemDetails {
    email: string,
    name: string,
    cartItemSummary: CartItemSummary[]
}

export interface CartItemSummaryWithNameEmailTimeStamp extends CartItemSummary {
    name: string;
    email: string;
}

export const SelectCartItems = (state: RootState) => {
    const ret: CartItem[] = [];
    for (const email of Object.keys(state.cartItems.cartItems)) {
        for (const itemId of Object.keys(state.cartItems.cartItems[email])) {
            ret.push(...state.cartItems.cartItems[email][itemId]);
        }
    }
    return ret;
}

export const SelectCartItemSummaryWithNameEmailTimeStamp = (state: RootState): CartItemSummaryWithNameEmailTimeStamp[] => {
    const cartItemsByUserAndTimeStamp: CartItemSummaryWithNameEmailTimeStamp[] = [];
    for (const email of Object.keys(state.cartItems.cartItems)) {
        let name = ""
        for (const itemId of Object.keys(state.cartItems.cartItems[email])) {
            const cartItems: CartItem[] = [];
            state.cartItems.cartItems[email][itemId].forEach(cartItem => {
                cartItems.push(cartItem);
            })
            const [addedByName, itemsSummarized]= squashAndSummarize(cartItems);
            name = addedByName
            itemsSummarized.forEach(item => cartItemsByUserAndTimeStamp.push({...item, name, email, addedOnTimestamp: itemsSummarized[0].addedOnTimestamp}));
        }
    }
    return cartItemsByUserAndTimeStamp.sort((a,b) => a.addedOnTimestamp - b.addedOnTimestamp);
    // return ret;
}

export const selectResponseState = (state: RootState) => state.cartItems.response;

export const selectTotalCartCount = (state: RootState) : number => {
    let ret = 0;
    // const ret: {[email: string]: {[itemId : string]: number}} = {}
    for (const email of Object.keys(state.cartItems.cartItems)) {
        // ret[email] = {}
        for (const itemId of Object.keys(state.cartItems.cartItems[email])) {
            const val = state.cartItems.cartItems[email][itemId].length;
            ret += val
        }
    }
    return ret;
}

export const selectCountOfMenuItemWithItemIdAndEmail = (state: RootState) : {[emailKey: string]: {[itemIdKey : string]: number}} => {
    const ret: {[email: string]: {[itemId : string]: number}} = {}
    for (const email of Object.keys(state.cartItems.cartItems)) {
        ret[email] = {}
        for (const itemId of Object.keys(state.cartItems.cartItems[email])) {
            const val = state.cartItems.cartItems[email][itemId].length;
            ret[email][itemId] = val
        }
    }
    return ret;
}


export const selectItemIdCountsAfterSquashCompress = (state: RootState) : {[key: string]: CartItemDetails} => {
    const ret: {[key: string]: CartItemDetails} = {};
    for (const email of Object.keys(state.cartItems.cartItems)) {
        const cartItemsByUser: CartItemSummary[] = [];
        let name = ""
        for (const itemId of Object.keys(state.cartItems.cartItems[email])) {
            const cartItems: CartItem[] = [];
            state.cartItems.cartItems[email][itemId].forEach(cartItem => {
                cartItems.push(cartItem);
            })
            const [addedByName, itemsSummarized]= squashAndSummarize(cartItems);
            name = addedByName
            itemsSummarized.forEach(item => cartItemsByUser.push(item));
        }
        console.log(name);
        ret[email] = {email, name: name, cartItemSummary: cartItemsByUser}
    }
    console.log(ret);
    return ret
}

const squashAndSummarize = (cartItems: CartItem[]) : [string, CartItemSummary[]] => {
    const name = cartItems[0].addedByName
    const cartCustomizationMap: {[key: string]: CartItemSummary} = {}
    const cartItemSummaries: CartItemSummary[] = []
    cartItems.forEach(cartItem => {
        const [customizationHash, cartItemSummary] = getCustomizationHash(cartItem);
        if (!(customizationHash in cartCustomizationMap)) {
            cartCustomizationMap[customizationHash] = cartItemSummary
        } else {

            cartCustomizationMap[customizationHash].addedOnTimestamp = Math.min(cartItemSummary.addedOnTimestamp, cartCustomizationMap[customizationHash].addedOnTimestamp)
            cartCustomizationMap[customizationHash].quantity += 1
            cartCustomizationMap[customizationHash].totalAmount += cartItemSummary.itemUnitPrice
            cartCustomizationMap[customizationHash].totalAmountString = calculateTotalSum([cartItemSummary.itemUnitPriceText, cartCustomizationMap[customizationHash].totalAmountString])
        }
    })
    for (const hash in cartCustomizationMap) cartItemSummaries.push(cartCustomizationMap[hash])
    return [name, cartItemSummaries]
}

const getCustomizationHash = (cartItem: CartItem) : [string, CartItemSummary] => {
    const hash = getHash(cartItem);
    const foodItemId = cartItem.menu_item_id
    const dishType = cartItem.dishType
    const foodItemName = cartItem.dishName
    const quantity = 1
    const customizationId = hash
    const sampleCartItemIUUID = cartItem.cartItemId
    const customizations: AddOnItem[] = []
    const [itemUnitPrice, itemUnitPriceText] = getTotalSumInNumberAndString(cartItem);
    const customizationStep = cartItem.customizeOptions;
    const dishPriceText = cartItem.dishPriceText;
    const dishPrice = cartItem.dishPrice;
    const addedOnTimestamp = cartItem.addedOnTimestamp;
    cartItem.customizeOptions?.forEach(step => step.stepOptions.forEach(choice => choice.items.forEach(item => customizations.push({...item}))))
    const [totalAmount, totalAmountString] = getTotalSumInNumberAndString(cartItem)
    return [hash, {foodItemId,foodItemName, addedOnTimestamp, dishType, quantity, customizationId, dishPrice, customizations, totalAmount, totalAmountString, customizationStep, sampleCartItemIUUID, itemUnitPriceText, itemUnitPrice, dishPriceText}]
}

const getHash = (cartItem: CartItem) : string => {
    let hash: string[] = [cartItem.menu_item_id];
    cartItem.customizeOptions?.forEach(step => step.stepOptions.forEach(choice => choice.items.forEach(item => hash.push(`${step.step}£${choice.id}£${item.name.replace(" ", '')}.${item.id}`))))
    return hash.sort((a,b) => a.localeCompare(b)).join("$");
}
