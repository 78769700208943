import Cookies from 'js-cookie';
import { useCallback } from 'react';

interface UseCookie {
  setCookie: (name: string, value: string, days?: number) => void;
  getCookie: (name: string) => string | undefined;
  deleteCookie: (name: string) => void;
}

const useCookie = (): UseCookie => {
  const secure: boolean = process.env.REACT_APP_COOKIE_STORE_SECURE === 'true';
  const domain: string = process.env.REACT_APP_COOKIE_STORE_DOMAIN || '';

  const setCookie = useCallback((name: string, value: string, days: number = 30): void => {
    const options = {
      expires: days,
      path: '/',
      secure,
      ...(domain ? { domain } : {})
    };
    console.log(name);
    console.log(value);
    Cookies.set(name, value, options);
  }, [secure, domain]);

  const getCookie = useCallback((name: string): string | undefined => {
    return Cookies.get(name);
  }, []);

  const deleteCookie = useCallback((name: string): void => {
    const options = { path: '/', ...(domain ? { domain } : {}) };
    Cookies.remove(name, options);
  }, [domain]);

  return { setCookie, getCookie, deleteCookie };
};

export default useCookie;
