import { useAuthenticator } from '@aws-amplify/ui-react';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import { Box, CardMedia, Divider, Grid, Rating, Stack, Typography, styled } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ITEM_DETAIL_PATH, MENU_ITEM_CUSTOMIZATION_PATH, MULTICUSOMTIZATION_DELETE_PATH } from "../../../../constants/pathConstants";
import { FoodMenuItem } from "../../../../interfaces/FoodItem";
import { MenuPageCardSection } from "../../../../interfaces/MenuPageCardSection";
import { selectCountOfMenuItemWithItemIdAndEmail, selectItemIdCountsAfterSquashCompress } from "../../../../redux/cartItems/cartItemsSelectors";
import { CartItem, addCartItem, removeCartItem } from "../../../../redux/cartItems/cartItemsSlice";
import { getCartItemFromFoodItem } from "../../../../utils/CartItemsUtils";
import { VegNonVegIcon } from "./CustomIcons";
import AddButton from "./DineInItemAddToCartButton";
import { useAddToCart } from '../../../../hooks/useAddToCart';
import { AppDispatch } from '../../../../redux/stores/mainStore';
import { selectCustomerFavorites } from '../../../../redux/customerFavourites/CustomerFavouritesSelectors';
import useCookie from '../../../../hooks/useCookie';
import { optimisticAddToFavorites, optimisticRemoveFromFavorites } from '../../../../redux/customerFavourites/CustomerFavouritesSlice';
import { addFavorite, removeFavorite } from '../../../../redux/customerFavourites/CustomerFavouriteThunks';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { useAddToFavourite } from '../../../../hooks/useAddToFavourite';

export const MenuItemTitle = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left'
  }));

export const MenuItemPrice = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#218D0F'
}));

export const CustomizeText = styled(Typography)(({ theme }) => ({
  marginTop:'5px',
  fontFamily: 'Google Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textAlign: 'center',
  color: '#218D0F'
}));


const MenuItemBriefDescription = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#625B71',
}));


export const MenuItemRatingNumber = styled(Typography)(({ theme }) => ({
  fontFamily: 'Google Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#F5A61D',
  textAlign: 'center',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
}));

const MenuItemRMenuItemRatingBy = styled(Typography)(({ theme }) => ({
  fontFamily: 'Google Sans',
  fontSize: '14px',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: '#79747E',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
}));


const LandingPageCategoryTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontFamily: 'Google Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  color: '#1C1B1F',
  textAlign: 'left'
}));

export type ItemCardSectionProps = {
  menuPageCardSection : MenuPageCardSection[]
} 

export const ItemCardSection: React.FC<ItemCardSectionProps> = ({ menuPageCardSection }) => {
  // const { menuPageCardSection } = useMenuPageFoodItemsWithSearchAndFilters();
  console.log('menu sectopns in real')
  console.log(menuPageCardSection)
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.route]);

  const email: string = user.attributes?.email || '';
  const name: string = user.attributes? user.attributes['given_name'] : "John Doe";
  const menuItemCountMap = useSelector(selectCountOfMenuItemWithItemIdAndEmail);
  const cartSummaryMap = useSelector(selectItemIdCountsAfterSquashCompress);
  const {addToCartHandler, isCartSessionLoading, removeFromCartHandler} = useAddToCart(email, name);


  const handleIncrement = (item: FoodMenuItem) => {
    addToCartHandler(item.id, item);
    // if (item.customizeOptions && item.customizeOptions.length > 0) {
    //   navigate(MENU_ITEM_CUSTOMIZATION_PATH.replace(':itemId', item.id).replace(":stepId", item.customizeOptions[0].step.toString()))
    // } else {
    //   const cartItem: CartItem = getCartItemFromFoodItem(item, email, name)
    //   const mainItemId = item.id;
    //   dispatch(addCartItem({ mainItemId, cartItem }))
    // }
  };

  // const removeItemFromBackendAnd = (item: FoodMenuItem) => {
  //   const 
  // }


  const getCount = (itemId: string) : number => {
    if (email in menuItemCountMap && itemId in menuItemCountMap[email]) return menuItemCountMap[email][itemId];
    return 0
  }

  const handleDecrement = (item: FoodMenuItem) => {
    if (getCount(item.id) <= 0) return;
    if (item.customizeOptions && item.customizeOptions.length > 0) {
      navigate(MULTICUSOMTIZATION_DELETE_PATH.replace(":itemId", item.id))
    } else {
      const cart_item_id =  cartSummaryMap[email].cartItemSummary.filter(summary => summary.foodItemId == item.id)[0].sampleCartItemIUUID;
      // const { } = cartItemSummary
      const cartItem = getCartItemFromFoodItem(item, email, name); 
      cartItem.cartItemId = cart_item_id;
      // dispatch(removeCartItem({mainItemId: item.id, email, cartItemUUID}))
      removeFromCartHandler(cartItem);
    }
  };
    // const {getCookie} = useCookie();
    // const storeId = getCookie('storeId') || "";
    // const favorites = useSelector(selectCustomerFavorites);

    // const isFavorite = (foodItem: FoodMenuItem) => favorites.some(favorite => favorite.dish_item_id == foodItem.id);

    // const handleFavoriteClick = (foodItem: FoodMenuItem, action: 'add' | 'remove') => {
    //     const favoriteItem = {
    //         dish_item_name: foodItem.dishName,
    //         dish_item_id: foodItem.id,
    //         dish_item_price: foodItem.dishPriceText,
    //         sections: [...foodItem.sections],
    //         store_id: storeId
    //     };

    //     if (action == 'remove') {
    //         dispatch(optimisticRemoveFromFavorites(favoriteItem.dish_item_id));
    //         dispatch(removeFavorite(favoriteItem)); // Revert if API call fails
    //     } else {
    //         dispatch(optimisticAddToFavorites(favoriteItem));
    //         dispatch(addFavorite(favoriteItem)); // Revert if API call fails
    //     }
    // };

    const [isFavorite, handleFavoriteClick] = useAddToFavourite();


  // const { searchText }  = useSearchTextContext()

  // const handleIncrementBase = () => {
  //   if (item.customizeOptions && item.customizeOptions.length > 0) {
  //     navigate(MENU_ITEM_CUSTOMIZATION_PATH.replace(':itemId', item.id).replace(":stepId", item.customizeOptions[0].step))
  //   }
  //   if (count == 0) {
  //     setCount(count + 1);
  //   }
  // };

  // const showItemBasedOnSearchText = (foodItem: FoodItem) : boolean => {
  //   if (!searchText || !searchText.length) return true;
  //   const texts = searchText.toLowerCase().split(" ").filter(text => text.length);
  //   for (var i = 0; i < texts.length; i++) {
  //     const text = texts[i];
  //     const allText = foodItem.dishName.toLowerCase().split(" ").concat(foodItem.dishDescription.toLowerCase().split(" ")).filter(word => word.length).filter(word => word.startsWith(text));
  //     if (allText.length == 0) return false;
  //   }
    
  //   return true;
  // }

  return (
    <div>
      {menuPageCardSection.map( menuCardSectionItem  => (
          <Stack padding={3} spacing={2} id={ menuCardSectionItem.titleId } key={ menuCardSectionItem.titleId }>
            <LandingPageCategoryTitle  gutterBottom> { menuCardSectionItem.title } </LandingPageCategoryTitle>
            <Stack sx={{border: 'none'}} divider={<Divider sx={{marginY: 2}}/>}>
                { menuCardSectionItem.foodItems.map( foodItem => ( //showItemBasedOnSearchText(foodItem) &&
                    <Grid container spacing={2} sx={{textAlign: 'left'}} key={menuCardSectionItem.titleId + "-" + foodItem.id}>
                        <Grid item xs={6}>
                            <Stack gap={1}>
                              <VegNonVegIcon foodType= { foodItem.dishType } />
                              <MenuItemTitle variant='h4'>{ foodItem.dishName } </MenuItemTitle>
                              <MenuItemPrice variant='h2'>{ foodItem.dishPriceText } </MenuItemPrice>
                              {/* <Grid item xs={12} display="flex" sx={{justifyContent:"space-between", alignItems: 'center', textAlign: 'center'}}> 
                                <Rating precision={0.5} readOnly value={ Number(foodItem.dishRatingText) } sx={{transform: 'scale(1.0)'}} size="small" /> 
                                <MenuItemRatingNumber> {foodItem.dishRatingText} </MenuItemRatingNumber>
                                <MenuItemRMenuItemRatingBy>({foodItem.dishRatedByText})</MenuItemRMenuItemRatingBy>
                              </Grid> */}
                              <MenuItemBriefDescription variant='h3' 
                                sx={{overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical'}}>
                                    {foodItem.dishDescription}</MenuItemBriefDescription>
                              {foodItem.dishDescription.length > 30 && <Link to={{ pathname: `${ITEM_DETAIL_PATH.replace(":itemId",foodItem.id)}` }} relative="path" style={{textDecoration: "none"}}><Typography variant="h6" style={{ cursor: 'pointer', fontSize: '12px', color: '#7D5260' }}>More</Typography></Link>}

                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ position: 'relative', zIndex: 1 }}>
                              <CardMedia sx= {{ borderRadius: 4, height: '150px', boxShadow: 2}} component="img" image={foodItem.dishImageURL} title={foodItem.dishImageAltText} />
                              {isFavorite(foodItem) ? <FavoriteRoundedIcon
                                sx={{
                                  position: 'absolute',
                                  top: 8, // Adjust the position as needed
                                  right: 8, // Adjust the position as needed
                                  color: '#ff02a7ba', // Adjust color as needed
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: for better visibility
                                  borderRadius: '50%',
                                  padding: '4px',
                                }}
                                onClick={() => handleFavoriteClick(foodItem, 'remove')}
                              />: <FavoriteBorderRoundedIcon
                                sx={{
                                  position: 'absolute',
                                  top: 8, // Adjust the position as needed
                                  right: 8, // Adjust the position as needed
                                  color: 'white', // Adjust color as needed
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: for better visibility
                                  borderRadius: '50%',
                                  padding: '4px',
                                }}
                                onClick={() => handleFavoriteClick(foodItem, 'add')}
                              />}
                            </Box>
                            {/* <Box sx={{justifyContent: 'center', display: 'flex'}}><AddButton backgroundColor="#fff" count={getCount(foodItem.id)} incrementHandler={ () => {handleIncrement(foodItem)} } decrementHandler={() => {handleDecrement(foodItem)}}/></Box> */}
                            <Box sx={{ position: 'relative', marginTop: '-10%', justifyContent: 'center', display: 'flex', zIndex: 2 }}>
                                <AddButton backgroundColor="#fff" count={getCount(foodItem.id)} incrementHandler={ () => {handleIncrement(foodItem)} } decrementHandler={() => {handleDecrement(foodItem)}}/>
                            </Box>
                            {foodItem.customizeOptions && foodItem.customizeOptions.length ? <CustomizeText>Customize +</CustomizeText> : null}
                            
                        </Grid>
                    </Grid>
                ))}
            </Stack> 
          </Stack>
        ))
      }
    </div>
  )
}