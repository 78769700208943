import { useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

// UserRole is one of MANAGER, KITCHEN and WAITER
export enum UserRole {
    MANAGER = 'MANAGER',
    KITCHEN = 'KITCHEN',
    WAITER = 'WAITER',
    ADMIN = 'ADMIN'
}

export interface StoreColleagueFormData {
    user_email: string;
    user_nick_name: string;
    store_id: string;
    role: UserRole;
}

export const useAddColleagueForm = (
    onSubmit: (values: StoreColleagueFormData, formikHelpers: FormikHelpers<StoreColleagueFormData>) => void | Promise<any>
) => {
    const [initialFormValues, setInitialFormValues] = useState<StoreColleagueFormData>({
        user_email: '',
        store_id: '',
        role: 'KITCHEN' as UserRole,
        user_nick_name: ''
    });

    const storeInfoSchema = Yup.object().shape({
        user_email: Yup.string().email("emailId in correct format needed ").required('user_email is required'),
        user_nick_name: Yup.string().required('user_nick_name is required'),
        store_id: Yup.string().required('store_id is required'),
        role: Yup.string().oneOf(Object.values(UserRole).filter(r => r != UserRole.ADMIN)).required('role is needed')
    });

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: storeInfoSchema,
        onSubmit: onSubmit,
        enableReinitialize: true,
        validateOnMount: true,
    });
    return formik;
};
