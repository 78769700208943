import React, { useEffect } from "react";
import { Hub } from "aws-amplify";
import { LoaderPage } from "../../components/rootDomain/LoderPage";
import useCookie from "../../hooks/useCookie";

const Redirect: React.FC = () => {
    const { getCookie } = useCookie();
    const restaurant_prefix = getCookie('restaurant_prefix');

    const addUserVerifySuffix = () => {
        if (restaurant_prefix == 'admin') {
            return "";
        }
        return '?userverify=true';
    }

    useEffect(() => {
        const listener = (eventData: { payload: { event: string } }) => {
            const { event } = eventData.payload;
            if (event === 'customOAuthState') {
                console.log('got auth');
                if (restaurant_prefix) {
                    const redirectUrl = `${process.env.REACT_APP_REDIRECT_HOME_URL}${addUserVerifySuffix()}`?.replace("store", restaurant_prefix) ?? '/';
                    setTimeout(() => {
                        window.location.replace(redirectUrl);
                    }, 100);
                } else {
                    console.error('Store information is missing in cookies.');
                    // Handle the missing store information case
                }
            }
        };

        Hub.listen('auth', listener);
        return () => Hub.remove('auth', listener);
    }, [getCookie]);

    return <LoaderPage text="Redirecting" />;
};

export default Redirect;
