import { useMutation } from 'react-query';
import axios from 'axios';
import { useBaseMutation } from '../../../../hooks/useBaseMutation';

export const useDeleteRestaurantInfo = () => {
    return useBaseMutation(
        'delete',
        'https://api.menufy.link/restaurantBackend/restaurants/info', // Endpoint
        // 'retaurantDelete',
        undefined,
        '/restaurants'
    );
};
