import { useMutation } from 'react-query';
import axios from 'axios';
import { useBaseMutation } from '../../../../hooks/useBaseMutation';

export const useDeleteStoreInfo = () => {
    return useBaseMutation(
        'delete',
        'https://api.menufy.link/restaurantBackend/stores/info', // Endpoint
        // 'storeInfo',
        undefined,
        '/stores'
    );
};
