import { createAsyncThunk } from "@reduxjs/toolkit"
import { CartItem, removeCartItem } from "./cartItemsSlice";
import axiosInstance from "../../components/common/axiosInstance";

export interface AddToCartPayload {
    cart_session_id: string;
    store_id: string;
    email_id: string;
    table_id: string;
    cart_item_id: string;
    cartItem: CartItem;
    timeStamp?: number;
}

export interface RemoveFromCartPayload {
    cart_session_id: string;
    email_id: string;
    dish_name: string;
    cart_item_id: string;
    cart_item: CartItem;
    store_id: string;
    table_id: string;
}

export const persistCartItem = createAsyncThunk(
    'cart/addToCart',
    async (payload: AddToCartPayload, { rejectWithValue, getState, dispatch }) => {
        try {
            console.log('calling backend...')
            const response = await axiosInstance.put('https://api.menufy.link/dineIn/cartItems/session', payload);
            return response.data; 
        } catch (error: any) {
            console.log(error);
            // dispatch(removeCartItem({mainItemId: payload.cartItem.menu_item_id, cartItemUUID: payload.cart_item_id, email: payload.email_id}))
            return rejectWithValue(error.response.data);
        }
    },
)

export const removeCartItemThunk = createAsyncThunk(
    'cart/removeFromCart',
    async (payload: RemoveFromCartPayload, { dispatch, rejectWithValue }) => {
        try {
            // Replace the URL and method as per your API's requirement to remove an item
            const backendPayload = {
                store_id: payload.store_id,
                cart_session_id: payload.cart_session_id,
                email_id: payload.email_id,
                main_item_id: payload.cart_item.menu_item_id,
                dish_name: payload.dish_name,
                cart_item_id: payload.cart_item_id,
                table_id: payload.table_id

            }
            const response = await axiosInstance.delete(`https://api.menufy.link/dineIn/cartItems/session`, {data: backendPayload}); // Some APIs require payload in data property for DELETE requests
            // });
            return response.data;
        } catch (error: any) {
            console.log(error);

            // Assuming error handling returns enough info to re-create the cartItem
            // const cartItem: CartItem = {
            //     cartItemId: payload.cart_item_id,
            //     // Populate other necessary fields for CartItem
            //     addedByEmail: payload.email_id,
            //     // You might need additional information that isn't available here,
            //     // consider how you can retrieve or store it for this purpose
            // };

            // Dispatch to add the item back to the cart
            // dispatch(addCartItem({ cartItem }));

            return rejectWithValue(error.response?.data || 'Unknown error');
        }
    },
);


export const loadCartItems = createAsyncThunk(
    'cart/loadCartItems',
    async ({ cart_session_id, table_id, store_id }: { cart_session_id: string; table_id: string; store_id: string; }, { rejectWithValue }) => {
      try {
        console.log('fetching data..from db.. for cart items..')
        const response = await axiosInstance.get(`https://api.menufy.link/dineIn/cartItems/session?cart_session_id=${cart_session_id}&table_id=${table_id}&store_id=${store_id}`);
        return response.data as CartItem[]; // Assuming the API returns the list of cart items directly
        console.log(response.data);
      } catch (error: any) {
        return rejectWithValue(error.response?.data || 'Unknown error');
      }
    }
);
  