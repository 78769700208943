import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../components/common/axiosInstance";
import { CartOrderItem } from "./CartOrderSlice";

export const loadCartItemOrders = createAsyncThunk(
    'cart/loadCartItemOrders',
    async ({ cartSessionId, tableId, storeId }: { cartSessionId: string; tableId: string; storeId: string; }, { rejectWithValue }) => {
      try {
        console.log('fetching data..from db.. for cart orders..')
        const response = await axiosInstance.get(`https://api.menufy.link/dineIn/cartItems/order?cart_session_id=${cartSessionId}&store_id=${storeId}&table_id=${tableId}`);
        return response.data as CartOrderItem[]; // Assuming the API returns the list of cart items directly
        console.log(response.data);
      } catch (error: any) {
        return rejectWithValue(error.response?.data || 'Unknown error');
      }
    }
);