import { Avatar, Stack, Typography, styled } from "@mui/material"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


const GrayTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#49454F',
    textAlign: 'left',
  }));

const BlackTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left',
}));


export const DineInCheckoutpageNavBar = () => {
    return (
        <Stack direction='row' padding={3} spacing={2}>
            <Avatar sx={{ backgroundColor: '#1C1C1E'}}><ShoppingCartIcon/></Avatar>
            <Stack direction='column'>
                <GrayTitleText>What's in Your</GrayTitleText>
                <BlackTitleText>Cart</BlackTitleText>
            </Stack>
        </Stack>
    )
}