import { Box, Chip, Container, Divider, Menu, MenuItem, Select, Stack, SvgIcon, Typography } from "@mui/material"
import { useState } from "react";
import { VegNonVegIcon } from "./CustomIcons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import React from "react";
import { LegendToggleRounded } from "@mui/icons-material";
import { DishCategory } from "../../../../interfaces/FoodItem";

export interface VegNonVegFilterProps {
    setFoodCategoryFilter: (option: DishCategory[]) => void;
    foodCategoryFilter: DishCategory[];
    defaultFilter: DishCategory[];
}


export const VegNonVegFilter: React.FC<VegNonVegFilterProps> = ({  foodCategoryFilter, setFoodCategoryFilter, defaultFilter }) => {
    const [value, setValue] = useState<DishCategory[]>([...defaultFilter]);
    const handleClick = (val : DishCategory ) => {
        let valueCopy = [...value]
        const isThere = valueCopy.filter(v => v == val);
        if (isThere.length == 0) valueCopy.push(val);
        else valueCopy = valueCopy.filter(v => v != val);
        setValue(valueCopy);
        setFoodCategoryFilter(valueCopy);
    }
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, maxWidth: '200px' }}>
            {defaultFilter.map(v => <SelectedVegNonVegChip key={v} onClick={()=>{handleClick(v)}} vegOrNonVeg={v}/>) }
            {/* <SelectedVegNonVegChip onClick={()=>{handleClick("non-veg")}} vegOrNonVeg="non-veg"/> */}
        </Box>
    )
}
export type SelectedVegNonVegChipProps = {
    vegOrNonVeg: DishCategory
    onClick: () => void;
} 
export const SelectedVegNonVegChip: React.FC<SelectedVegNonVegChipProps> = ({vegOrNonVeg, onClick}) => {
    const [isSelected, setSelected] = useState(true);
    const handleClick = () => {
        const selectedVal = isSelected
        setSelected(!selectedVal);
        onClick()
    }
    return (

        <Chip label={<VegNonVegIcon foodType={vegOrNonVeg}/>} sx={{paddingTop: '5px', display: 'flex', alignItems: 'normal', backgroundColor: isSelected ? '#E4E4E4' : 'inherit', border: isSelected ? '1px solid #6750A4' : '1px solid #79747E'}} deleteIcon={isSelected ? <DoneTwoToneIcon/> : <CloseTwoToneIcon/>} onClick={handleClick} onDelete={handleClick}/> 
    )
}