import { useQuery } from 'react-query'
import axiosInstance from '../../../../common/axiosInstance';

const getRestaurantData = async () => {
    const response = await axiosInstance.get('https://api.menufy.link/restaurantBackend/users/restaurants'); // Use the custom axios instance
    return response.data;
};

export const useFetchUserRestaurants = () => {
    return useQuery('UserRestaurants', getRestaurantData, {
        refetchOnMount: 'always',
    });
};
