import { Box, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { CategoryListItem } from "./CategoryListItem";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { FieldArray, FieldArrayRenderProps, Formik, useFormikContext } from "formik";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { CategoryFormValue, FoodMenuItemInput } from "../../../hooks/useMenuForm";
import { useSnackbarContext } from "../../../../../contexts/SnackbarContext";


export const CategorySideList = () => {
    const [selectedItem, setSelectedItem] = useState<string>("");
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    // const [initialValues, validationSchema] = useCategoryForm();
    const [edit, setEdit] = useState<boolean>(false);
    const {values} = useFormikContext<{categories: CategoryFormValue[], foodMenuItemsInputs: FoodMenuItemInput[]}>();
    const { setSnackbarMessage } = useSnackbarContext();


    useEffect(() => {
        console.log("CategorySideList created");
    }, []);

    const notUsed = (categoryName: string) => {
        let edit = true;
        values.foodMenuItemsInputs.forEach(item => {
            if (item.sections.findIndex(section => section == categoryName) >= 0) {
                edit = false;
            }
        })
        return edit;
    }

    const handleItemClick = (id: string, index: number) => {
        setSelectedIndex(index);
        // console.log(formik.values)
        setSelectedItem(id);
    };

    const reset = () => {
        setSelectedIndex(-1);
    }

    const handleAdd = async (arrayHelpers: FieldArrayRenderProps) => {
        arrayHelpers.push({ name: 'Add', id: uuidv4() });
    };

    const handleRemove = async (arrayHelpers: FieldArrayRenderProps) => {
        // if (selected)

        if (selectedIndex < 0) return;
        if (!notUsed(values.categories[selectedIndex].name)) {
            setSnackbarMessage({ key: uuidv4(), message: "Remove this tag from associated food Items first!", type: 'error' });
            return;
        }

        await arrayHelpers.remove(selectedIndex);
        setSelectedIndex(-1);
        setSnackbarMessage({ key: uuidv4(), message: "Deleted cateogry", type: 'success' });
    };

    const handleEdit = () => {
    };
    return (
                    <Stack spacing={2}>
                        <h4>Categories</h4>
                        <FieldArray
                            name="categories"
                            render={arrayHelpers => (
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, marginBottom: 2 }}>
                                        <IconButton
                                            aria-label="add"
                                            onClick={() => handleAdd(arrayHelpers)}
                                            sx={{ border: '1px solid black' }}
                                        >
                                            <AddRoundedIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="add"
                                            onClick={() => handleRemove(arrayHelpers)}
                                            sx={{ border: '1px solid black' }}
                                        >
                                            <RemoveRoundedIcon />
                                        </IconButton>
                                    </Box>
                                    <Stack spacing={0}>
                                        {values.categories && values.categories.map((item: any, index: any) => (
                                            <CategoryListItem
                                                key={item.id}
                                                index={index}
                                                isEditMode={item.id == selectedItem && notUsed(item.name)}
                                                onClick={() => handleItemClick(item.id, index)}
                                                categoryFormValue={item} />
                                        ))}
                                    </Stack>
                                </>
                            )} />
                    </Stack>
    );
};
