import React, { useContext, useState, useEffect, createContext } from "react";
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useStoreStripeAccountLink } from "../components/restaurantAdmin/pages/payments/hooks/useFetchStoreStripeAccountLink";
import useCookie from "../hooks/useCookie";
import { useFetchStoreStripeAccountId } from "../components/dinein/pages/checkout/hooks/useFetchStoreStripeAccountId";

// Define a type for the context value to ensure type safety
type StripeContextValue = {
  	stripePromise: Promise<Stripe | null> |  null;
};

export type StripeContextProviderProps = {
 	children: React.ReactNode;
};

// Create the context with a default value of undefined to ensure consumers handle the loading state
export const StripeContext = createContext<StripeContextValue | undefined>(undefined);

// Custom hook to use the Stripe context
export const useStripeContext = () => {
	const context = useContext(StripeContext);
	if (context === undefined) {
		throw new Error('useStripeContext must be used within a StripeContextProvider');
	}
	return context;
};

// Provider component that loads Stripe and provides it to the rest of the app
export const StripeContextProvider: React.FC<StripeContextProviderProps> = ({ children }) => {
	const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> |  null>(null);
	const { getCookie } = useCookie();
	const storeId = getCookie('storeId');
	const { data: stripeAccountInfo } = useFetchStoreStripeAccountId(storeId);
	useEffect(() => {
		if (stripeAccountInfo && stripeAccountInfo.accountId) {
		// Load Stripe and set it in state
			const stripePromiseObj = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!, {
				stripeAccount: stripeAccountInfo.accountId
			});
			setStripePromise(stripePromiseObj)
		}
	}, [stripeAccountInfo]);

	return (
		<StripeContext.Provider value={{ stripePromise }}>
		{children}
		</StripeContext.Provider>
	);
};
