import { Box, Button, FormHelperText, Grid, InputAdornment, InputLabel, Modal, Stack, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, FieldArray, useField, useFormikContext } from "formik";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AddOnItem } from '../../../../../../interfaces/FoodItem';
import { useFetchStoreInfo } from "../../../stores/hooks/useFetchStoreInfo";

export const CustomizationDetailsModal: React.FC = () => {
    //"menu/stores/:id/editItem/:foodMenuItemId/foodItemIndex/:foodItemIndex/customization/stepIndex/:stepIndex/position/:positionIndex
    const {id: storeId, foodMenuItemId, foodItemIndex, stepIndex, positionIndex} = useParams();
    const fieldPath  = `foodMenuItemsInputs.${foodItemIndex}.customizeOptions.${stepIndex}.stepOptions.${positionIndex}`;
    const [currency, setCurrency] = useState<string | undefined>(undefined);
    const { setFieldValue } = useFormikContext();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const numericPrice = event.target.value;
        const formattedPrice = `${currency} ${numericPrice}`;
        
        await setFieldValue(`${fieldPath}.items[${index}].priceNumber`, numericPrice);
        await setFieldValue(`${fieldPath}.items[${index}].price`, formattedPrice);
    };
    
    const open = Boolean(positionIndex);
    const navigate = useNavigate();

    const handleClose = () => {
        // Construct the path to navigate back to the item edit page without the customization ID
        navigate(`/menu/stores/${storeId}/editItem/${foodMenuItemId}`);
    };

    const [itemsField, , ] = useField(`${fieldPath}.items`)
    const createNewItem = () => ({
        id: uuidv4(), // Though you said it's generated elsewhere, placeholder here
        name: '',
        price: '',
        dishType: 'veg', // Default value
        priceNumber: 0
    });

    const {data, isLoading} = useFetchStoreInfo(storeId);
    
    useEffect( () => {
        if (data && data.length) {
            const currency_to_display = data[0].store_currency;
            setCurrency(currency_to_display);
        }
    }, [data]);

    if (!currency) return <>loading currency...</>

    return (
        <Modal
            open={open}
            // onClose={onClose}
            onClose={() => navigate(`/menu/stores/${storeId}/editItem/${foodMenuItemId}`)}
            aria-labelledby="customization-modal-title"
            aria-describedby="customization-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, maxHeight: '80vh', overflowY: 'scroll' }}>
                <Stack spacing={3}>
                    <Typography id="customization-modal-title" variant="h6">
                        Edit Customization Step
                    </Typography>
                    <form>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                        <h4>Details</h4>
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <InputLabel id="Title">Title</InputLabel>
                                <Field as={TextField} name={`${fieldPath}.title`} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel id="minSelect">Min Select</InputLabel>
                                <Field as={TextField} name={`${fieldPath}.minSelect`} type="number" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel id="maxSelect">Max Select</InputLabel>
                                <Field as={TextField} name={`${fieldPath}.maxSelect`} type="number" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel id="TruncateAt">Truncate At</InputLabel>
                                <Field as={TextField} name={`${fieldPath}.truncateAt`} type="number" fullWidth />
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" sx={{ mt: 2 }}>
                        <h4>Items</h4>
                        </Typography>
                        <FieldArray name={`${fieldPath}.items`}>
                            {({ push, remove }) => (
                                <>
                        
                                    {itemsField.value.map((item: AddOnItem, index: number) => (
                                        <>
                                            <Grid container spacing={2} key={item.id} sx={{ mt: 1 }}>
                                                <Grid item xs={4}>
                                                    <Field as={TextField} name={`${fieldPath}.items[${index}].name`} label="Name" fullWidth />
                                                    <ErrorMessage name={`${fieldPath}.items[${index}].name`}>
                                                        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                    </ErrorMessage>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field as={TextField} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)} name={`${fieldPath}.items[${index}].priceNumber`} label="Price" fullWidth 
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">{currency}</InputAdornment>
                                                            ),
                                                        }} />
                                                    <ErrorMessage name={`${fieldPath}.items[${index}].priceNumber`}>
                                                        {(msg) => <FormHelperText error>{msg}</FormHelperText>}
                                                    </ErrorMessage>
                                                </Grid>
                                                <Grid item xs={3}>
                                                <Field as={TextField} name={`${fieldPath}.items[${index}].dishType`} label="Dish Type" select fullWidth SelectProps={{ native: true }}>
                                                    <option value="veg">Veg</option>
                                                    <option value="non-veg">Non-Veg</option>
                                                </Field>
                                                </Grid>
                                                <Grid item xs={2}>
                                                <Button onClick={() => remove(index)} color="error">Remove</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ))}
                                    <Button onClick={() => push(createNewItem())} sx={{ mt: 2 }}>
                                    Add Item
                                    </Button>
                                </>
                            )}
                        </FieldArray>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button onClick={handleClose} color="primary">
                            Close
                            </Button>
                        </Box>
                    </form>
                </Stack>                
            </Box>
        </Modal>
    );
};
