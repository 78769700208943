import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { AppBar, Avatar, Grid, IconButton, InputAdornment, Stack, TextField, Typography, styled, useScrollTrigger } from "@mui/material";
import { useEffect, useState } from "react";
import { DishCategory } from "../../../../interfaces/FoodItem";
import { ShareCartOptions } from "../common/ShareCartOptions";
import ScrollspyDropdown, { Section } from "../common/ScrollSpy";
import { VegNonVegFilter } from "../common/VegNonVegFilter";
import { TransitionGroup } from 'react-transition-group';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';


const GrayTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#49454F',
    textAlign: 'left',
  }));

const BlackTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left',
}));

const ScrollspyContainer = styled('div')({
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: 1000,
  });
  
  interface DineInMenuPageNavBarProps {
    sections: Section[];
    setSearchText: (text: string) => void;
    searchText: string;
    foodCategoryFilter: DishCategory[];
    setFoodCategoryFilter: (val : DishCategory[]) => void
    defaultFilter: DishCategory[]
  }

export const DineInMenuPageNavBar: React.FC<DineInMenuPageNavBarProps> = ({sections, searchText, setSearchText, foodCategoryFilter, setFoodCategoryFilter, defaultFilter}) => {
  const [appBarHeight, setAppBarHeight] = useState(0);
  useEffect(() => {
    // Calculate the initial height of the AppBar
    const appBarElement = document.getElementById('app-bar');
    if (appBarElement) {
      const height = appBarElement.clientHeight;
      setAppBarHeight(height);
    }

    // Listen for changes to the AppBar's size
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.target === appBarElement) {
          const height = entry.target.clientHeight;
          setAppBarHeight(height);
        }
      }
    });

    resizeObserver.observe(appBarElement!);

    // Clean up the observer when the component unmounts
    return () => {
      resizeObserver.unobserve(appBarElement!);
    };
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const [showSecondSearchBar, setShowSecondSearchBar] = useState(false);

    const handleClick = () => {
        const val = showSecondSearchBar;
        setShowSecondSearchBar(!val);
    }


    // const { filterText: searchText , setFilterText: setSearchText, sections }  = useMenuPageFoodItemsWithSearchAndFilters();
    // console.log("heyyyyy trying to get session")

    return (
        <AppBar position="sticky" sx={{backgroundColor:'#fff', border: '0px', boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.08)'}} id="app-bar">
            <TransitionGroup>
            <Stack direction='row' padding={3} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
              {!trigger ? 
                <Stack direction='row' spacing={2}>
                    <Avatar sx={{ backgroundColor: '#1C1C1E'}}><RestaurantMenuIcon/></Avatar>
                    <Stack direction='column'>
                        <GrayTitleText>Today's Delicious</GrayTitleText>
                        <BlackTitleText>Menu</BlackTitleText>
                    </Stack>
                </Stack>
               :  (
                <Grid container sx={{ position:'relative', paddingBottom: '10px', minHeight: '10px'}}>
                  <Grid item xs={11} spacing={1}>
                      <Stack spacing= {1} direction="row" alignItems='center'>
                        <VegNonVegFilter foodCategoryFilter={foodCategoryFilter} setFoodCategoryFilter={setFoodCategoryFilter} defaultFilter={defaultFilter} />
                        {sections.length && <ScrollspyContainer><ScrollspyDropdown sections={sections} offset={appBarHeight}/></ScrollspyContainer>}
                      </Stack>
                  </Grid>
                  <Grid item container xs={1} alignItems='center' justifyContent="center">
                    <SearchRoundedIcon sx={{color: 'black'}} onClick={handleClick}/>
                  </Grid>
                </Grid>
                )}
              </Stack>

            </TransitionGroup>
            <TransitionGroup>
              <Stack direction='column' padding={3} spacing={3} sx={{paddingY: '0px', display: trigger? 'none' : 'flex'}}>
                  <ShareCartOptions/>
                  <MenuSearchFilterComponent searchText={searchText} setSearchText={setSearchText}/>
                  <Grid container sx={{ position:'relative', paddingBottom: '10px', minHeight: '50px'}}>
                    <Grid item xs={6}>
                        <VegNonVegFilter foodCategoryFilter={foodCategoryFilter} setFoodCategoryFilter={setFoodCategoryFilter} defaultFilter={defaultFilter} />
                    </Grid>
                    <Grid item xs={6}>
                      {sections.length && <ScrollspyContainer><ScrollspyDropdown sections={sections} offset={appBarHeight}/></ScrollspyContainer>}
                    </Grid>
                  </Grid>
                  
              </Stack>
            </TransitionGroup>
            <TransitionGroup>
              <Stack direction='column' padding={3} spacing={3} marginBottom={1} sx={{paddingY: '0px', display: trigger && showSecondSearchBar? 'flex': 'none'}}>
                <MenuSearchFilterComponent searchText={searchText} setSearchText={setSearchText}/>
              </Stack>
            </TransitionGroup>
        </AppBar>
    );
}

export interface MenuSearchFilterComponentProps {
  setSearchText: (text: string) => void;
  searchText: string;
}

export const MenuSearchFilterComponent: React.FC<MenuSearchFilterComponentProps> = ({searchText, setSearchText}) => {
  return (
    <TextField
                    id="filled-search"
                    label="Search"
                    type="text"
                    value={searchText}
                    sx={{
                        '& label.Mui-focused' : {
                            color:"#6750A4",
                            borderColor: '#6750A4'
                            },
                            '& label.MuiFormLabel-root' : {
                                color: '#6750A4'
                            }
                            ,'& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#6750A4',
                                },
                                '&:hover fieldset': {
                                borderColor: '#6750A4',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#6750A4',
                                },
                            },
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (<InputAdornment position="start"> <ManageSearchIcon  /> </InputAdornment>),
                        endAdornment: searchText && (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setSearchText("")}
                            ><CancelOutlinedIcon sx={{color:'#49454F'}}/></IconButton>
                            )
                        }}
                />
  )
}