import { string } from "yup";
import { useMenuItemsQuery } from "./useMenuItemsQuery";
import { ThumbnailCardProps } from "../components/dinein/pages/landing/LandingPageBody";
import { FoodMenuItem } from "../interfaces/FoodItem";

export const useLandingPageSections = () => {
    const { data: foodItems, isLoading} = useMenuItemsQuery() || [];
    const tagToThumbnailsMap = new Map<string, ThumbnailCardProps[]>();
    for(let i = 0; foodItems && i < foodItems.length; i++) {
        if (foodItems[i].homePageSection) {
            for (let j = 0; j < foodItems[i].homePageSection!.length; j++) {
                const tag =  foodItems[i].homePageSection![j];
                if (!tagToThumbnailsMap.has(tag)) tagToThumbnailsMap.set(tag, []);
                tagToThumbnailsMap.get(tag)!.push(getThumbNailFromFoodItem(foodItems[i]));
            }
        }
    }
    return {tagToThumbnailsMap, isLoading};
}

const getThumbNailFromFoodItem = (foodItem: FoodMenuItem) : ThumbnailCardProps => {
    const foodItemImageUrl = foodItem.dishImageURL; 
    const foodItemTitle = foodItem.dishName; 
    const price = foodItem.dishPriceText;
    const rating = foodItem.dishRatingText; 
    const foodItemId = foodItem.id;
    const vegNonVeg = foodItem.dishType
    return {foodItemImageUrl, foodItemTitle, price, rating, foodItemId, vegNonVeg}
}