import { useQuery } from "react-query";
import axiosInstance from "../../../../common/axiosInstance";

// Modify the hook to accept a restaurantId and adjust the query key accordingly
export const useFetchRestaurantInfo = (restaurantId: string | null | undefined ) => {
    return useQuery(['restaurantInfo', restaurantId], () => getRestaurantData(restaurantId), {
        enabled: !!restaurantId // Fetch data only if restaurantId is present
    });
};

const getRestaurantData = async (restaurantId: string | null | undefined) => {
    if (!restaurantId) {
        throw new Error("Restaurant ID is required");
    }
    const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/restaurants/info?restaurantId=${restaurantId}`);
    return response.data;
};
