import { useAuthenticator } from "@aws-amplify/ui-react";
import { Grid, Stack, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAddToCart, } from "../../../../hooks/useAddToCart";
import useCookie from "../../../../hooks/useCookie";
import { CartItemDetails, CartItemSummary, CartItemSummaryWithNameEmailTimeStamp } from "../../../../redux/cartItems/cartItemsSelectors";
import { CartItem } from "../../../../redux/cartItems/cartItemsSlice";
import { AppDispatch } from "../../../../redux/stores/mainStore";
import { getCloneCartItem, getNewOrExistingCartItemfromCartItemSummary } from "../../../../utils/CartItemsUtils";
import { VegNonVegIcon } from "../common/CustomIcons";
import AddButton from "../common/DineInItemAddToCartButton";
import { CheckoutCustomizationSection } from "./CheckoutCustomizationSection";
import { useCartSessionOrder } from "../../../../hooks/useCartSessionOrder";
import { useCartSession } from "../../../../hooks/useCartSession";
import { LoaderPage } from "../../../rootDomain/LoderPage";

export interface ItemSummarySectionProps {
    email?: string;
    cartItemDetails?: CartItemDetails;
    cartItemSummaryWithNameEmailTimeStamp?: CartItemSummaryWithNameEmailTimeStamp[];
    dontShow? : boolean
}

export const CartItemDishNameText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '14px',
	fontWeight: 700,
	textAlign: 'left',
	color: '#1C1C1E',
}));

export const AddedByText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '12px',
	fontWeight: 400,
	textAlign: 'left',
	color: '#79747E',
}));

export const PriceText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '14px',
	fontWeight: 700,
	textAlign: 'right',
	color: '#218D0F',
    paddingRight: '2px'
}));

export const ItemSummarySection: React.FC<ItemSummarySectionProps> = ({email, cartItemDetails, cartItemSummaryWithNameEmailTimeStamp=[], dontShow=false}) => {
    const getFirstName = (user: string): string => {
        return user.split(" ")[0];
    }
    const { user } = useAuthenticator((context) => [context.route]);

    const myEmail = user.attributes?.email || '';
    const myName = user.attributes? user.attributes['given_name'] : "John Doe";

    const isSameUser = (userEmail: string) : Boolean => userEmail == myEmail
    const getAddedByUser = (userEmail: string, userName: string): string => isSameUser(userEmail) ? 'You' : getFirstName(userName)
    const {addToCartHandler, isCartSessionLoading, removeFromCartHandler} = useAddToCart(myEmail, myName)

    const incrementItem = (cartItem: CartItem) => {
        addToCartHandler(cartItem.menu_item_id, undefined, undefined, getCloneCartItem(cartItem));
    }
    const decrementItem = (cartItem: CartItem) => {
        removeFromCartHandler(cartItem);
    }


    return (
        <Stack spacing={2}>
            {/* {
                cartItemDetails.cartItemSummary.map((cartItemSummary) => (
                    <Stack direction='row' style={{'display': 'flex', 'alignItems': 'flex-start', 'justifyContent': 'space-between'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                    <Stack direction='row' spacing={1}>
                                        <VegNonVegIcon foodType={cartItemSummary.dishType}></VegNonVegIcon>
                                        <Stack direction='column'>
                                            <CartItemDishNameText>{cartItemSummary.foodItemName}</CartItemDishNameText>
                                            { (!dontShow) && <AddedByText>{`Added by ${getAddedByUser(cartItemDetails.email, cartItemDetails.name)}`}</AddedByText> }
                                            {
                                                cartItemSummary.customizations && cartItemSummary.customizations.length > 0 &&
                                                    <CheckoutCustomizationSection cartItemSummary={cartItemSummary} />
                                            }
                                        </Stack>
                                    </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction='column' spacing={1}>
                                    <AddButton backgroundColor='inherit' color={'#7D5260'} count={cartItemSummary.quantity} incrementHandler={() => {incrementItem(getNewOrExistingCartItemfromCartItemSummary(cartItemSummary, myName, myEmail))}} decrementHandler={() => {decrementItem(getNewOrExistingCartItemfromCartItemSummary(cartItemSummary, cartItemDetails.name, cartItemDetails.email, cartItemSummary.sampleCartItemIUUID))}}/>
                                    <PriceText>{cartItemSummary.totalAmountString}</PriceText>   
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                ))
            } */}
            {
                cartItemSummaryWithNameEmailTimeStamp.map((cartItemSummary: CartItemSummaryWithNameEmailTimeStamp) => (
                    <Stack direction='row' style={{'display': 'flex', 'alignItems': 'flex-start', 'justifyContent': 'space-between'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                    <Stack direction='row' spacing={1}>
                                        <VegNonVegIcon foodType={cartItemSummary.dishType}></VegNonVegIcon>
                                        <Stack direction='column'>
                                            <CartItemDishNameText>{cartItemSummary.foodItemName}</CartItemDishNameText>
                                            { (!dontShow) && <AddedByText>{`Added by ${getAddedByUser(cartItemSummary.email, cartItemSummary.name)}`}</AddedByText> }
                                            {
                                                cartItemSummary.customizations && cartItemSummary.customizations.length > 0 &&
                                                    <CheckoutCustomizationSection cartItemSummary={cartItemSummary} />
                                            }
                                        </Stack>
                                    </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction='column' spacing={1}>
                                    <AddButton backgroundColor='inherit' color={'#7D5260'} count={cartItemSummary.quantity} incrementHandler={() => {incrementItem(getNewOrExistingCartItemfromCartItemSummary(cartItemSummary, myName, myEmail))}} decrementHandler={() => {decrementItem(getNewOrExistingCartItemfromCartItemSummary(cartItemSummary, cartItemSummary.name, cartItemSummary.email, cartItemSummary.sampleCartItemIUUID))}}/>
                                    <PriceText>{cartItemSummary.totalAmountString}</PriceText>   
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                ))
            }
        </Stack>
    )
}