import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Stack, TextField, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { CartSessionInfo, useCartSession } from "../../../../hooks/useCartSession";
import { useJoinCart } from "../../../../hooks/useJoinCart";
import useCookie from "../../../../hooks/useCookie";
import { MuiOtpInput } from 'mui-one-time-password-input'

const JoinShareCartTitle = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left'
}));

const InviteAction = styled(Button)(({ theme }) => ({
    padding: '0px',
    width: 'fit-content', // Reduce the minimum width to fit smaller screens
    // [theme.breakpoints.down('sm')]: {
    fontSize: '12px', // Adjust font size for consistency on small devices
    // },]
    minWidth: '15px',
    textAlign: 'left',
    fontWeight: 700,
}));

export const ShareCartOptions = () => {

    const {data: cartSessionInfo, isLoading, error} = useCartSession();
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    // refetch();
    
    if (isLoading) return <Skeleton variant="rectangular" sx={{width: '80%'}} height={60} />
    
    return (
        <Stack direction="column" spacing={1} alignItems="left" sx={{flexWrap: 'wrap'}}>
            <JoinShareCartTitle>Share this cart? code: {cartSessionInfo?.join_code}, Or join another cart: <InviteAction onClick={handleOpenModal}>Click</InviteAction></JoinShareCartTitle>
            <JoinCartModal open={modalOpen} onClose={handleCloseModal} currentSessionInfo={cartSessionInfo!}/>
        </Stack>
        
    )
}


interface JoinCartModalProps {
    open: boolean;
    onClose: () => void;
    currentSessionInfo: CartSessionInfo
}


const JoinCartModal: React.FC<JoinCartModalProps> = ({ open, onClose, currentSessionInfo }) => {
  const [joinCode, setJoinCode] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');
  const {mutate: joinCart, isSubmitting, status, data } = useJoinCart();
  const {getCookie} = useCookie();
  const tableNumber = getCookie('tableNumber');

  // useEffect(() => {
  //   if(data){
  //     data.
  //   }
  // }, [data]);

  const handleSubmit = async () => {
    try {
      // Assuming joinCart is adapted to await for a promise
//   const tableNumber = getCookie('tableNumber');
        const payload = { current_session_id: currentSessionInfo.cart_session_id, table_id: currentSessionInfo.table_id, table_number: tableNumber, store_id: currentSessionInfo.store_id, join_code: joinCode};
        console.log(payload);
        joinCart({ variables: payload, key: ['cartSession'], invalidate: true });
         // Close modal after successful submission
    } catch (error: any) {
        setSubmitStatus('Failure: No such code!');
    } finally {
    }
  };

  useEffect(() => {
    if (status === 'success') {
      setSubmitStatus('Sucessfully joined cart!')
      const timer = setTimeout(onClose, 1000);
      return () => clearTimeout(timer);

    } else if (status == 'error'){
      setSubmitStatus('Invalid Code');
      const timer = setTimeout(() => { setJoinCode(''); setSubmitStatus('') }, 1000);
      return () => clearTimeout(timer);
    }
    
    // return () => clearTimeout(1000);
  }, [status]);

  const handleChange = (e: string) => {
    const newValue = e
    console.log(newValue)
  }

  const matchIsNumeric = (text: any, index: number): boolean => {
    const isNumber: boolean = typeof text === 'number';
    const isString: boolean = typeof text === 'string';
    return (isNumber || (isString && text.trim() !== '')) && !isNaN(Number(text));
  }

  const handleNext = (val: string) => {
    setJoinCode(val)
  }

  const textFieldsProps = {
    inputProps: { inputMode: 'numeric' as "numeric", pattern: "[0-9]*" }
  };


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Join Another Cart</DialogTitle>
      <DialogContent>
        <MuiOtpInput value={joinCode} autoFocus TextFieldsProps={textFieldsProps} onComplete={handleChange} length={4} onChange={handleNext} validateChar={matchIsNumeric}/>
        {submitStatus && <p>{submitStatus}</p>}
      </DialogContent>
      <DialogActions>
        <Button variant ='contained' onClick={onClose} disabled={isSubmitting}>Cancel</Button>
        <Button variant ='contained' onClick={handleSubmit} disabled={isSubmitting || joinCode.length != 4}>Join Cart</Button>
      </DialogActions>
    </Dialog>
  );
};
