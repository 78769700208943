import React, { useRef, useEffect } from 'react';
import { useFormikContext } from 'formik';

interface AutoTouchFieldProps {
    children: React.ReactNode;
    name: string;
  }
const AutoTouchField: React.FC<AutoTouchFieldProps> = ({ name, children }) => {
    const formik = useFormikContext();
    const fieldRef = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              formik.setFieldTouched(name, true);
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 } // Adjust the threshold as needed
      );
  
      if (fieldRef.current) {
        observer.observe(fieldRef.current);
      }
  
      return () => {
        if (fieldRef.current) {
          observer.unobserve(fieldRef.current);
        }
      };
    }, [name, formik]);
  
    return <div ref={fieldRef}>{children}</div>;
  };

  export default AutoTouchField;