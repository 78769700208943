import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useFoodItemsSectionsByOfferId } from "../../../../hooks/useFoodItemSections";
import { ItemCardSection } from "../common/MenuFoodItemCard";
import { useEffect } from "react";
import { Box, Chip, Paper, Stack, Typography, styled } from "@mui/material";
import { useOffersQuery } from "../../../../hooks/useOffersQuery";
import { DineInStickyFooter } from "../common/DineInStickyFooter";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export const DineInOfferItemDetailPage = () => {
    let { id:offerId } = useParams();   
    const {isLoading, foodItemsBySection}= useFoodItemsSectionsByOfferId(offerId || '');

    return (
        <>
        {isLoading ? "hey":
            <>
                <OfferBanner id={offerId || ""}/>
                <ItemCardSection menuPageCardSection={foodItemsBySection} />
                <DineInStickyFooter page='home'/>
            </>
        }
        </>
    )
}

const OfferTitle = styled(Typography)(({ theme }) => ({
    fontSize: '28px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    color: '#FFFFFF',
    whiteSpace: 'pre-line',
    textAlign: 'left'
}));

const OfferDescription = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#FFFFFF',
    whiteSpace: 'pre-line',
    textAlign: 'left'
}));


const OfferBanner:React.FC<{id: string}> = ({ id }) => {
    const {data: offers} = useOffersQuery();
    const offer = offers?.filter(offer => id == offer.offerId)[0] || undefined;
    const navigate = useNavigate();
    if (!offer) return (<>{offers}</>);
    return(
        <Paper
            sx={{
                width: 'auto',
                display:'flex',
                flexDirection: 'column',
                flexGrow: '1',
                minHeight: '25vh',
                p: 3,
                borderRadius: '0',
                paddingTop: 2,
                bgcolor: 'white',
                boxShadow: 1,
                justifyContent: 'space-around',
                // justifyContent: 'center',
                alignItems: 'left', // Align content in the center horizontally
                textAlign: 'left',
                background: 'linear-gradient(136deg, #6750A4 0%, #B3261E 100%)'
            }}
        >
            <Stack spacing={3}>
                <Chip
                    avatar={<ArrowCircleLeftIcon sx={{color: '#EADDFF!important'}}/>}
                    label="Back"
                    variant="outlined"
                    sx={{
                        width: '100px',
                        color: '#EADDFF',
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                    onClick={()=>{navigate("/")}}
                />
                <OfferTitle>{offer!.offerName}</OfferTitle>
                <OfferDescription>{offer.offerDescription}</OfferDescription>
            </Stack>
            
        </Paper>
    )
}