import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material"
import React from "react"

export const EmptyCart: React.FC = () => {
    return (
        <React.Fragment>
            <Box padding={3}>
                <Card sx={{border: '0'}}>
                <CardMedia
                    component="img"
                    height="200"
                    image="https://media.tenor.com/mfP4dv4hQZkAAAAd/garfield-garfield-and-friends.gif"
                    alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Oh oh, your cart seems empty, trying finding some food
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    )
}