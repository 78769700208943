import { Box, InputAdornment, Stack, TextField } from "@mui/material";
import { useField } from "formik";
import { useParams } from "react-router-dom";
import { useFetchStoreInfo } from "../../../stores/hooks/useFetchStoreInfo";
import { useEffect, useState } from "react";

export interface PriceSectionProps {
    index: number;
}

export const PriceSection: React.FC<PriceSectionProps> = ( {index} ) => {
    const [dishPriceField, , dishPriceHelpers] = useField(`foodMenuItemsInputs.${index}.dishPrice`);
    const [dishPriceTextField, , dishPriceTextHelpers] = useField(`foodMenuItemsInputs.${index}.dishPriceText`);
    const [currency, setCurrency] = useState<string| undefined>(undefined);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dishPriceTextHelpers.setValue(`${currency} ${event.target.value}`);
        dishPriceHelpers.setValue(event.target.value);
    };
    const {id} = useParams();
    const {data, isLoading} = useFetchStoreInfo(id);
    
    useEffect( () => {
        if (data && data.length) {
            const currency_to_display = data[0].store_currency;
            setCurrency(currency_to_display);
        }
    }, [data]);
    if (!currency) return <>waiting for currency info..</>
    return (
        <Stack padding={2}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '90%'}}>
                <h4>Price</h4>
                <TextField
                    type="number"
                    id="priceText"
                    defaultValue="Price Text"
                    value={dishPriceField.value}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{currency}</InputAdornment>
                        ),
                      }}
                />
            </Box>
        </Stack>
    )
}