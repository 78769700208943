import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddOnItem, CustomizationStep, FoodMenuItem, StepOption } from '../../interfaces/FoodItem';
import { CartItem } from '../cartItems/cartItemsSlice';
import { loadCartItemOrders } from './CartOrderThunks';
// import { loadCartItems, persistCartItem, removeCartItemThunk } from './cartItemsThunks';

export interface CartOrderItem extends CartItem {

}

interface CartOrderState {
    cartOrderItems: {[email : string]: {[itemId:string]: CartOrderItem[]}};
    // alreadyLoaded: boolean,
    response: {
        message: string;
        type: 'success' | 'info' | 'warning' | 'error';
    }; 
}

const initialState: CartOrderState = {
	cartOrderItems: {},
    // alreadyLoaded: false,
    response: {
        message: '',
        type: 'success',
    },
};


const cartItemOrderSlice = createSlice({
	name: 'cartOrder',
	initialState,
	reducers: {
		moveCartItemsToOrder: (
			state,
			action: PayloadAction<{cartItems: CartItem[]}>
		) => {
			const { cartItems } = action.payload;
            // const email = cartItem.addedByEmail;
            // const cartItemUUID = cartItem.cartItemId;
            // if (!(email in state.cartOrderItems)) state.cartOrderItems[email] = {};
            // if (!(mainItemId in state.cartOrderItems[email])) state.cartOrderItems[email][mainItemId] = [];
            // state.cartOrderItems[email][mainItemId] = state.cartOrderItems[email][mainItemId].filter(item => item.cartItemId != cartItemUUID);
            // state.cartOrderItems[email][mainItemId].push(cartItem);
            // state.cartOrderItems = {...cartItems};
            for (let i = 0; i < cartItems.length; i++) {
                const cartItem = cartItems[i];
                const email = cartItem.addedByEmail;
                const mainItemId = cartItem.menu_item_id;
                if (!(email in state.cartOrderItems)) state.cartOrderItems[email] = {};
                if (!(mainItemId in state.cartOrderItems[email])) state.cartOrderItems[email][mainItemId] = [];
                state.cartOrderItems[email][mainItemId].push(cartItem);
            }
		},
        resetCartOrderItems: (
			state,
			action: PayloadAction<{}>
		) => {
			// const { mainItemId, cartItemUUID, email } = action.payload;
            // if (!(email in state.cartOrderItems)) return;
            // if (!(mainItemId in state.cartOrderItems[email])) return;
            // state.cartOrderItems[email][mainItemId] = state.cartOrderItems[email][mainItemId].filter(item => item.cartItemId != cartItemUUID);
            // if (state.cartOrderItems[email][mainItemId].length == 0) delete state.cartOrderItems[email][mainItemId]
            state.cartOrderItems = {}
		},
        
	},
    extraReducers: (builder) => {
        // builder.addCase(persistCartItem.rejected, (state, action) => {
        //     // Assuming action.payload or action.meta.arg contains necessary info to identify the item
        //     if (!action.meta.arg) return;
        //     const { cartItem, cart_item_id, email_id } = action.meta.arg;
        //     const mainItemId = cartItem.menu_item_id;
        //     // Utilize the existing removeCartItem logic
        //     state.cartItems[email_id] = state.cartItems[email_id] || {};
        //     if (state.cartItems[email_id][mainItemId]) {
        //         state.cartItems[email_id][mainItemId] = state.cartItems[email_id][mainItemId].filter(item => item.cartItemId !== cart_item_id);
        //         if (state.cartItems[email_id][mainItemId].length === 0) delete state.cartItems[email_id][mainItemId];
        //     }
        //     const errorMessage = 'Failed to save item'; // Fallback to a default message if the error message is not available

        //     state.response = {
        //         message: errorMessage,
        //         type: 'error', // Assuming 200 is your success code
        //     };
        
        // })
        // .addCase(removeCartItemThunk.rejected, (state, action) => {
        //     if (!action.meta.arg) return;
        //     const { cart_item } = action.meta.arg;
        //     const mainItemId = cart_item.menu_item_id
        //     const email = cart_item.addedByEmail;
        //     const cartItemUUID = cart_item.cartItemId;
        //     if (!(email in state.cartItems)) state.cartItems[email] = {};
        //     if (!(mainItemId in state.cartItems[email])) state.cartItems[email][mainItemId] = [];
        //     state.cartItems[email][mainItemId] = state.cartItems[email][mainItemId].filter(item => item.cartItemId != cartItemUUID);
        //     state.cartItems[email][mainItemId].push(cart_item);
        //     const errorMessage =  'Failed to remove item'; // Fallback to a default message if the error message is not available

        //     state.response = {
        //         message: errorMessage,
        //         type: 'error', // Assuming 200 is your success code
        //     };
        // })
        builder.addCase(loadCartItemOrders.fulfilled, (state, action) => {
            // Assuming the response structure matches your state structure
            // You might need to transform data if the structures are different
            // if (state.alreadyLoaded) return;
            if (action.payload) {
                action.payload.forEach(cartItem => {
                    if (! (cartItem.addedByEmail in state.cartOrderItems)) state.cartOrderItems[cartItem.addedByEmail] = {};
                    if (!(cartItem.menu_item_id in state.cartOrderItems[cartItem.addedByEmail])) state.cartOrderItems[cartItem.addedByEmail][cartItem.menu_item_id] = [];
                    if (state.cartOrderItems[cartItem.addedByEmail][cartItem.menu_item_id].some(item => item.cartItemId === cartItem.cartItemId)) return;
                    state.cartOrderItems[cartItem.addedByEmail][cartItem.menu_item_id].push(cartItem);
                })
                // state.alreadyLoaded = true;
            }
          
        })
        .addCase(loadCartItemOrders.rejected, (state, action) => {
            // if (state.alreadyLoaded) return;
            state.response = {
                message: 'Failed to load cart items',
                type: 'error', 
            };
        });
    },
});


export const { moveCartItemsToOrder, resetCartOrderItems } = cartItemOrderSlice.actions;

export default cartItemOrderSlice.reducer;
