import React, { useState, FormEvent } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Button, Stack } from '@mui/material';
import useCookie from '../../../../hooks/useCookie';

const CheckoutForm: React.FC = () => {
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Track the submitting state
    const {getCookie} = useCookie();
    const restaurant_prefix = getCookie('restaurant_prefix') || "";
    const return_url = process.env.REACT_APP_PAYMENT_STATUS_URL!.replace("store", restaurant_prefix);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url
            },
        });

        if (error) {
            setErrorMessage(error.message ?? null);
        } else {
        // Handle success scenarios
        }
        setIsSubmitting(true);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
                <PaymentElement />
                <Button sx={{ height: '50px', padding: '10px 24px', borderRadius: '25px', width: '100%',
                                                    backgroundColor: '#6750A4', color: 'white', textTransform: 'none', ':hover': {backgroundColor: '#6750A4', color: 'white'} }} disabled={isSubmitting} onClick={handleSubmit}>Pay</Button>                {errorMessage && <div>{errorMessage}</div>}
            </Stack>
        </form>
    );
};

export default CheckoutForm;
