import { FormikProvider } from "formik";
import { useStoreForm } from "../hooks/useStoreForm";
import { Button, Grid, Stack, TextField } from "@mui/material";
import StoreAddressComponent  from "../components/StoreAddressComponent";
import RestaurantDropdownComponent from "../components/RestaurantDropdownComponent";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useBreadcrumb } from "../../../contexts/BreadcrumbContext";
import { usePutStoreInfo } from "../hooks/usePutStoreInfo";
import { useFetchStoreInfo } from "../hooks/useFetchStoreInfo";
import { CreateUpdateStoreSkeleton } from "./CreateUpdateStoreSkeleton";
import ActionButtonsComponent from "../components/ActionButtonComponent";
import { useDeleteStoreInfo } from "../hooks/useDeleteStoreInfo";
import QRCodeGenerator from "../../../components/QRCodeGenerator";
import { StoreCurrencyComponent } from "../components/StoreCurrencyComponent";

export const CreateUpdateStore : React.FC = () => {

    const { pathname } = useLocation();
    const { setBreadcrumbName } = useBreadcrumb();


    const { mutate: updateStoreInfo, isSubmitting } = usePutStoreInfo();


    const isCreateOperation = pathname.endsWith('/create');
    const {id: storeId} = useParams() || null;
    const { data: storeData, isLoading, refetch } = useFetchStoreInfo(storeId);


    useEffect(() => {
        if (storeId && storeData && storeData.length == 1) {
            setBreadcrumbName(storeId, storeData[0].store_name);
        }
    }, [storeId, isLoading])

    const handleUserDataSubmit = (values: any) => {
        updateStoreInfo({variables: values, key: ['storeInfo', formik.values.store_id], invalidate: true});
        console.log(values);
        // updateRestaurant(values);
    };
    const formik = useStoreForm(handleUserDataSubmit, storeData);

    const { mutate: deleteStore, isSubmitting: isDeleting } = useDeleteStoreInfo();


    if (isLoading) return <CreateUpdateStoreSkeleton/>
    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} marginBottom={5}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Store Name"
                                    name="store_name"
                                    sx={{width: '100%'}}
                                    value={formik.values.store_name}
                                    onChange={formik.handleChange}
                                    disabled={!isCreateOperation}
                                    error={formik.touched.store_name && Boolean(formik.errors.store_name)}
                                    helperText={formik.touched.store_name && formik.errors.store_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RestaurantDropdownComponent disabled={!isCreateOperation}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} >
                                        <TextField
                                            label="Number of Tables(1-100)"
                                            name="tables"
                                            type="number"
                                            sx={{width: '100%'}}
                                            value={formik.values.tables}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.tables && Boolean(formik.errors.tables)}
                                            helperText={formik.touched.tables && formik.errors.tables}
                                            InputProps={{ inputProps: { min: 1, max: 100 } }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StoreCurrencyComponent/>
                                        {/* <TextField
                                            label="Currency"
                                            name="currency"
                                            sx={{width: '100%'}}
                                            value={formik.values.tables}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.tables && Boolean(formik.errors.tables)}
                                            helperText={formik.touched.tables && formik.errors.tables}
                                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                                        /> */}

                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={6}>
                        <StoreAddressComponent/>

                    </Grid>
                </Grid>
                <ActionButtonsComponent
                    isUpdating={isSubmitting}
                    isCreateOperation={isCreateOperation}
                    isDeleting={isDeleting}
                    deleteStore={() => deleteStore({variables: { store_id: storeId }, key: ['storeInfo', formik.values.store_id], invalidate: true})}
                    storeName={storeData?.[0]?.store_name || ''}
                />
            </form>
        </FormikProvider>
    )
}

