import { Grid } from "@mui/material";
import { Formik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../contexts/BreadcrumbContext";
import { useFetchStoreTables } from "../../tables/hooks/useFetchStoreTables";
import { SideListContainer } from "../components/SideListComponent";
import { DishItemEdit } from "../components/sideList/dishes/DishItemEdit";
import { useFoodMenuForm } from "../hooks/useMenuForm";
import { usePutStoreMenuItems } from "../hooks/usePutStoreMenuItems";

export const MenuFormPage: React.FC = () => {
    const { id, foodMenuItemId } = useParams<{ id: string, foodMenuItemId: string }>();
    const { data } = useFetchStoreTables(id || '');
    const { setHideBreadCrumbLabelsForLocation, setBreadcrumbName } = useBreadcrumb();

    const [initialValues, validationSchema] = useFoodMenuForm();

    useEffect(() => {
        if (id && data && data.length) {
            setBreadcrumbName(id, data[0].store_name);
            setHideBreadCrumbLabelsForLocation(["stores", "editItem"])
        }
    }, [data, id, foodMenuItemId]);

    
    

    if (!id) return <>waiting for id</>

    // const submitFormToBackend = (values: any) => {
    // }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {}}
            enableReinitialize={true}
        >
            {({ handleSubmit, values, errors }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} sx={{overflowY: 'scroll'}}>
                            <SideListContainer/>
                        </Grid>
                        <Grid item xs={9}>
                            <DishItemEdit/>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
        // <Grid container spacing={2}>
        
        // <Box sx={{display: 'flex', 'justifyContent': 'flex-start'}}>
        //     <SideListContainer/>
        //     {/* <MenuEditSidebarComponent lists={{"list1" : sections.map(item => item.title)}} />  */}
        // </Box>
    )
}