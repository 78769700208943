import Auth from '@aws-amplify/auth';
import { Link as RouterLink } from 'react-router-dom'; // Import Link component
import { Button, Divider, Link, Paper, Stack, styled, Typography } from "@mui/material"
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';
import WebSocketComponent from '../../../common/WebsSocketComponent';

const CustomLink = styled(Button)({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'start',
    width: '100%',
    paddingY: '16px',
    textAlign: 'left',
    paddingX: '0px',
    borderRadius: '8px',
    textDecoration: 'none',
    backgroundColor: '#f0f0f0',
    color: 'inherit',
    '& svg': {
      marginRight: '8px',
    },
    '&.MuiButtonBase-root': {
        padding: '0px',
        backgroundColor: '#fff'
    },
  });


const ProfileButtonText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textTransform: 'none',
    alignSelf: 'center', 
    marginLeft:'10px'
}));

export const DineInUserProfileOptions = () => {
    const navigate = useNavigate();
    return (
        <Stack padding={3} sx={{border: 'none'}} divider={<Divider sx={{marginY: 2}}/>}>
            <CustomLink onClick={() => navigate('/profile/info')} endIcon={<KeyboardDoubleArrowRightIcon sx={{color: '#6750A4', flex: 'auto', alignSelf:'center'}}/>}>
                <AccountBoxRoundedIcon sx= {{color:'#6750A4'}}/>
                <ProfileButtonText flexGrow={2}>My Account</ProfileButtonText> 
            </CustomLink>
            <CustomLink onClick={() => navigate('/profile/favourites')} endIcon={<KeyboardDoubleArrowRightIcon sx={{color: '#6750A4', flex: 'auto', alignSelf:'center'}}/>}>
                <FavoriteIcon sx= {{color:'#6750A4'}}/>
                <ProfileButtonText flexGrow={2}>Favourites</ProfileButtonText> 
            </CustomLink>
            <CustomLink href="" endIcon={<KeyboardDoubleArrowRightIcon sx={{color: '#6750A4', flex: 'auto',  alignSelf:'center'}}/>}>
                <ListAltIcon sx= {{color:'#6750A4'}}/>
                <ProfileButtonText flexGrow={2}>Past Orders</ProfileButtonText> 
            </CustomLink>
            <CustomLink href="#" endIcon={<KeyboardDoubleArrowRightIcon sx={{color: '#6750A4', flex: 'auto',  alignSelf:'center'}}/>}>
                <AccountBalanceWalletOutlinedIcon sx= {{color:'#6750A4'}}/>
                <ProfileButtonText flexGrow={2}>Mode of Payment</ProfileButtonText> 
            </CustomLink>
            <CustomLink onClick={()=>{ Auth.signOut();}} endIcon={<KeyboardDoubleArrowRightIcon sx={{color: '#B3261E', flex: 'auto',  alignSelf:'center'}}/>}>
                <LogoutOutlinedIcon sx= {{color: '#B3261E'}}/>
                <ProfileButtonText flexGrow={2} sx={{color: '#B3261E'}}>Logout</ProfileButtonText> 
            </CustomLink>
        </Stack>
    )
}