import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useFetchStoreTables } from '../hooks/useFetchStoreTables'
import { ListStoreSkeleton } from '../../stores/pages/ListStoresSkeletonPage';
import { useBreadcrumb } from '../../../contexts/BreadcrumbContext';
import { TableRowWithQRCodeComponent } from '../components/TableRowWithQRCodeComponent';

export interface TableInfo {
    restaurant_name: string;
    store_name: string;
    store_id: string;
    table_number: string;
    table_id: string;
    restaurant_id: string;
}

const ListStoreTables: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // Type the useParams hook
    const navigate = useNavigate();
    const { data, isLoading } = useFetchStoreTables(id || '');
    const [tableInfo, setTableInfo] = useState<TableInfo[]>([]); // Type the state


    const { setBreadcrumbName, setHideBreadCrumbLabelsForLocation } = useBreadcrumb();
    const location = useLocation();

    useEffect(() => {
        if (id && data && data.length) {
            setTableInfo(data);
            setBreadcrumbName(id, data[0].store_name);
            setHideBreadCrumbLabelsForLocation(["stores"])
        }
    }, [data, id]);


    // Handle row click
    const handleRowClick = (tableId: string) => {
        // Define your navigation logic based on tableId
        // Example: navigate(`/tables/${tableId}`);
    };

    if (isLoading) {
        return <ListStoreSkeleton count={30}/>
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Restaurant Name</TableCell>
                        <TableCell>Store Name</TableCell>
                        <TableCell>Table Number</TableCell>
                        <TableCell>Table URL</TableCell>
                        <TableCell>QR Code</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableInfo.map((table) => (
                        <TableRowWithQRCodeComponent key={table.table_id} table={table} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ListStoreTables;
