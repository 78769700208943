import { useQuery } from 'react-query'
import axiosInstance from '../common/axiosInstance';

const getUserSubscription = async () => {
    const response = await axiosInstance.get('https://api.menufy.link/restaurantBackend/users/subscriptions'); // Use the custom axios instance
    return response.data;
};

export const useFetchUserSubscriptions = () => {
    return useQuery(['userSubscriptions'], getUserSubscription);
};
