import React, { useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

interface BreadcrumbContextType {
    breadcrumbNames: Record<string, string>;
    hideBreadCrumbLabels: Record<string, string[]>;
    setBreadcrumbName: (id: string, name: string) => void;
    setHideBreadCrumbLabelsForLocation: (labels: string[]) => void;
  
}

const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumbNames: {},
  hideBreadCrumbLabels: {},
  setBreadcrumbName: (id: string, name: string) => {},
  setHideBreadCrumbLabelsForLocation: (labels: string[]) => {}
});

export const useBreadcrumb = () => useContext(BreadcrumbContext);

interface BreadcrumbProviderProps {
    children: React.ReactNode;
}

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({ children }) => {
  const [breadcrumbNames, setBreadcrumbNames] = useState({});
  const [hideBreadCrumbLabels, setHideBreadCrumbLabels] = useState({});
  const location = useLocation();
  const setBreadcrumbName = (id: string, name: string) => {
    setBreadcrumbNames(prev => ({ ...prev, [id]: name }));
  };

  const setHideBreadCrumbLabelsForLocation= (names: string[]) => {
    const path = location.pathname
    setHideBreadCrumbLabels(prev => ({ ...prev, [path]: names }));
  };

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbNames, setBreadcrumbName, hideBreadCrumbLabels, setHideBreadCrumbLabelsForLocation }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
