import { Box, Paper } from "@mui/material";

export interface HeroBannerImageProps {
    imageUrl: string;
    bgColor: string;
}
  
export const HeroBannerImage: React.FC<HeroBannerImageProps> = ({ imageUrl, bgColor }) => {
    return (
        <Paper style={{ marginTop: '10px', 
                    borderRadius: '10px', 
                    background: bgColor, 
                    height: '40vh',
                    padding:'10px'
                    }}>
                    <div style={{height:'100%'}}>
                        <Box style={{
                            height:'100%',
                            border:'2px',
                            padding:'10',
                            backgroundSize: 'contain', 
                            backgroundRepeat: 'no-repeat', 
                            backgroundPosition: 'center',
                            backgroundImage:`url(${imageUrl})`
                        }}/>
                    </div>
        </Paper>
    );
}