import { useSnackbarContext } from "../contexts/SnackbarContext";
import UserSnackbar from "./UserSnackbar";

export const GlobalSnackbar = () => {
    const { snackbarMessage } = useSnackbarContext();

    if (!snackbarMessage) return null;

    return (
        <UserSnackbar
            key={snackbarMessage.key}
            message={snackbarMessage.message}
            type={snackbarMessage.type}
        />
    );
};