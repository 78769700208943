import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { Typography, Stack } from '@mui/material';

export const LoaderPage:React.FC<{text: string}> = ({text}) => {
    return (
        <>
           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Slide direction="right" in mountOnEnter unmountOnExit>
                    <Typography variant="h6" gutterBottom sx={{ marginBottom: '20px'}}> {text} </Typography>
                </Slide>
                <CircularProgress sx={{color: "#6750A4"}} />
            </div>
        </>
    )
}