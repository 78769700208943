import { useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

export interface StoreFormData {
    store_name: string;
    store_id: string;
    store_restaurant_id: string;
    store_restaurant_name: string;
    store_address_first_line: string;
    store_address_second_line?: string;
    store_address_city: string;
    store_address_postcode: string;
    store_address_country: string;
    store_currency: string;
    store_currency_symbol: string;
    tables?: number; 
}

export const useStoreForm = (
    onSubmit: (values: StoreFormData, formikHelpers: FormikHelpers<StoreFormData>) => void | Promise<any>,
    storeData: StoreFormData[] | null,
) => {
    const [initialFormValues, setInitialFormValues] = useState<StoreFormData>({
        store_name: "",
        store_id: uuidv4(),
        store_address_first_line: "",
        store_address_second_line: "",
        store_address_city: "",
        store_restaurant_id: "",
        store_restaurant_name: "",
        store_address_postcode: "",
        store_address_country: "",
        tables: 1,
        store_currency: 'AED',
        store_currency_symbol: 'AED'
    });

    useEffect(() => {
        if (storeData && storeData.length) {
            const data = storeData[0];
            console.log(storeData);
            setInitialFormValues({
                store_name: data.store_name,
                store_id: data.store_id,
                store_address_first_line: data.store_address_first_line,
                store_address_second_line: data.store_address_second_line,
                store_restaurant_id: data.store_restaurant_id,
                store_restaurant_name: data.store_restaurant_name,
                store_address_city: data.store_address_city,
                store_address_postcode: data.store_address_postcode,
                store_address_country: data.store_address_country,
                tables: data.tables || 0,
                store_currency: data.store_currency || 'AED',
                store_currency_symbol: data.store_currency_symbol || 'AED'
            });
        }
    }, [storeData]);

    const storeInfoSchema = Yup.object().shape({
        store_name: Yup.string().required('Store name is required'),
        store_id: Yup.string().required('Store id is required'),
        tables: Yup.number().min(1).max(100).required('Number of tables is required'),
        store_address_first_line: Yup.string().required('Address first line is required'),
        store_address_city: Yup.string().required('City is required'),
        store_address_postcode: Yup.string().required('Postcode is required'),
        store_address_country: Yup.string().required('Country is required'),
        store_address_second_line: Yup.string(),
        store_restaurant_id: Yup.string().required('Restaurant ID is required'),
        store_restaurant_name: Yup.string().required('Restaurant name is required'),
        store_currency: Yup.string().required('Store Currency is required'),
        store_currency_symbol: Yup.string().required('Currency Symbol is required'),
    });

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: storeInfoSchema,
        onSubmit: onSubmit,
        enableReinitialize: true,
        validateOnMount: true,
    });
    return formik;
};
