import React from "react";
import { useMenuPageFoodItemsWithSearchAndFilters } from "../../../../hooks/useMenuPageFoodItemsWithSearchAndFilters";
import { DineInStickyFooter } from "../common/DineInStickyFooter";
import { ItemCardSection } from "../common/MenuFoodItemCard";
import { MenuPageSkeleton } from "../common/MenuPageSkeleton";
import { DineInMenuPageNavBar } from "./DineInMenuPageNavBar";


const DineInMenuPage = () => {
  
  const {isLoading: loading, sections, filterText, setFilterText, menuPageCardSection, foodCategoryFilter, setFoodCategoryFilter, defaultFilter} = useMenuPageFoodItemsWithSearchAndFilters();
 
  return (
    <React.Fragment>
    {loading ? (<MenuPageSkeleton count={30}/>) : (//( <LoaderPage text="Loading menu.."/> ) : (
		<>
			<DineInMenuPageNavBar sections={sections} searchText={filterText} setSearchText={setFilterText} foodCategoryFilter={foodCategoryFilter} setFoodCategoryFilter={setFoodCategoryFilter} defaultFilter={defaultFilter}/>
      <ItemCardSection menuPageCardSection={menuPageCardSection} />
			<DineInStickyFooter page= 'menu' />
		</>
      )}
    </React.Fragment>
  );
}

export default DineInMenuPage;
