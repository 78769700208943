
import { Amplify } from 'aws-amplify';
import { getStoreName } from './utils/storeUtils';
import { RestaurantAdminApp } from './AdminApp';
import { DineInApp } from './DineInApp';
import { BrowserRouter } from 'react-router-dom';
import { RedirectApp } from './RedirectApp';


import { QueryClientProvider } from 'react-query';
import { QueryClient } from 'react-query';

import store from './redux/stores/mainStore';
import { Provider } from 'react-redux';
import { SnackbarProvider } from './components/restaurantAdmin/contexts/SnackbarContext';
import { GlobalSnackbar } from './components/restaurantAdmin/components/GlobalSnackbar';

const amplifyInit = () => {
	const cookieStorage = {
		domain: process.env.REACT_APP_COOKIE_STORE_DOMAIN,
		secure: process.env.REACT_APP_COOKIE_STORE_SECURE,
		path: '/',
		expires: 365,
	};
	Amplify.configure({
		Auth: {
				region: 'us-east-1',
				userPoolId: "us-east-1_nsukXxUEB",
				userPoolWebClientId: "1h526jbl8hh1eiirmdjqcg3n9o",
				oauth:{
					domain: "menufy-digininja-prefix.auth.us-east-1.amazoncognito.com",
					redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
					redirectSignOut: process.env.REACT_APP_LOGOUT_URL,
					responseType: "code"
				},
				cookieStorage: cookieStorage,
		}
	})
	Amplify.Logger.LOG_LEVEL = 'DEBUG';
}



const App = () =>  {

	amplifyInit();

	const storeName = getStoreName();


	const isRedirectOrLoginLogoutPage = (store: string) : boolean => {
		return store === 'store'; 
	}

	const isRestaurantAdminPortal = (store: string) : boolean => {
		console.log(`checking if store ${store} is admin`)
		return store == 'admin';
	}


	if (isRedirectOrLoginLogoutPage(storeName)) {
		return (
			<RedirectApp/>
		)
	} else if (isRestaurantAdminPortal(storeName)) {
		return (
			<RestaurantAdminApp />
		);
	}

	const cacheTime = (15 * 60 * 1000);

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: cacheTime, // 5 minutes in milliseconds
			},
		},
	});


	// const isValid = (store: string) :  boolean => {
	// 	return store == 'subway' ? true : false
	// }

	

	

	// const notRedirectURL = (url: string) : boolean => {
	// 	return (url != process.env.REACT_APP_REDIRECT_URL);
	// }
    // console.log(window.location.pathname)
	// const isLocalhost = process.env.REACT_APP_IS_LOCALHOST;
	// console.log(`is local ${is_localhost}`)
	// if (isLocalhost == 'false' && notRedirectURL(window.location.hostname)) {
	// 	console.log(isValid(storeName))
	// 	if (!isValid(storeName)) {
	// 		return(
	// 			<div>
	// 				<h1>404 - Not Found!</h1>
	// 			</div>
	// 		)
	// 	}
	// }

	// if (isRestaurantAdminPortal(storeName)) {
	// 	return (
	// 		<>
	// 			<RestaurantAdminApp />
	// 		</>
	// 	)
	// }

	// if (isRedirectOrLoginLogoutRoute()) {

	// }


	return (
		<div className="App">

			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<SnackbarProvider>
						<BrowserRouter>
							<DineInApp/>
						</BrowserRouter>
						<GlobalSnackbar />
					</SnackbarProvider>
				</Provider>
			</QueryClientProvider>
		</div>
  );
}




export default App;
