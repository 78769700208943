import { Elements } from "@stripe/react-stripe-js"
import { DineInStickyFooter } from "../common/DineInStickyFooter"
import { DineInCheckoutpageNavBar } from "./DineInCheckoutPageNavBar"
import { useStripeContext } from "../../../../contexts/StripeContext"
import { useEffect, useState } from "react"
import { StripeElementsOptionsMode } from "@stripe/stripe-js"
import { useFetchStripeIntent } from "./hooks/useFetchStripeIntent"
import { LoaderPage } from "../../../rootDomain/LoderPage"
import { useSelector } from "react-redux"
import { SelectCartItemOrderSummaryWithNameEmailTimeStamp } from "../../../../redux/cartOrder/CartOrderSelectors"
import { useLocation } from "react-router-dom"

export const DineInCheckoutPage = () => {
    const cartItemOrderSummaryWithNameEmailTimeStamp = useSelector(SelectCartItemOrderSummaryWithNameEmailTimeStamp);
    const hasOrdered = cartItemOrderSummaryWithNameEmailTimeStamp ? cartItemOrderSummaryWithNameEmailTimeStamp.length > 0 : false
    const { stripePromise } = useStripeContext()
    const [ options, setOptions ] = useState<StripeElementsOptionsMode | undefined>(undefined);
    const { data: stripeIntent, isLoading: isStripeIntentLoading} = useFetchStripeIntent(hasOrdered);
    const location = useLocation();
    
    let loaderText = 'Loading cart info'; // Default loader text
    if (location.pathname.startsWith('/checkout/paymentStatus')) {
      loaderText = 'Checking payment status...';
    } else if (location.pathname === '/checkout/pay') {
      loaderText = 'Loading payment info..';
    } else {
        loaderText = 'Checking cart info..'
    }
    useEffect(() => {
        if (stripeIntent) {
            const stripeElementsOptions: StripeElementsOptionsMode = {
                appearance: {
                
                },
                clientSecret: stripeIntent.client_secret
            };
            setOptions(stripeElementsOptions);
        }
        console.log(stripeIntent)
        console.log(stripePromise)
    }, [stripeIntent, stripePromise]);
    if ((isStripeIntentLoading || !stripePromise || !options) && hasOrdered) return <LoaderPage text={loaderText} />
    
    return(
        <Elements stripe={stripePromise} options={options}>
            <DineInCheckoutpageNavBar/>
            <DineInStickyFooter page='cart'/>
        </Elements>
    )
}