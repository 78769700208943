import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { LoaderPage } from "../../rootDomain/LoderPage";
import { useNavigate } from "react-router-dom";

export const AdminLogoutPage = () => {
    const navigate = useNavigate();
    const [loggedOut, setLoggedOut] = useState(false);
    useEffect(() => {
        const signOut = async () => {
            try {
                await Auth.signOut();
                 // Redirect to home if user is already logged in
                setLoggedOut(true);
            } catch (error) {
                console.log('Not authenticated:', error);
            }
        };

        signOut();
    }, [navigate]);
    
    if (loggedOut) {
        navigate('/login');
    }

    return (
        <LoaderPage text="logging out..."/>
    )
}