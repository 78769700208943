import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useSnackbarContext } from '../restaurantAdmin/contexts/SnackbarContext';
import { v4 as uuidv4 } from 'uuid';
import { useProcessWebSocketMessage } from '../../hooks/useProcessWebSocketMessage';
import useCookie from '../../hooks/useCookie';

const WebSocketComponent: React.FC = () => {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const { setSnackbarMessage } = useSnackbarContext();
    const { processMessage } = useProcessWebSocketMessage();
    const { getCookie } = useCookie();
    const storeId = getCookie('storeId');


    const connectWebSocket = async () => {
        try {
            // Attempt to fetch the current authenticated user
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.accessToken.jwtToken;
            const urlDomain = `wss://ws.menufy.link/?token=${encodeURIComponent(token)}&storeId=${storeId}`;

            // Initialize WebSocket connection
            const socket = new WebSocket(urlDomain);

            socket.addEventListener('open', () => console.log('WebSocket is connected.'));
            socket.addEventListener('message', (event: MessageEvent) => {
                console.log('Message from server:', event.data);
                processMessage(event.data);
            });
            socket.addEventListener('error', (error: Event) => console.error('WebSocket error:', error));
            socket.addEventListener('close', () => {
                console.log('WebSocket is closed.');
                setWs(null); // Ensure the WebSocket state is cleared on close
            });

            setWs(socket);
        } catch (error) {
            console.error('Error establishing WebSocket connection or user not authenticated:', error);
        }
    };

    useEffect(() => {
        // Only establish a new WebSocket connection if one doesn't already exist
        if (!ws) {
            connectWebSocket();
        }

        // Define a cleanup function to close the WebSocket connection when the component unmounts
        return () => {
            ws?.close();
        };
    }, [ws]); // Depend on 'ws' to avoid unnecessary reconnections

    useEffect(() => {
        // Function to handle reconnection attempts when the window gains focus
        const handleWindowFocus = () => {
            if (!ws) {
                connectWebSocket();
            }
        };

        // Add event listener for window focus to trigger reconnection attempts
        window.addEventListener('focus', handleWindowFocus);

        // Remove event listener on cleanup
        return () => window.removeEventListener('focus', handleWindowFocus);
    }, [ws]); // Depend on 'ws' to ensure the latest WebSocket state is used

    return null; // This component does not render anything
};

export default WebSocketComponent;
