import React, { useState } from 'react';
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Outlet, useNavigate } from 'react-router-dom';
import { MENU_PAGE_ROOT_PATH } from '../../../../constants/pathConstants';


export interface ModalProps {
  isOpen: boolean
}
const BottomDrawer:React.FC<ModalProps> = ({isOpen}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(isOpen);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(MENU_PAGE_ROOT_PATH);//, "../..", { relative: "path" });
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        sx={{
            ' .MuiDrawer-paperAnchorBottom': {
              zIndex: -1,
              overflowY: 'visible',
              height:'60vh',
              position: 'fixed',
              bottom: 0
            },
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Change the backdrop color here
            },
  
          }}
      >
        {/* <Box sx={{position: 'fixed', bottom: '0', height: '60vh'}}> */}
        <Outlet/>
        {/* </Box> */}
        {open && (
          <HighlightOffIcon
          onClick={handleClose}
          sx={{
              position: 'absolute',
              top: '-10%',
              color: 'white',
              left: '50%',
              transform: 'translateX(-50%) scale(2.0)',
              zIndex: 10, // Set a higher z-index to display the close button above the drawer
          }}
          >
          </HighlightOffIcon>
        )}
        
      </Drawer>
      
    </>
  );
};

export default BottomDrawer;
