import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../../../../common/axiosInstance';
import dayjs from 'dayjs';
import { useSnackbarContext } from '../../../contexts/SnackbarContext';
import { v4 as uuidv4 } from 'uuid';
import { useBaseMutation } from '../../../../hooks/useBaseMutation';

export const usePutUserInfo = () => {
    const transformUserData = (userData: any) => ({
        first_name: userData.first_name,
        last_name: userData.last_name,
        date_of_birth: dayjs(userData.date_of_birth).format('DD-MM-YYYY'),
        phone_number: userData.phone_number,
    });

    return useBaseMutation(
        'put',
        'https://api.menufy.link/restaurantBackend/users/info', // Endpoint
        // 'userData',                                              // Query key
        transformUserData                                        // Data transformation function
    );
};
