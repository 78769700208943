import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { Chip, Stack, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export const GreenText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '14px',
	fontWeight: 700,
	textAlign: 'left',
	color: '#218D0F',
    paddingRight: '2px'
}));


export const PaymentStatus: React.FC = () => {
    const stripe: Stripe | null = useStripe();
    const [message, setMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
        if (!clientSecret) {
            setMessage('Payment intent client secret not found.');
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (!paymentIntent) {
            setMessage('PaymentIntent not found.');
            return;
        }

        switch (paymentIntent.status) {
            case 'succeeded':
                setMessage('Success! Payment received.');
                break;

            case 'processing':
                setMessage("Payment processing. We'll update you when payment is received.");
                break;

            case 'requires_payment_method':
                setMessage('Payment failed. Please try another payment method.');
                break;

            default:
                setMessage('Something went wrong.');
                break;
        }
        }).catch((error: Error) => {
            setMessage(error.message || 'An error occurred while retrieving the payment intent.');
        });
    }, [stripe]);

    return (
        <Stack spacing={3} padding={3}>
            <Chip
                avatar={<ArrowCircleLeftIcon/>}
                label="Back"
                variant="outlined"
                sx={{
                    width: '100px',
                    fontSize: '16px',
                    fontWeight: '500'
                }}
                onClick={()=>{navigate("/")}}
            />
            <GreenText>{message}</GreenText>
        </Stack>
    )
};

export default PaymentStatus;
