import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddOnItem, CustomizationStep, FoodMenuItem, StepOption } from '../../interfaces/FoodItem';
import { loadCartItems, persistCartItem, removeCartItemThunk } from './cartItemsThunks';
import { getTotalSumInNumberAndString } from '../../utils/priceCalculatorUtil';

interface CartItemState {
	// cartItems: Record<string, Record<string, CartItem[]>>;
    cartItems: {[email : string]: {[itemId:string]: CartItem[]}};
    alreadyLoaded: boolean,
    response: {
        message: string;
        type: 'success' | 'info' | 'warning' | 'error';
    }; 
}

const initialState: CartItemState = {
	cartItems: {},
    alreadyLoaded: false,
    response: {
        message: '',
        type: 'success',
    },
};

export type CartItem  = {
    cartItemId: string;
    addedOnTimestamp: number;
    addedByEmail: string;
    addedByName: string;
    dishName: string;
    menu_item_id: string;
    dishType: 'veg' | 'non-veg'; 
    dishPriceText: string;
    dishPrice: number;
    customizeOptions?: CustomizationStep[];
    totalPriceWithCustomization: number;
    totalPriceWithCustomizationAndCurrency: string;
}
const createCartItems = (data: CartItem) => {
    
}
const cartItemSlice = createSlice({
	name: 'cartItems',
	initialState,
	reducers: {
		addCartItem: (
			state,
			action: PayloadAction<{mainItemId: string, cartItem: CartItem}>
		) => {
			const { mainItemId, cartItem } = action.payload;
            const email = cartItem.addedByEmail;
            const cartItemUUID = cartItem.cartItemId;
            // [cartItem.totalPriceWithCustomization, cartItem.totalPriceWithCustomizationAndCurrency] = getTotalSumInNumberAndString(cartItem)
            if (!(email in state.cartItems)) state.cartItems[email] = {};
            if (!(mainItemId in state.cartItems[email])) state.cartItems[email][mainItemId] = [];
            state.cartItems[email][mainItemId] = state.cartItems[email][mainItemId].filter(item => item.cartItemId != cartItemUUID);
            state.cartItems[email][mainItemId].push(cartItem);
		},
        removeCartItem: (
			state,
			action: PayloadAction<{mainItemId: string, cartItemUUID: string, email: string}>
		) => {
			const { mainItemId, cartItemUUID, email } = action.payload;
            if (!(email in state.cartItems)) return;
            if (!(mainItemId in state.cartItems[email])) return;
            state.cartItems[email][mainItemId] = state.cartItems[email][mainItemId].filter(item => item.cartItemId != cartItemUUID);
            if (state.cartItems[email][mainItemId].length == 0) delete state.cartItems[email][mainItemId]
		},
        resetCartItems: (
            state,
            action: PayloadAction<{}>
        ) => {
            state.cartItems = {}
        }
	},
    extraReducers: (builder) => {
        builder.addCase(persistCartItem.rejected, (state, action) => {
            // Assuming action.payload or action.meta.arg contains necessary info to identify the item
            if (!action.meta.arg) return;
            const { cartItem, cart_item_id, email_id } = action.meta.arg;
            const mainItemId = cartItem.menu_item_id;
            // Utilize the existing removeCartItem logic
            state.cartItems[email_id] = state.cartItems[email_id] || {};
            if (state.cartItems[email_id][mainItemId]) {
                state.cartItems[email_id][mainItemId] = state.cartItems[email_id][mainItemId].filter(item => item.cartItemId !== cart_item_id);
                if (state.cartItems[email_id][mainItemId].length === 0) delete state.cartItems[email_id][mainItemId];
            }
            const errorMessage = 'Failed to save item'; // Fallback to a default message if the error message is not available

            state.response = {
                message: errorMessage,
                type: 'error', // Assuming 200 is your success code
            };
        
        })
        .addCase(removeCartItemThunk.rejected, (state, action) => {
            if (!action.meta.arg) return;
            const { cart_item } = action.meta.arg;
            const mainItemId = cart_item.menu_item_id
            const email = cart_item.addedByEmail;
            const cartItemUUID = cart_item.cartItemId;
            if (!(email in state.cartItems)) state.cartItems[email] = {};
            if (!(mainItemId in state.cartItems[email])) state.cartItems[email][mainItemId] = [];
            state.cartItems[email][mainItemId] = state.cartItems[email][mainItemId].filter(item => item.cartItemId != cartItemUUID);
            state.cartItems[email][mainItemId].push(cart_item);
            const errorMessage =  'Failed to remove item'; // Fallback to a default message if the error message is not available

            state.response = {
                message: errorMessage,
                type: 'error', // Assuming 200 is your success code
            };
        })
        .addCase(loadCartItems.fulfilled, (state, action) => {
            // Assuming the response structure matches your state structure
            // You might need to transform data if the structures are different
            if (state.alreadyLoaded) return;
            if (action.payload) {
                action.payload.forEach(cartItem => {
                    if (! (cartItem.addedByEmail in state.cartItems)) state.cartItems[cartItem.addedByEmail] = {};
                    if (!(cartItem.menu_item_id in state.cartItems[cartItem.addedByEmail])) state.cartItems[cartItem.addedByEmail][cartItem.menu_item_id] = [];
                    state.cartItems[cartItem.addedByEmail][cartItem.menu_item_id].push(cartItem);
                })
                state.alreadyLoaded = true;
            }
          
        })
        .addCase(loadCartItems.rejected, (state, action) => {
            if (state.alreadyLoaded) return;
            state.response = {
                message: 'Failed to load cart items',
                type: 'error', 
            };
        });
    },
});


export const { addCartItem, resetCartItems, removeCartItem } = cartItemSlice.actions;

export default cartItemSlice.reducer;
