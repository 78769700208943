import { configureStore } from '@reduxjs/toolkit';
import customizationReducer from '../FoodItemCustomization/customizationSlice'
import cartItemReducer from '../cartItems/cartItemsSlice'
import CustomerFavouritesSlice from '../customerFavourites/CustomerFavouritesSlice';
import CartItemOrderSlice from '../cartOrder/CartOrderSlice';
// import logger from "redux-logger";

const store = configureStore({
  reducer: {
    currentCustomizedFood: customizationReducer,
    cartItems: cartItemReducer,
    customerFavourites: CustomerFavouritesSlice,
    cartOrderItems: CartItemOrderSlice
    // Add other reducers if needed
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch