import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotFoundPage } from './components/common/NotFoundPage';
import RequireAdminAuth from './components/restaurantAdmin/RequireAdminAuth';
import { RestaurantAdminLogin } from './components/restaurantAdmin/RestuarantAdminLogin';
import { HomePage } from './components/restaurantAdmin/pages/HomePage';
import { GlobalSnackbar } from './components/restaurantAdmin/components/GlobalSnackbar';
import { SnackbarProvider } from './components/restaurantAdmin/contexts/SnackbarContext';
import ListStoresForMenuPage from './components/restaurantAdmin/pages/menu/pages/ListStoreForMenuPage';
import { MenuFormPage } from './components/restaurantAdmin/pages/menu/pages/MenuFormPage';
import { CreateUpdateRestaurant } from './components/restaurantAdmin/pages/restaurant/modify/CreateUpdate';
import ListRestaurantPage from './components/restaurantAdmin/pages/restaurant/pages/ListRestaurantsPage';
import { RestaurantOperations } from './components/restaurantAdmin/pages/restaurant/pages/RestaurantOperations';
import { CreateUpdateStore } from './components/restaurantAdmin/pages/stores/pages/CreateUpdateStore';
import ListStoresPage from './components/restaurantAdmin/pages/stores/pages/ListStoresPage';
import { StoreOperations } from './components/restaurantAdmin/pages/stores/pages/StoreOperations';
import ListStoreRestaurants from './components/restaurantAdmin/pages/tables/pages/ListStoresRestaurants';
import ListStoreTables from './components/restaurantAdmin/pages/tables/pages/ListTableInfo';
import EditUserInfo from './components/restaurantAdmin/pages/user/ modify/UserInfo';
import { ViewEditStorePayment } from './components/restaurantAdmin/pages/payments/pages/ViewEditStorePayment';
import { ListStoresForPayment } from './components/restaurantAdmin/pages/payments/pages/ListStoresForPayment';
import useCookie from './hooks/useCookie';
import { LoggedInBuyPlan } from './components/restaurantAdmin/pages/buyplan/loggedIn/LoggedInBuyPage';
import { AdminLogoutPage } from './components/restaurantAdmin/pages/LogoutPage';
import { AddListUsers } from './components/restaurantAdmin/pages/colleagues/pages/AddListUsers';

const cacheTime = (15 * 60 * 1000);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: cacheTime, // 5 minutes in milliseconds
		},
	},
});


export const RestaurantAdminApp = () => {
	const { setCookie } = useCookie()
	
	setCookie('restaurant_prefix', 'admin');

	return (

		<QueryClientProvider client={queryClient}>
			<SnackbarProvider>
				<BrowserRouter>
					<Authenticator.Provider>
						<Routes>
								<Route path='/login' element={<RestaurantAdminLogin/>} />
								<Route path="/" element={<RequireAdminAuth/>}>
									<Route path= "" element={<HomePage/>}/>
									<Route path="profile" element={<EditUserInfo/>}/>
									
									<Route path="restaurants/create" element={<CreateUpdateRestaurant />} />
        							<Route path="restaurants/:id" element={<CreateUpdateRestaurant/>} />
									<Route path="restaurants/list" element={<ListRestaurantPage />} />
									<Route path="restaurants" element={<RestaurantOperations/>}/>

									<Route path="stores/create" element={<CreateUpdateStore />} />
        							<Route path="stores/:id" element={<CreateUpdateStore />} />
									<Route path="stores/list" element={<ListStoresPage />} />
									<Route path="stores" element={<StoreOperations/>}/>
									
									
									<Route path="tables/stores/:id" element={<ListStoreTables/>}/>
									<Route path="tables" element={<ListStoreRestaurants productPageLink='/tables/stores/storeId'/>}/>

									<Route path="menu/stores/:id" element={<MenuFormPage/>}/>
									<Route path="menu/stores/:id/editItem/:foodMenuItemId" element={<MenuFormPage/>}/>
									<Route path="menu/stores/:id/editItem/:foodMenuItemId/foodItemIndex/:foodItemIndex/customization/stepIndex/:stepIndex/position/:positionIndex" element={<MenuFormPage/>}/>
									
									<Route path="menu" element={<ListStoresForMenuPage/>}/>

									<Route path="payments/stores/:id" element={<ViewEditStorePayment />} />
									<Route path='payments/stores' element={<ListStoresForPayment/>} />
									<Route path='payments' element={<ListStoresForPayment/>} />

									<Route path="users/stores/:id" element={<AddListUsers/>} />
									<Route path="users" element={<ListStoreRestaurants productPageLink='/users/stores/storeId'/>} />

								</Route>
								<Route path="/logout" element={<AdminLogoutPage/>} />
								<Route path="/loggedInBuyPlan" element={<LoggedInBuyPlan/>} />
								<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</Authenticator.Provider>
				</BrowserRouter>
				<GlobalSnackbar />
			</SnackbarProvider>
		</QueryClientProvider>
	)
}