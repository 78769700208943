import axios from 'axios';
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create({
    // ... other Axios config
});

axiosInstance.interceptors.request.use(async (config) => {
    try {
        const user = await Auth.currentAuthenticatedUser();

        const token = user.signInUserSession.accessToken.jwtToken;

        // Define headers
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        console.log(`Bearer ${token}`)

        // Set headers on config
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['Content-Type'] = 'application/json';

    } catch (error) {
        console.error('Error retrieving auth token', error);
        // Optionally handle errors here (e.g., redirect to login)
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;
