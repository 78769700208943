import { Stack, Typography, styled } from "@mui/material";
import { CartItemSummary } from "../../../../redux/cartItems/cartItemsSelectors";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { useState } from "react";


const CustomizationText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '12px',
	fontWeight: 400,
	textAlign: 'left',
	color: '#1C1B1F',
  }));

  const AddedOnItemText = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '12px',
	fontWeight: 400,
	textAlign: 'left',
	color: '#79747E',
  }));

export interface CheckoutCustomizationSectionProps {
    cartItemSummary: CartItemSummary
}

export const CheckoutCustomizationSection: React.FC<CheckoutCustomizationSectionProps> = ({ cartItemSummary }) => {
    const [show, setShow] = useState(false);
    // const select = useSelect
    const toggleShow = () => {
        const val = show;
        setShow(!val);
    }
    return (
        <Stack>
            <CustomizationText style={{'display': 'flex', 'alignItems': 'center'}} onClick={toggleShow}>Customize <ArrowDropDownRoundedIcon/> </CustomizationText>
                { show && cartItemSummary.customizations!.map(customization => 
                    (
                        <AddedOnItemText>{customization.name}</AddedOnItemText>
                    ))
                }
        </Stack>
    )
}