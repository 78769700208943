import { Stack } from "@mui/material"
import { CategorySideList } from "./sideList/cateogories/CategorySideList"
import { DishesSideList } from "./sideList/dishes/DishesSideList"
import { useState } from "react"

export const SideListContainer: React.FC = () => {
    return (
        <Stack spacing={4} direction="column" sx={{maxHeight: '100vh', overflowY: 'scroll'}}>
            <CategorySideList/>
            <DishesSideList />
        </Stack>
    )
}