import { useQuery } from 'react-query'
import axiosInstance from '../../../../common/axiosInstance';

const getUserStoresData = async () => {
    const response = await axiosInstance.get('https://api.menufy.link/restaurantBackend/users/stores'); // Use the custom axios instance
    return response.data;
};

export const useFetchUserStores = () => {
    return useQuery('UserStores', getUserStoresData, {
        refetchOnMount: 'always',
    });
};
