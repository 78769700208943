import React, { useState, useEffect, useRef } from 'react';
import { Menu, List, ListItem, ListItemText, ListItemIcon, Button, styled, Typography, Paper, ListSubheader, ListItemButton, Collapse, IconButton, Divider, Container } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { LoaderPage } from '../../../rootDomain/LoderPage';

export interface Section {
  id: string;
  title: string;
}

export interface ScrollspyDropdownProps {
  sections: Section[];
  offset: number;
}
const ScrollSpyText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left',
  }));

const ScrollspyDropdown: React.FC<ScrollspyDropdownProps> = ({ sections, offset }) => {
  const [activeSection, setActiveSection] = useState<Section>(sections[0])
    // console.log(sections)
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSectionClick = (sectionId: string) => {
    const section = document.querySelector(sectionId);
    if (section) {
      const yOffset = -10//-10
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset - offset;

    //   console.log("handliig click " + y)
      window.scrollTo({ top: y, behavior: 'smooth' });
    //   logInfo()
    //   setActiveSection(sections.find(sectionId
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    // console.log("at " + scrollPosition)
    // Determine the active section based on scroll position
    for (const section of sections) {
      const sectionElement = document.querySelector(section.id);
      if (sectionElement) {
        const yOffset = 0//-10
        const sectionTop = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset - offset;
        const sectionBottom = sectionTop + sectionElement.clientHeight;
        if (scrollPosition >= sectionTop - 2 && scrollPosition < sectionBottom) {
        // if (scrollPosition < sectionTop) {
          setActiveSection(section);
        //   logInfo()
          return;
        }
      }
    }

    setActiveSection(sections[0]);
  };

  const logInfo = () => {
    const yOffset = 0//-10
    // console.log("at " +  window.pageYOffset + ", " + (window.pageYOffset + yOffset) + ", " + (window.pageYOffset + yOffset - offset))
    for (const section of sections) {
        const sectionElement = document.querySelector(section.id);
        if (sectionElement) {
          const sectionTop = sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset - offset;
          const sectionBottom = sectionTop + sectionElement.clientHeight;
        //   console.log( section.title + " " + sectionTop + " " + sectionBottom)
        }
  }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);

  useEffect( () => {
    if(sections.length > 0)
        setActiveSection(sections[0])
  }, [sections])

  return (<List
      sx={{width: '150px', height: '1.5 rem', border:'1px solid #79747E', borderRadius: '20px', bgcolor: 'background.paper', color: '#1C1B1F'}}
    >
        
        <ListItem component='div' onClick={handleClick} alignItems='center' sx={{paddingY: '0px'}}>
            <ListItemText><ScrollSpyText> {activeSection ? activeSection.title: ""}</ScrollSpyText> </ListItemText>
            <IconButton sx={{padding:'0px', color:'#7947E'}}> {open ? <ArrowDropUpRoundedIcon/> : <ArrowDropDownRoundedIcon/> } </IconButton>
        </ListItem>
        <Container sx={{display: open ?  'block': 'none'}} ><Divider sx={{margin: '2px'}} /></Container>
        <Collapse in={open} sx={{maxHeight: '100px', overflow: 'scroll'}}timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

                {activeSection && sections.map((section) => (
                    <ListItem
                    key={section.id}
                    button
                    onClick={() => handleSectionClick(section.id)}
                    selected={activeSection!.id === section.id}
                    >
                        <ScrollSpyText>{section.title}</ScrollSpyText>
                    {/* <ListItemText primary= /> */}
                    </ListItem>
                ))}
            </List>
        </Collapse>
    </List>);
};

export default ScrollspyDropdown;
