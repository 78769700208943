import { useQuery } from 'react-query';
import axios from 'axios'; // Import axios directly if you don't have an axiosInstance set up
import axiosInstance from '../components/common/axiosInstance';
import { CustomerInfo } from '../components/dinein/pages/profile/ProfileFormComponent';

// Define the function to fetch customer info
const fetchCustomerInfo = async () : Promise<CustomerInfo> => {
  const { data } = await axiosInstance.get('https://api.menufy.link/dineIn/customers/info'); // Replace axios with your axiosInstance if available
//   console.log(data);
  return data as CustomerInfo;
};

// Hook to use the fetch function with react-query
export const useFetchCustomerInfo = () => {
  return useQuery(['customerData'], fetchCustomerInfo);
};
