import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CartItemSummaryWithNameEmailTimeStamp, SelectCartItemSummaryWithNameEmailTimeStamp, selectItemIdCountsAfterSquashCompress } from "../../../../redux/cartItems/cartItemsSelectors";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { MENU_PAGE_ROOT_PATH } from "../../../../constants/pathConstants";
import { ItemSummarySection } from "../checkout/ItemSummarySection";
import React from "react";
import { Box, Stack } from "@mui/material";
import { CustomizeBoldTitle, CustomizePageTitle } from "../common/FoodCustomizationForm";

export const DineInDeleteCustomizationPage = () => {
    const { itemId } = useParams();
    const cartItems: CartItemSummaryWithNameEmailTimeStamp[] = useSelector(SelectCartItemSummaryWithNameEmailTimeStamp);
    const navigate = useNavigate();
    const { user } = useAuthenticator((context) => [context.route]);
    const email = user.attributes?.email || '';
    // const cartItemSummary = email in cartItems ? cartItems[email].cartItemSummary.filter(cartItemSummary => itemId == cartItemSummary.foodItemId) : [];
    const cartItemSummary = [...cartItems.filter(cartItem => cartItem.foodItemId == itemId)];
    if (cartItemSummary.length == 0) {
        navigate(MENU_PAGE_ROOT_PATH);
    }
    if (cartItemSummary.length == 0) return <>loading...</>
    const name = cartItemSummary[0].name;

    return (
        <React.Fragment>
            <Stack padding={3} spacing={4} sx={{overflowY: 'scroll'}}>

                <CustomizeBoldTitle variant='h2' gutterBottom={true}>Choose the item to remove</CustomizeBoldTitle>
                <CustomizePageTitle>{cartItemSummary[0].foodItemName}</CustomizePageTitle>
                <Box padding={2} paddingBottom={3} sx={{border: '1px solid #E4E3E5', borderRadius: '20px'}}>
                    <ItemSummarySection cartItemSummaryWithNameEmailTimeStamp={cartItemSummary} dontShow={true}></ItemSummarySection>
                </Box>
            </Stack>
        </React.Fragment>
    )
}