// hooks/useItemsQuery.ts
import { useQuery } from 'react-query';
import axios from 'axios';
import { OfferCard } from '../interfaces/offers';

const fetchItems = async (): Promise<OfferCard[]> => {
  const response =  await axios.get('/src/data/sampleOffers.json');
  if (!response.data) {
    throw new Error('Network response was not ok');
  }
  console.log(typeof response.data)
  return response.data;
};

export const useOffersQuery = () => {
  return useQuery<OfferCard[], Error>('offers', fetchItems);
}

// export default{}