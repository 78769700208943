import { Authenticator } from "@aws-amplify/ui-react";
import { Route, Routes } from "react-router-dom";
import DineInLogin from "./components/dinein/pages/login/DineInLogin";

import RequireAuth from './components/common/RequireAuth';
import DineInMenu from './components/dinein/pages/DineInMenu';
import BottomDrawer from './components/dinein/pages/common/DineInItemDetailAddDrawer';
import FoodCustomizationForm from './components/dinein/pages/common/FoodCustomizationForm';
import ItemDetailPage from './components/dinein/pages/common/ItemDetailPage';
import { DineInLandingPage } from './components/dinein/pages/landing/DineInLandingPage';
import { DineInOfferItemDetailPage } from './components/dinein/pages/landing/DineInOfferItemDetailPage';
import { DineInDeleteCustomizationPage } from './components/dinein/pages/menu/DineInDeleteCustomizationPage';
import DineInMenuPage from './components/dinein/pages/menu/DineInMenuPage';
import { DineInProfilePage } from './components/dinein/pages/profile/DineInProfilePage';

import { useEffect } from "react";
import { NotFoundPage } from "./components/common/NotFoundPage";
import { DineInUserProfileOptions } from "./components/dinein/pages/profile/DineInUserProfileOptions";
import { UserFavourites } from "./components/dinein/pages/profile/Favourites";
import { CustomerForm } from "./components/dinein/pages/profile/ProfileFormComponent";
import PlansPage from './components/rootDomain/PlansPage';
import useCookie from "./hooks/useCookie";
import { useURLQuery } from "./hooks/useURLQuery";
import { getStoreName } from "./utils/storeUtils";
import { DineInCheckoutPage } from "./components/dinein/pages/checkout/DineInCheckoutPage";
import { UserProfileForm } from "./components/dinein/pages/profile/UserProfileForm";
import WebSocketComponent from "./components/common/WebsSocketComponent";
import { StripeContextProvider } from "./contexts/StripeContext";
import { CheckoutCartItems } from "./components/dinein/pages/checkout/CheckoutCartItems";
import CheckoutAndPay from "./components/dinein/pages/checkout/CheckoutAndPay";
import PaymentStatus from "./components/dinein/pages/checkout/PaymentStatus";




export const DineInApp = () => {
    // updateStoreAndTableDetails
    const query = useURLQuery();
    const { setCookie, getCookie } = useCookie();



	const storeId = query.get('storeId');
	const tableId = query.get('tableId');
	const tableNumber = query.get('tableNumber');

    const restaurant_prefix = getStoreName();

	// const { setSnackbarMessage } = useSnackbarContext();
	// const cartItemResponseState = useSelector(selectResponseState)
	// const dispatch = useDispatch<AppDispatch>()
	// const { data: cartSessionInfo, isLoading: isCartSessionLoading } = useCartSession();
	// // const { getCookie } = useCookie();
	// useEffect(() => {
	// 	const store_id_from_cookie = getCookie('storeId') || "";
	// 	const table_id_from_cookie = getCookie('tableId') || "";

	// 	if (store_id_from_cookie && table_id_from_cookie && cartSessionInfo && cartSessionInfo.cart_session_id) {
    //         dispatch(loadCartItems({ cart_session_id: cartSessionInfo.cart_session_id, table_id: table_id_from_cookie, store_id: store_id_from_cookie }));
    //     }
	// }, [isCartSessionLoading, getCookie]);

	// useEffect(() => {
	// 	if (cartItemResponseState.message != '') {
	// 		setSnackbarMessage({ key: uuidv4(), message: cartItemResponseState.message, type: cartItemResponseState.type })
	// 	}
	// }, [cartItemResponseState])
	

    useEffect(() => {

        if (storeId) {
            setCookie('storeId', storeId);
        }

        if (tableId) {
            setCookie('tableId', tableId);
        }

		if (tableNumber) {
            setCookie('tableNumber', tableNumber);
        }

        if (restaurant_prefix) {
            setCookie('restaurant_prefix', restaurant_prefix);
        }
    }, [query, setCookie, restaurant_prefix, storeId, tableId]);

	return (

		<Authenticator.Provider>
			<StripeContextProvider>
				<Routes>
					{/* <Route path="/logout" element={<DineInLogout/>} /> */}
					{/* <Route path="/test" element={<VegNonVegFilter/>} /> */}
					<Route path="/login" element={<DineInLogin/>} />
					<Route path= "/" element={<RequireAuth/>}>
						<Route path="/plans" element={<PlansPage/>} />

						<Route path="hello" element={<DineInMenu/>} />
						<Route path= "" element={<DineInLandingPage/>}/>
						<Route path= "offerDetail/:id" element={<DineInOfferItemDetailPage/>}/>
						<Route path= "menu" element={<DineInMenuPage/>}>
							<Route path="itemDetail" element={<BottomDrawer isOpen={true}/>}>
								<Route path=":id" element={<ItemDetailPage/>}/>
							</Route>
							<Route path="customize" element={<BottomDrawer isOpen={true}/>}>
								<Route path="item/:itemId/step/:stepId" element={<FoodCustomizationForm/>}/>
								<Route path="item/:itemId/step/:stepId/zoom/:choiceId" element={<FoodCustomizationForm/>}/>
								<Route path="delete/:itemId" element={<DineInDeleteCustomizationPage/>}/>
							</Route>
						</Route>
						<Route path= "profile" element={<DineInProfilePage/>}>
							<Route index element={<DineInUserProfileOptions/>}/>
							<Route path="favourites" element={<UserFavourites/>}/>
							<Route path="info" element={<UserProfileForm/>}/>
						</Route>
						<Route path= "checkout" element={<DineInCheckoutPage/>}>
							<Route index element={<CheckoutCartItems/>}/>
							<Route path='pay' element={<CheckoutAndPay/>}/>
							<Route path='paymentStatus' element={<PaymentStatus/>}/>
						</Route>
						<Route path="*" element={<NotFoundPage/>} />
					</Route>
				</Routes>
				<WebSocketComponent/>
			</StripeContextProvider>
		</Authenticator.Provider>

	)
}