import { useMemo, useState } from "react";
import { useMenuItemsQuery } from "./useMenuItemsQuery";
import { DishCategory, FoodMenuItem } from "../interfaces/FoodItem";
import { MenuPageCardSection } from "../interfaces/MenuPageCardSection";
import { getSectionId } from "../utils/FoodItemUtils";
import { Section } from "../components/dinein/pages/common/ScrollSpy";

export const useMenuPageFoodItemsWithSearchAndFilters = () => {
    const { data: items, isLoading, error } = useMenuItemsQuery();
    const [filterText, setFilterText] = useState<string>("");
    const [foodCategoryFilter, setFoodCategoryFilter] = useState<DishCategory[]>([]);
    const { filteredItems, defaultFilter, sectionTitleAndSectionIdMap, menuPageCardSection, sections} = useMemo( () => {
        console.log("evaluating use memo");
        const filteredItems: FoodMenuItem[] | undefined = filterFoodItemsBasedOnTextAndCategory(items, filterText, foodCategoryFilter);
        const defaultFilter = getFoodCategoryFilter(items);
        // setFoodCategoryFilter(getFoodCategoryFilter(filteredItems))
        const sectionTitleAndSectionIdMap = filteredItems ? getSectionTitleAndSectionIdMap(filteredItems) : new Map();
        const menuPageCardSection = filteredItems ? getFoodItemsBySection(filteredItems): [];
        const sections = getSections(sectionTitleAndSectionIdMap);
        return {sections, filteredItems, sectionTitleAndSectionIdMap, menuPageCardSection, defaultFilter};
    }, [filterText, items, foodCategoryFilter]);

    return { isLoading, error, filteredItems, sections, sectionTitleAndSectionIdMap, menuPageCardSection, filterText, setFilterText, foodCategoryFilter, setFoodCategoryFilter, defaultFilter }
}

export const getFoodCategoryFilter = (items: FoodMenuItem[] | undefined) : DishCategory[] => {
    console.log("dasdasdasdasdasdasdadasdasdas")
    console.log(typeof items);
    console.log(items)
    if (!items) return []
    return items ? Array.from(new Set(items.map(item => item.dishType))): [];
}

const getSections = (sectionMap: Map<string, string> ) : Section[] => {
    const sections: Section[] = []
    sectionMap.forEach((v, k)=> {
        sections.push({id: `#${v}`, title: k});
    })
    return sections;
}

const filterFoodItemsBasedOnTextAndCategory = (items: FoodMenuItem[] | undefined, filterText: string, foodCategoryFilter: DishCategory[]): FoodMenuItem[] | undefined => {
    if (!items || items.length == 0) return items;
    if (filterText.length == 0 && foodCategoryFilter.length == 0) return items;
    if (foodCategoryFilter.length == 0) return showItemBasedOnSearchText(items, filterText);
    if (filterText.length == 0) return showItemsBasedOnDishCategory(items, foodCategoryFilter);
    const filteredItems: FoodMenuItem[] = showItemBasedOnSearchText(showItemsBasedOnDishCategory(items, foodCategoryFilter), filterText); 
    return filteredItems
}

const showItemsBasedOnDishCategory = (foodItems: FoodMenuItem[], dishCategoryChosen: DishCategory[]) : FoodMenuItem[] => {
    if (dishCategoryChosen.length == 0) return foodItems;
    return foodItems.filter(item => dishCategoryChosen.indexOf(item.dishType) >= 0);
}

const showItemBasedOnSearchText = (foodItems: FoodMenuItem[], searchText: string) : FoodMenuItem[] => {
    if (!searchText || !searchText.length) return foodItems;
    const text = searchText.toLowerCase().trim();
    return foodItems.filter(item => item.dishName.toLowerCase().indexOf(text) >= 0 || item.dishDescription.toLowerCase().indexOf(text) >= 0)
}

const getSectionTitleAndSectionIdMap = (foodItems: FoodMenuItem[]): Map<string, string> => {
    console.log("running getSectionTitleAndSectionIdMap")
    const sectionsTitleToIdMap: Map<string, string> = new Map<string, string>();
    if (!foodItems) return sectionsTitleToIdMap;
    for (const foodItem of Object.values(foodItems)) {
      for (const section of foodItem.sections) {
        sectionsTitleToIdMap.set(section, getSectionId(section));
      }
    }
    return sectionsTitleToIdMap;
  }
  
  const getFoodItemsBySection = (foodItems: FoodMenuItem[]): MenuPageCardSection[] => {
    console.log("running getFoodItemsBySection")
    const sections: MenuPageCardSection[] = [];
    if (!foodItems) return sections;
  
    const sectionsMap: Map<string, MenuPageCardSection> = new Map<string, MenuPageCardSection>();
    for (const foodItem of Object.values(foodItems)) {
        for (const section of foodItem.sections) {
            if (!sectionsMap.has(section)) {
                const foodItemSection: MenuPageCardSection = {title: section, titleId: getSectionId(section), foodItems: []}
                sectionsMap.set(section, foodItemSection);
            }
            sectionsMap.get(section)!.foodItems.push(foodItem);
        }
    }
        // Convert the grouped sections to the desired interface format
    sectionsMap.forEach( (k: MenuPageCardSection, v: string) => (sections.push({
        title: k.title,
        titleId: k.titleId,
        foodItems: k.foodItems,
    })));
    return sections;
  }