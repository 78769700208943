import { useLocation } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/BreadCrumbsComponent";
import ListOperations from "../../../components/ListOperations"

export const RestaurantOperations = () => {

    const restaurantOperations = [
        { name: "List Restaurants", link: "/restaurants/list" },
        { name: "Create New Restaurant", link: "/restaurants/create" },
        // more restaurant operations...
    ];
    
    return (
        <ListOperations title="Restaurants" operations={restaurantOperations} />
    )
}