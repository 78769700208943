import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText, SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import { StoreFormData } from '../hooks/useStoreForm';
import { useFetchUserRestaurants } from '../hooks/useFetchUserRestaurants';

interface RestaurantInfo {
    restaurant_id: string;
    restaurant_name: string;
}

export interface RestaurantDropdownComponentProps {
    disabled: boolean;
}
const RestaurantDropdownComponent: React.FC<RestaurantDropdownComponentProps> = ({disabled}) => {
    const { values, touched, errors, setFieldValue } = useFormikContext<StoreFormData>();
    const { data: restaurantsData, isLoading } = useFetchUserRestaurants();
    const [restaurants, setRestaurants] = useState<RestaurantInfo[]>([]);

    useEffect(() => {
        if (restaurantsData) {
            const formattedRestaurants: RestaurantInfo[] = restaurantsData.map((restaurant: any) => ({
                restaurant_id: restaurant.restaurant_id,
                restaurant_name: restaurant.restaurant_name
            }));
            setRestaurants(formattedRestaurants);
        }
    }, [restaurantsData]);

    useEffect(() => {
        if (disabled) {
            setRestaurants([{restaurant_id: values.store_restaurant_id, restaurant_name: values.store_restaurant_name}]);
        }
    }, [values])

    const handleRestaurantChange = (event: SelectChangeEvent) => {
        const selectedRestaurant = restaurants.find(restaurant => restaurant.restaurant_id === event.target.value as string);
        if (selectedRestaurant) {
            setFieldValue('store_restaurant_id', selectedRestaurant.restaurant_id);
            setFieldValue('store_restaurant_name', selectedRestaurant.restaurant_name);
        }
    };

    return (
        <FormControl fullWidth error={touched.store_restaurant_id && Boolean(errors.store_restaurant_id)}>
            <InputLabel id="restaurant-select-label">Restaurant</InputLabel>
            <Select
                labelId="restaurant-select-label"
                label="Restaurant"
                name="store_restaurant_id"
                value={values.store_restaurant_id}
                onChange={handleRestaurantChange}
                disabled={disabled}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {!isLoading && restaurants.map((restaurant) => (
                    <MenuItem key={restaurant.restaurant_id} value={restaurant.restaurant_id}>
                        {restaurant.restaurant_name}
                    </MenuItem>
                ))}
            </Select>
            {touched.store_restaurant_id && errors.store_restaurant_id && (
                <FormHelperText>{errors.store_restaurant_id}</FormHelperText>
            )}
        </FormControl>
    );
};

export default RestaurantDropdownComponent;
