import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify'
import useCookie from "../../hooks/useCookie";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { LoaderPage } from '../../components/rootDomain/LoderPage';
import { useNavigate } from 'react-router-dom';

interface AuthEvent {
    payload: {
        event: string;
        data?: any;
    };
}

export const OauthLogin: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { getCookie } = useCookie();
    const navigate = useNavigate();

    useEffect(() => {
        // Initiate federated sign-in with Google
        const initiateGoogleSignIn = () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const store = urlParams.get('store');

            Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
                customState: JSON.stringify(store)
            });
        };

        // Check user authentication status
        const checkUserAuthentication = async () => {
            try {
                await Auth.currentAuthenticatedUser();
                setLoading(false);
            } catch (error) {
                console.error('Error during user authentication:', error);
                initiateGoogleSignIn(); // Initiate sign-in if user is not authenticated
            }
        };

        checkUserAuthentication();

        // Hub listener for authentication events
        const listener = (eventData: AuthEvent) => {
            const { event } = eventData.payload;
            switch (event) {
                case 'signIn':
                    setLoading(false);
                    break;
                // Handle other events if necessary
            }
        };

        Hub.listen('auth', listener);
        return () => Hub.remove('auth', listener);
    }, []);

    // Additional logic or redirects based on cookie values
    useEffect(() => {

        console.log(loading)
        if (!loading) {
            const storeName = getCookie('store');
            // Implement redirection or additional logic based on storeName
            navigate('/redirect');
        }
    }, [loading, getCookie]);

    return (
        <LoaderPage text={loading ? "Logging in..." : "Redirecting..."} />
    );
};
