import { SvgIconComponent } from "@mui/icons-material";
import BadgeIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import SyncIcon from '@mui/icons-material/Sync';
import TableBarIcon from '@mui/icons-material/TableBar';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';


export interface SidebarProps {
    title: string;
    icon: SvgIconComponent;
    link: string;
    color?: string;
    coreTeam?: boolean;
    isLogout?: boolean;
}

export const profileProps: SidebarProps[] = [
    {
        title: 'Profile', 
        icon: PersonIcon,
        link: '/profile'
    }, 
    {
        title: 'logout',
        icon: LogoutIcon,
        color: 'red',
        link: '/logout',
        isLogout: true
    },
]

export const dashboardProps: SidebarProps[] = [
    {
        title: 'Core Team(Menufy users only)', 
        icon: Diversity3RoundedIcon,
        link: '/coreTeam',
        coreTeam: true
    },
    {
        title: 'Home', 
        icon: HomeIcon,
        link: '/'
    },
    {
        title: 'Dashboard',
        link: '/billing',
        icon: DashboardIcon
    },
    {
        title: 'Analytics',
        link: '/analytics',
        icon: BarChartIcon
    },
    {
        title: 'Billing',
        link: '/billings',
        icon: PaidIcon
    },
    {
        title: 'Orders',
        link: '/orders',
        icon: ReceiptIcon
    },
    {
        title: 'Tables',
        link: '/tables',
        icon: TableBarIcon
    },
    {
        title: 'Restaurants',
        link: '/restaurants',
        icon: BusinessIcon
    },
    {
        title: 'Stores',
        link: '/stores',
        icon: StorefrontIcon
    },
    {
        title: 'Digital Menu',
        link: '/menu',
        icon: MenuBookIcon
    },
    {
        title: 'My Colleagues',
        link: '/users',
        icon: BadgeIcon
    },
    {
        title: 'Customers',
        link: '/customers',
        icon: GroupIcon
    },
    {
        title: 'Loyalty',
        link: '/loyalty',
        icon: LoyaltyIcon
    },
    {
        title: 'My Subscriptions',
        link: '/plans',
        icon: SyncIcon
    },
    {
        title: 'Payments',
        link: '/payments',
        icon: PaymentsIcon
    },
    
]