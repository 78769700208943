import Auth from '@aws-amplify/auth';
import { AmplifyUser } from '@aws-amplify/ui';
import { Heading, useAuthenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { Link,  useSearchParams, useNavigate } from 'react-router-dom';

export default function DineInMenu() {
	const { route, user } = useAuthenticator((context) => [context.route]);

  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Sign in with Cognito
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        console.log(user.signInUserSession.accessToken)
        console.log(`Bearer ${token}`);
        const response = await fetch('https://api.menufy.link/getStore/asdad', {
          headers: {
            Authorization: `Bearer ${token}`
          },
          method: 'GET',
          mode: 'cors'
        });
        console.log(response)
        if (response.ok) {
          const data = await response.json();
          console.log('got data')
          console.log(data);
          setMessage(data.name);
        } else {
          // Handle error response (optional)
          setMessage('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  
    return (
      <div>
        <h4>welcome { user ? user.attributes?.email: "guest"}</h4>
        <button onClick={()=>{ Auth.signOut();}}> signout</button>
        <Link to="/login"><button>move to login page</button></Link>
        <div>{message}</div>
      </div>
  );
}
  