import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Typography, Grid } from '@mui/material';

// Define the type for the plan based on the provided structure
type Plan = {
  id: string;
  title: string;
  price: number;
  max_staff: number;
  max_stores: number;
  max_restaurants: number;
  whatsapp_updates: boolean;
  whatsapp_promotions: boolean;
  free_promotions: number;
  max_whatsapp_updates: number;
  currency: string;
};

// Function to fetch plans from a backend
const fetchPricingPlans = async (): Promise<Plan[]> => {
  // Replace with your actual API call
//   const response = await fetch('/api/pricing');
//   if (!response.ok) {
//     throw new Error('Network response was not ok');
//   }
//   return response.json();

return Promise.resolve([
    {
      id: 'basic',
      title: 'Basic Plan',
      price: 9.99,
      max_staff: 5,
      max_stores: 10,
      max_restaurants: 1,
      whatsapp_updates: false,
      whatsapp_promotions: false,
      free_promotions: 0,
      max_whatsapp_updates: 100,
      currency: '$',
    },
    {
        id: 'basic',
        title: 'platinum Plan',
        price: 99.9,
        max_staff: 50,
        max_stores: 1,
        max_restaurants: 1,
        whatsapp_updates: true,
        whatsapp_promotions: false,
        free_promotions: 0,
        max_whatsapp_updates: 100,
        currency: '$',
      },
      {
        id: 'basic',
        title: 'silver plan',
        price: 999.9,
        max_staff: 500,
        max_stores: 1,
        max_restaurants: 1,
        whatsapp_updates: true,
        whatsapp_promotions: true,
        free_promotions: 0,
        max_whatsapp_updates: 100,
        currency: '$',
      },
    // ... other plans
  ]);

};

const PlansPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: plans, isLoading, isError } = useQuery('pricingPlans', fetchPricingPlans);

  const handleSelectPlan = (planId: string) => {
    // Redirect to the payment page with the selected plan's ID
    navigate(`/payment/${planId}`);
  };

  const formatPrice = (price: number, currency: string): string => `${currency}${price.toFixed(2)} / mo`;

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching plans</div>;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h2" align="center">Choose Your Plan</Typography>
      </Grid>
      {plans?.map((plan) => (
        <Grid item xs={12} md={4} key={plan.id}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">{plan.title}</Typography>
              <Typography variant="h6">{formatPrice(plan.price, plan.currency)}</Typography>
              {/* Display other plan details as needed */}
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={() => handleSelectPlan(plan.id)} fullWidth>
                Select Plan
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlansPage;
