import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack } from "@mui/material"
import { useField } from "formik";
import { useState } from "react";

export interface TagsSectionProps {
    index: number
}


export const TagsSection: React.FC<TagsSectionProps> = ( { index} ) => {
    const [dishSections, , dishSectionsHelpers] = useField(`foodMenuItemsInputs.${index}.sections`);
    const [categorySections, ,] = useField(`categories`)

    const [chosenCategories, setChosenCategories] = useState<string[]>(categorySections.value.map((v:any) => v.name));

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        const updatedChoice =  typeof value === 'string' ? value.split(',') : value;
        dishSectionsHelpers.setValue(updatedChoice);
        setChosenCategories(updatedChoice);
    };

    return (
        <Stack padding={2}>
            <Stack spacing={2}>
                    <InputLabel id="categoryTags">Category tags</InputLabel>
                    <Select
                        variant="standard"
                        labelId="categoryTags"
                        id="categoryTagsSelect"
                        multiple
                        value={dishSections.value}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {dishSections.value.map((value: any) => (
                                <Chip key={value} label={value} />
                            )) }
                            </Box>
                        )}
                        >
                            {categorySections.value.map((category: any) => (
                                <MenuItem
                                key={category.id}
                                value={category.name}
                                >
                                    {category.name}
                                </MenuItem>
                            ))}
                    </Select>
            </Stack>
            
        </Stack>
    )
}