import { Step } from "@mui/material";
import { CartItem } from "../redux/cartItems/cartItemsSlice";
import { FoodMenuItem } from "../interfaces/FoodItem";

export const calculateTotalSum = (values: string[]): string => {
    if (values.length === 0) {
        return 'No values provided';
    }

    let currencySymbol: string | undefined;
    let total = 0;
  
    for (const value of values) {
      const matches = value.match(/([A-Z]+) (\d+\.\d{2}|\d+)/);
      if (matches) {
        if (!currencySymbol) {
          currencySymbol = matches[1];
        } else if (currencySymbol !== matches[1]) {
          return 'Mismatched currency symbols in the array';
        }
  
        total += parseFloat(matches[2]);
      }
    }
  
    if (currencySymbol) {
      return `${currencySymbol} ${total.toFixed(2)}`;
    } else {
      return 'No valid values with a currency symbol found';
    }
};

export const parseValueFromString = (input: string): number => {
  // Regular expression to find a number, including decimal points
  const regex = /(\d+(\.\d+)?)/;
  const match = input.match(regex);

  // If a match is found, convert it to a number and return
  if (match) {
    return parseFloat(match[0]);
  }

  // Return null if no number is found
  return 0;
}

export const getTotalSumInNumberAndString = (cartItem: CartItem | FoodMenuItem) : [number, string]=> {
  const prices = [cartItem.dishPriceText]
  cartItem.customizeOptions?.forEach(step => step.stepOptions.forEach(choice => choice.items.forEach(item => prices.push(item.price)) ))
  const priceInText: string = calculateTotalSum(prices);
  return [parseFloat(priceInText.split(' ')[1]), priceInText]
}