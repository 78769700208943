import React from 'react';
import { Box, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import ConfirmDeleteComponent from '../../../components/ConfirmDeleteComponent';

interface ActionButtonsComponentProps {
    isUpdating: boolean;
    isImageUploading: boolean;
    isCreateOperation: boolean;
    isDeleting: boolean;
    deleteRestaurant: () => void;
    restaurantName: string;
}

const ActionButtonsComponent: React.FC<ActionButtonsComponentProps> = ({
    isUpdating,
    isImageUploading,
    isCreateOperation,
    isDeleting,
    deleteRestaurant,
    restaurantName
}) => {
    const { isValid, isSubmitting } = useFormikContext<any>();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button variant="contained" type="submit" disabled={!isValid || isUpdating || isImageUploading || isSubmitting} sx={{height: 'fit-content'}} >
                {isUpdating ? "Saving.." : "Save Changes"}
            </Button>
            {!isCreateOperation && (
                <ConfirmDeleteComponent
                    onConfirmDelete={deleteRestaurant}
                    confirmationText={restaurantName}
                    isDeleting={isDeleting || isSubmitting}
                />
            )}
        </Box>
    );
};

export default ActionButtonsComponent;
