import { Container, Divider, Stack, Typography } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { Outlet, useLocation } from "react-router-dom";
import { SidebarList } from "./components/SidebarList";
import { dashboardProps, profileProps } from "./configs/SideBarOptions";
import BreadcrumbsComponent from "./components/BreadCrumbsComponent";
import { BreadcrumbProvider } from "./contexts/BreadcrumbContext";


export const RestaurantAdminHomePage = () => {
    
    const location = useLocation();
    
    const drawerWidth = 240;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                    Admin Portal
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
            >
            <Toolbar />
            <Stack sx={{ overflow: 'auto' }} divider={<Divider />}>
                <SidebarList sideBarList={dashboardProps}/>
                <SidebarList sideBarList={profileProps}/>
            </Stack>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <Container>
                <BreadcrumbProvider>
                    {location.pathname != '/' && <BreadcrumbsComponent currentLocation={location.pathname} />}
                    <Outlet/>
                </BreadcrumbProvider>
            </Container>
            </Box>
        </Box>   
    )
}