import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { NavLink } from 'react-router-dom';

export interface Operation {
    name: string;
    link: string;
}

export interface ListOperationsProps {
    title: string;
    operations: Operation[];
}

const ListOperations: React.FC<ListOperationsProps> = ({ title, operations }) => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                {title}
            </Typography>
            <Grid container spacing={2}>
                {operations.map((operation) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={operation.name}>
                        <NavLink 
                            to={operation.link} 
                            style={{ textDecoration: 'none' }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 200, // Adjust the height as needed
                                    border: '1px solid grey',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'lightgrey',
                                    },
                                }}
                            >
                                <Typography variant="h6">{operation.name}</Typography>
                            </Box>
                        </NavLink>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ListOperations;
