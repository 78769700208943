import { useBaseMutation } from "../../../../hooks/useBaseMutation";


export const usePutRestaurantInfo = () => {
    
    return useBaseMutation(
        'put',
        'https://api.menufy.link/restaurantBackend/restaurants/info', // Endpoint
        // `restaurants`,
        // Data transformation function
    );
};
