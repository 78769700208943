import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { LoaderPage } from '../rootDomain/LoderPage';
import React from 'react';
import { FoodItemsContextProvider } from '../../contexts/FoodItemsContext';
import { Provider } from 'react-redux';
import { useDataLoader } from '../../hooks/useDataLoader';
// import store from '../../redux/stores/mainStore';


const RequireAuth = ():  JSX.Element  => {
  const location = window.location.href
  let {route} = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const isDataLoading = useDataLoader();
  const [dataLoading, setDataLoading] = useState(true);


  useEffect(() => {

      if (route === 'idle') {
        setLoading(true);
      } else setLoading(false);

      if(!isDataLoading) setDataLoading(false);
  }, [route, isDataLoading]);

  if (loading || dataLoading) {
    return (<React.Fragment><LoaderPage text="Getting user info."/></React.Fragment>);
  } else if (route === 'authenticated') {
        return (
                // <Provider store={store}>
                  <Outlet/>
                // </Provider>
        )
  } else {
    return <React.Fragment><Navigate to="/login" state={{ from: location }} /></React.Fragment>;
  }
}


export default RequireAuth;