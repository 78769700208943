import { useQuery } from 'react-query'
import axiosInstance from '../../../../common/axiosInstance';

const getStoreTablesInfo = async (storeId: string) => {
    const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/stores/tables?storeId=${storeId}`); // Use the custom axios instance
    return response.data;
};

export const useFetchStoreTables = (storeId: string) => {
    return useQuery(['storeTables', storeId], () => getStoreTablesInfo(storeId));
};
