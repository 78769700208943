import { Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCartSession } from "../../../../hooks/useCartSession";
import useCookie from "../../../../hooks/useCookie";
import { usePlaceCartItemsOrders } from "../../../../hooks/usePlaceCartItemsOrder";
import { SelectCartItemSummaryWithNameEmailTimeStamp, SelectCartItems } from '../../../../redux/cartItems/cartItemsSelectors';
import { resetCartItems } from "../../../../redux/cartItems/cartItemsSlice";
import { SelectCartItemOrderSummaryWithNameEmailTimeStamp } from "../../../../redux/cartOrder/CartOrderSelectors";
import { moveCartItemsToOrder } from "../../../../redux/cartOrder/CartOrderSlice";
import { LoaderPage } from "../../../rootDomain/LoderPage";
import { EmptyCart } from "./EmptyCart";
import { ItemSummarySection } from "./ItemSummarySection";
import { ItemsOrderedSummarySection } from "./ItemsOrderedSummarySection";
import { useFetchStoreStripeAccountId } from "./hooks/useFetchStoreStripeAccountId";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";


export const CheckoutCartItems: React.FC = () => {
    const cartItems = useSelector(SelectCartItems);
    const dispatch = useDispatch();
    const cartItemSummaryWithNameEmailTimeStamp = useSelector(SelectCartItemSummaryWithNameEmailTimeStamp);
    const cartItemOrderSummaryWithNameEmailTimeStamp = useSelector(SelectCartItemOrderSummaryWithNameEmailTimeStamp);
    const {mutate: placeCartItems, isSubmitting} = usePlaceCartItemsOrders();
    const {data: cartSessionInfo, isLoading: isCartSessionInfoLoading} = useCartSession();

    const { getCookie } = useCookie();
    const storeId = getCookie('storeId') || "";
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const goToPayPage = () => {
        navigate('pay'); // Relative navigation to /checkout/pay
    };

   
    // const {data:cartOrder, isLoading: isCartOrderLoading} = useCartSessionOrder(true, cartSessionInfo?.cart_session_id || "");

    const isCartEmptyAndNoOrderPlaced = (): boolean => {
        return cartItemSummaryWithNameEmailTimeStamp.length == 0 && cartItemOrderSummaryWithNameEmailTimeStamp.length == 0
    }
   

    const placeOrder = () => {
        const payLoad = {
            store_id: cartSessionInfo?.store_id,
            cart_session_id: cartSessionInfo?.cart_session_id,
            table_id: cartSessionInfo?.table_id,
            table_number: getCookie("tableNumber")
        }
        dispatch(moveCartItemsToOrder({cartItems}))
        dispatch(resetCartItems({}))
        placeCartItems({variables: payLoad, key: ['cartOrder'], invalidate: true});
        queryClient.invalidateQueries(['intent', storeId])
    }
    
    if (isCartSessionInfoLoading) return <LoaderPage text="loading cart.."/>
    
    return (
        <Stack spacing={1} padding={3}>
            { isCartEmptyAndNoOrderPlaced() ? (<EmptyCart/>) : (
                    <Stack spacing={4}>
                        {cartItemSummaryWithNameEmailTimeStamp && cartItemSummaryWithNameEmailTimeStamp.length > 0 && 
                            <Stack spacing={3}>
                                <Stack spacing={1} padding={2} style={{'backgroundColor': '#F9DEDC', 'borderRadius': '30px'}}>
                                {/* {
                                    Object.entries(cartItems).map(([email, cartItemDetails])=> <ItemSummarySection email={email} cartItemDetails={cartItemDetails} cartItemSummaryWithNameEmailTimeStamp={cartItemSummaryWithNameEmailTimeStamp} /> )
                                        
                                } */}
                                    <ItemSummarySection cartItemSummaryWithNameEmailTimeStamp={cartItemSummaryWithNameEmailTimeStamp} />
                                </Stack>

                                <Button sx={{ height: '50px', padding: '10px 24px', borderRadius: '25px', width: '100%',
                                                        backgroundColor: '#6750A4', color: 'white', textTransform: 'none', ':hover': {backgroundColor: '#6750A4', color: 'white'} }} onClick={placeOrder} disabled={isSubmitting}>Lock Cart and Place Order</Button>
                            </Stack>
                        }
                        {/* <Stack spacing={1}> */}
                            {
                                cartItemOrderSummaryWithNameEmailTimeStamp && cartItemOrderSummaryWithNameEmailTimeStamp.length > 0 &&
                                    <Stack spacing={3}>
                                        {/* <LockCartAndPlaceOrder/>
                                    <BillAndSummary/>
                                    <PayAndCheckout/> */}
                                        <ItemsOrderedSummarySection cartItemSummaryWithNameEmailTimeStamp={cartItemOrderSummaryWithNameEmailTimeStamp} />
                                        
                                        <Button sx={{ height: '50px', padding: '10px 24px', borderRadius: '25px', width: '100%',
                                                    backgroundColor: '#6750A4', color: 'white', textTransform: 'none', ':hover': {backgroundColor: '#6750A4', color: 'white'} }} disabled={isSubmitting} onClick={goToPayPage}>Checkout and Pay</Button>
                                       
                                    </Stack>
                                
                            }
                        </Stack>
            )}
        </Stack>
    );
}