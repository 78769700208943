import { useMemo } from "react";
import { FoodMenuItem } from "../interfaces/FoodItem";
import { useMenuItemsQuery } from "./useMenuItemsQuery";

export const useFoodItemsByOfferId = (id: string) => {
    const { data: items, isLoading, error } = useMenuItemsQuery();
    const filteredItemsByOfferId = useMemo(() =>{
        return items?.filter(item => item.homePageOffers?.filter(offerId => offerId == id)?.length) || []
    },[items, id]);
    return {filteredItemsByOfferId, isLoading, error};
}