import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerFavorite } from "./CustomerFavouritesSlice";
import axiosInstance from "../../components/common/axiosInstance";

export const addFavorite = createAsyncThunk(
    'customerFavorites/addFavorite',
    async (favoriteItem: CustomerFavorite, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put('https://api.menufy.link/dineIn/customers/favourites', favoriteItem);
            return response.data;
        } catch (error) {
            return rejectWithValue(favoriteItem);
        }
    }
);

export const removeFavorite = createAsyncThunk(
    'customerFavorites/removeFavorite',
    async (favoriteItem: CustomerFavorite, { rejectWithValue }) => {
        try {

            const backendPayload = {
                store_id: favoriteItem.store_id,
                dish_item_id: favoriteItem.dish_item_id,
            }

            await axiosInstance.delete(`https://api.menufy.link/dineIn/customers/favourites`, { data: backendPayload });
            return favoriteItem.dish_item_id;
        } catch (error) {
            return rejectWithValue(favoriteItem);
        }
    }
);

export const fetchAllFavorites = createAsyncThunk(
    'customerFavorites/fetchAll',
    async (store_id: string, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get<CustomerFavorite[]>(`https://api.menufy.link/dineIn/customers/favourites?storeId=${store_id}`);
        return response.data;
      } catch (error) {
        // Assuming axios for HTTP request and error handling
        return rejectWithValue('An unknown error occurred');
      }
    }
  );