import React from 'react';
import { Stack } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';

export interface QRCodeProps {
    storeId?: string,
    tableId?: string,
    url?: string,
    text?: string,
    logo?: string,
    logoHeight?: number,
    logoWidth?: number,
    componentHeight?: number,
    componentWidth?: number
}

const QRCodeGenerator: React.FC<QRCodeProps> = ({ storeId, tableId, url, text, logo, logoHeight, logoWidth, componentHeight, componentWidth }) => {
    url = url || `${storeId}.menufy.link?tableId=${tableId}`;

    return (
        <Stack 
            spacing={1} 
            sx={{
                ...(componentHeight && { height: '300px' || componentHeight }),
                ...(componentWidth && { width: '300px' ||componentWidth })
            }}
        >
            {text && <h3>{text}</h3>}
            <QRCode 
            size={400}
                bgColor='#1976d2'
                fgColor='black'
                value={url} 
                logoImage={logo} 
                logoHeight={75|| logoHeight || 50} 
                logoWidth={75 ||logoWidth || 50} 
                removeQrCodeBehindLogo={true}
                enableCORS={true}
                eyeColor='black'
                eyeRadius={10}
                logoPaddingStyle='circle'
            />
        </Stack>
    );
};

export default QRCodeGenerator;
