import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddOnItem, CustomizationStep, StepOption } from '../../interfaces/FoodItem';
import { CartItem } from '../cartItems/cartItemsSlice';


interface CustomizationState {
	choices: Record<string, CustomizationStep[]>;
	seenPages: Record<string, string[]>;

}

const initialState: CustomizationState = {
	choices: {},
	seenPages: {}
};

const customizationSlice = createSlice({
	name: 'customization',
	initialState,
	reducers: {
		updateSelectedOption: (
			state,
			action: PayloadAction<{mainItemId: string, stepId: string; choice: StepOption, item: AddOnItem }>
		) => {
			const { mainItemId, stepId, choice, item } = action.payload;
			const existingMainItem = mainItemId in state.choices;//state.choices[itemId] && state.choices[itemId].length > 0; 
			if(!existingMainItem) {
				state.choices[mainItemId] = [];
			}
			const existingStep = state.choices[mainItemId].find((choice) => choice.step.toString() == stepId)
			if (!existingStep) {
				state.choices[mainItemId].push({step: Number(stepId), stepOptions: [{...choice, items: [{...item}]}]})
			} else {
				const existingChoice = existingStep.stepOptions.find(stepOption => stepOption.id == choice.id);
				if (!existingChoice) {
					existingStep.stepOptions.push({...choice, items: [{...item}]})
				} else {
					const existingItem = existingChoice.items.find((i) => i.id == item.id)
					if (!existingItem) {
						if (existingChoice.minSelect == 1 && existingChoice.maxSelect == 1 ) existingChoice.items.pop()
						existingChoice.items.push(item)
					} else {
						if (existingChoice.minSelect == 1 && existingChoice.maxSelect == 1) return
						existingChoice.items = existingChoice.items.filter((choiceItem) => {return choiceItem.id != item.id})
						if (existingChoice.items.length == 0) {
							existingStep.stepOptions = existingStep.stepOptions.filter(option => option.id != choice.id)
						}
						if (existingStep.stepOptions.length == 0) {
							state.choices[mainItemId] = state.choices[mainItemId].filter(stepOption => stepOption.step.toString() != stepId)
						}
						if (state.choices[mainItemId].length == 0) {
							delete state.choices[mainItemId]
						}
					}
				}
			}
		},
		resetSelection : (
			state,
			action: PayloadAction<{mainItemId: string}>
		) => {
			const { mainItemId } = action.payload;
			if (mainItemId in state.choices) delete state.choices[mainItemId]
			state.seenPages[mainItemId] = []
		},
		loadSelection: (
			state,
			action: PayloadAction<{mainItemId: string, cartItem: CartItem}>
		) => {
			const { mainItemId, cartItem } = action.payload;
			state.choices[mainItemId] = cartItem.customizeOptions!
			if (!(mainItemId in state.seenPages)) state.seenPages[mainItemId] = [];
			cartItem.customizeOptions!.forEach(step => state.seenPages[mainItemId].push(step.step.toString()));
		},
		removeCurrentPage: (state, action: PayloadAction<{mainItemId: string, stepId: string}>) => {
			const {mainItemId, stepId} = action.payload;
			if (!(mainItemId in state.seenPages)) state.seenPages[mainItemId] = [];
			state.seenPages[mainItemId] = state.seenPages[mainItemId].filter(seenPage => seenPage !== stepId);
		},
		addCurrentPage: (state, action: PayloadAction<{mainItemId: string, stepId: string}>) => {
			const {mainItemId, stepId} = action.payload;
			if (!(mainItemId in state.seenPages)) state.seenPages[mainItemId] = [];
			const isThere = state.seenPages[mainItemId].filter(seenPage => seenPage == stepId);
			if (isThere.length) return;
			state.seenPages[mainItemId].push(stepId)
		}
	}
});

export const { updateSelectedOption, removeCurrentPage, addCurrentPage, resetSelection, loadSelection } = customizationSlice.actions;

export default customizationSlice.reducer;
