import { useQuery } from "react-query";
import axiosInstance from "../../../../common/axiosInstance";
import useCookie from "../../../../../hooks/useCookie";

// Modify the hook to accept a restaurantId and adjust the query key accordingly
export const useFetchStripeIntent = (hasOrdered: boolean) => {
    const { getCookie } = useCookie();
    const storeId = getCookie('storeId');
    const cartSessionId = getCookie('cartSessionId');
    const tableId = getCookie('tableId');
    return useQuery(['intent', storeId], () => getStripeIntent(storeId, cartSessionId, tableId), {
        enabled: !!storeId && hasOrdered, // Fetch data only if restaurantId is present
        // staleTime: 0
    });
};

const getStripeIntent = async (storeId: string | null | undefined, cartSessionId: string | undefined, tableId: string | undefined) => {
    if (!storeId) {
        throw new Error("storeId is required");
    }
    const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/stores/stripeIntent?storeId=${storeId}&cartSessionId=${cartSessionId}&tableId=${tableId}`);
    return response.data;
};
