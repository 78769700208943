import { Avatar, Stack, Typography, styled } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AmplifyUser } from '@aws-amplify/ui';

const GrayTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#49454F',
    textAlign: 'left',
  }));

const BlackTitleText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left',
}));

const getInitials = (name: string) => {
    const names = name.split(" ")
    return `${names[0][0]}${names[1][0]}`.toUpperCase();
}
const getFirstName = (name: string) => {
    const names = name.split(" ")
    return `${names[0]}`
}
export const DineInProfilePageNavBar:React.FC<{ user: AmplifyUser }> = ({user}) => {
    const name = user.attributes ? user.attributes['given_name'] : "John Doe"
    const initials = getInitials(name)
    const fname = getFirstName(name);
    return (
        <Stack direction='row' padding={3} spacing={2}>
            <Avatar sx={{ backgroundColor: '#1C1C1E'}}>{initials}</Avatar>
            <Stack direction='column'>
                <GrayTitleText>Hey {fname}, lets check your</GrayTitleText>
                <BlackTitleText>Profile</BlackTitleText>
            </Stack>
        </Stack>
    )
}