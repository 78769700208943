// hooks/useItemsQuery.ts
import { useQuery } from 'react-query';
import axios from 'axios';
import { FoodMenuItem } from '../interfaces/FoodItem';
import useCookie from './useCookie';
import axiosInstance from '../components/common/axiosInstance';

const fetchItems = async (storeId: string): Promise<FoodMenuItem[]> => {
  const response = await axiosInstance.get(`https://api.menufy.link/restaurantBackend/stores/menuItems?storeId=${storeId}`);
  // console.log(response.data)
  if (!response.data) {
    throw new Error('Network response was not ok');
  }
  return response.data || [];
};

export const useMenuItemsQuery = (storeIdInput?: string) => {
  const { getCookie } = useCookie();
  const store_id_from_cookie = getCookie('storeId') || "";
  const store_id_picked = storeIdInput || store_id_from_cookie;
  return useQuery<FoodMenuItem[], Error>(['menuItems', store_id_picked], () => fetchItems(store_id_picked));
}
