import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addFavorite, fetchAllFavorites, removeFavorite } from "./CustomerFavouriteThunks";

export interface CustomerFavorite {
    updated_on?: string;
    dish_item_name: string;
    dish_item_id: string;
    dish_item_price: string;
    sections: string[];
    store_id: string;
}


export interface CustomerFavoriteState {
    favourites: CustomerFavorite[];
    loadedOnce: boolean;
    response: {
        message: string;
        type: 'success' | 'info' | 'warning' | 'error';
    };
}

const initialState: CustomerFavoriteState = {
    favourites: [],
    loadedOnce: false,
    response: {
        message: '',
        type: 'success',
    },
};

const customerFavoritesSlice = createSlice({
    name: 'customerFavorites',
    initialState,
    reducers: {
        // Optimistically add to favorites
        optimisticAddToFavorites(state, action: PayloadAction<CustomerFavorite>) {
            state.favourites.push(action.payload);
        },
        // Optimistically remove from favorites
        optimisticRemoveFromFavorites(state, action: PayloadAction<string>) {
            state.favourites = state.favourites.filter(item => item.dish_item_id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addFavorite.rejected, (state, action) => {
                if (!action.meta.arg) return;
                const favouriteItem = action.meta.arg;
                state.favourites = state.favourites.filter(item => item.dish_item_id !== favouriteItem.dish_item_id);
                state.response = { message: 'Failed to add favorite', type: 'error' };
            })
            .addCase(removeFavorite.rejected, (state, action) => {
                if (!action.meta.arg) return;
                const favouriteItem = action.meta.arg;
                state.favourites.push(favouriteItem);
                state.response = { message: 'Failed to remove favorite', type: 'error' };
            })
            .addCase(fetchAllFavorites.fulfilled, (state, action: PayloadAction<CustomerFavorite[]>) => {
                if (state.loadedOnce) return;
                state.favourites = action.payload;
                state.loadedOnce = true;
            })
    },
});

export const { optimisticAddToFavorites, optimisticRemoveFromFavorites } = customerFavoritesSlice.actions;
export default customerFavoritesSlice.reducer;