import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { NavLink, useLocation, Link as RouterLink } from 'react-router-dom';
import { useBreadcrumb } from '../contexts/BreadcrumbContext';

export interface BreadcrumbsComponentProps {
    currentLocation: string;
}

const capitalizeClass = {
    'text-transform':'capitalize'
}

const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = ({ currentLocation }) => {
    const location = useLocation();
    const { breadcrumbNames, hideBreadCrumbLabels } = useBreadcrumb();
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
    const breadCrumbLabelsToHide = hideBreadCrumbLabels[location.pathname] || [];
    useEffect(() => {
        const pathnames = location.pathname.split('/').filter(x => x);
        setBreadcrumbs(pathnames);
    }, [location]);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: '40px'}}>
      {breadcrumbs.map((value, index) => {
        const last = index === breadcrumbs.length - 1;
        const to = `/${breadcrumbs.slice(0, index + 1).join('/')}`;
        const label = breadcrumbNames[value] || value;
        if (breadCrumbLabelsToHide.includes(value)) return null;
        return last ? (
          <Typography color="text.primary" key={to} sx={capitalizeClass}>
            {label}
          </Typography>
        ) : (
          <Link component={RouterLink} to={to} key={to} sx={capitalizeClass}>
            {label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );

};

export default BreadcrumbsComponent;
