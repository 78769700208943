import { useFormikContext } from "formik"
import { CategoryFormValue, FoodMenuItemInput } from "../../hooks/useMenuForm";
import { Stack } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";

const RenderErrors = ({ errors }: { errors: any }) => {
    if (!errors) return null;
  
    return (
        <>
            {Object.entries(errors).map(([key, value]) => {
                if (typeof value === 'string') {
                // Direct field error
                    return <li key={key}>{key}: {value}</li>;
                } else if (Array.isArray(value)) {
                // Array of errors, could be direct array errors or nested object errors within the array
                    return value.map((itemError, index) => (
                    typeof itemError === 'string' ? (
                        <li key={`${key}-${index}`} style={{ listStyleType: 'disc' }}>{itemError}</li>
                    ) : (
                    // Increase level for nested errors
                        <RenderErrors key={`${key}-${index}`} errors={itemError} />
                    )
                ));
                } else if (typeof value === 'object') {
                // Nested object errors
                    return (
                        // Increase level for nested errors
                        <RenderErrors key={key} errors={value} />
                    );
                }
                return null;
            })}
        </>
    );
};



export const ErrorsSection: React.FC = () => {
    const { errors, values } = useFormikContext<{categories: CategoryFormValue[], foodMenuItemsInputs: FoodMenuItemInput[]}>();
    const { id } = useParams(); // Assuming `storeId` is a parameter in your route
    console.log(errors);
    return (
        <Stack sx={{background: '#ef5350'}} padding={2} spacing={3}>
            <h4>You have the following errors: click the links to view and fix</h4>
            <Stack padding={2} spacing={1}>
                {typeof errors.foodMenuItemsInputs === 'string' ? (
                    <li>{errors.foodMenuItemsInputs}</li>
                ) : values.foodMenuItemsInputs.map((item, index) => {
                    const itemErrors = errors.foodMenuItemsInputs?.[index];
                    if (itemErrors) {
                        const itemUrl = `/menu/stores/${id}/editItem/${item.id}`;
                        return (
                            <li key={index}>
                                <Link to={itemUrl} style={{ textDecoration: 'underline' }}>
                                    Error in "{item.dishName}"
                                </Link>
                                <ul style={{ listStyleType: 'disc' }}>
                                    <RenderErrors errors={itemErrors} />
                                </ul>
                                {/* <ul style={{ marginLeft: '20px' }}>
                                    {Object.entries(itemErrors).map(([field, error]) => (
                                        <li key={field} style={{ listStyleType: 'disc' }}>
                                            {field}: {error}
                                        </li>
                                    ))}
                                </ul> */}
                            </li>
                        );
                    }
                    return null; // Return null if there are no errors for this item
                })}
                { typeof errors.categories === 'string' ? (
                    <li>{errors.categories}</li>
                ) : values.categories.map((item, index) => {
                    const itemErrors = errors.categories?.[index];
                    if (!itemErrors) return null;
                    return (
                        <RenderErrors errors={itemErrors} />
                    )
                })}
            </Stack>
        </Stack>
    )
}