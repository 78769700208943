import { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbarContext } from '../../../contexts/SnackbarContext';
import { useFetchPresignedUrl } from './useGetPresignedUrl';
import { getFileExtension } from '../../../../common/fileUtils';

export const useImageUpload = () => {
    const [isUploading, setIsUploading] = useState(false);
    const { setSnackbarMessage } = useSnackbarContext();
    const { fetchPresignedUrl } = useFetchPresignedUrl();

    const uploadFile = async (file: File, validFileTypes: string[], maxFileSize: number) => {
        if (!validFileTypes.includes(file.type)) {
            setSnackbarMessage({ key: uuidv4(), message: 'Invalid file type', type: 'error' });
            return;
        }

        if (file.size > maxFileSize) {
            setSnackbarMessage({ key: uuidv4(), message: 'File size exceeds the limit', type: 'error' });
            return;
        }
        setIsUploading(true);

        try {
            const { uploadUrl, key, bucket, viewUrl } = await fetchPresignedUrl(file.type);
            await axios.put(uploadUrl, file, {
                headers: {
                    'Content-Type': file.type
                }
            });
            setSnackbarMessage({ key: uuidv4(), message: 'Image successfully uploaded', type: 'success' });
            return { uploadUrl, key, bucket, viewUrl };
        } catch (uploadError) {
            const errorMessage = uploadError instanceof Error ? uploadError.message : 'Failed to upload file';
            setSnackbarMessage({ key: uuidv4(), message: errorMessage, type: 'error' });
        } finally {
            setIsUploading(false);
        }
    };

    return { uploadFile, isUploading };
};
