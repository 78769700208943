import { useEffect } from "react";
import { LoaderPage } from "../../components/rootDomain/LoderPage";
import useCookie from "../../hooks/useCookie";

export const Logout = () => {
    const { getCookie } = useCookie();
    
    const storeId = getCookie('storeId');
    const tableId = getCookie('tableId');
    const tableNumber = getCookie('tableNumber');
    const restaurant_prefix = getCookie('restaurant_prefix') || 'admin';
    
    const getLogoutUrl = () => {
        let baseUrl = `${process.env.REACT_APP_REDIRECT_LOGOUT_URL!.replace("store", restaurant_prefix)}`;
        if (restaurant_prefix === 'admin') return baseUrl+'login';
        return `${baseUrl}?store=${storeId}&table=${tableId}&tableNumber=${tableNumber}`;
    }

    useEffect(() => {
        
        if (storeId && tableId && restaurant_prefix) {
            const logoutUrl = getLogoutUrl()
            const timeout = setTimeout(() => {
                window.location.replace(logoutUrl);
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [getCookie]);

    return (<LoaderPage text="Logging out"/>);
}
    // useEffect( () => {
    //     const store = localStorage.getItem('store');
    //     if (store) {
    //         const timeout = setTimeout(() => {
    //             window.location.replace(process.env.REACT_APP_REDIRECT_LOGOUT_URL!.replace("store", store));
    //         }, 100);
    //         return () => clearTimeout(timeout);
    //     }
    // }, []);
    // return (<LoaderPage text="Logging out"/>)
