import { Snackbar, Alert, AlertColor } from '@mui/material';
import React, { useEffect, useState } from 'react';

type UserSnackbarProps = {
    message: string;
    type: AlertColor;
};

const UserSnackbar: React.FC<UserSnackbarProps> = ({ message, type }) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(true);
    }, [message, type]);

    const handleClose = () => {
        setOpen(false);
    };

    if (!message.length) return <></>
    return (
            <Snackbar sx={{bottom: '20%'}} open={open} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
    );
};

export default UserSnackbar;
