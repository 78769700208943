import { Avatar, Chip, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCustomerFavorites } from "../../../../redux/customerFavourites/CustomerFavouritesSelectors";
import { VegNonVegIcon } from "../common/CustomIcons";
import { ITEM_DETAIL_PATH } from "../../../../constants/pathConstants";
import { MenuItemPrice, MenuItemTitle } from "../landing/DineInLandingPageNavBar";
import { useMenuItemsQuery } from "../../../../hooks/useMenuItemsQuery";
import { CustomerFavorite } from "../../../../redux/customerFavourites/CustomerFavouritesSlice";
import { useState, useEffect } from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import LocalPizzaRoundedIcon from '@mui/icons-material/LocalPizzaRounded';
import { LoaderPage } from "../../../rootDomain/LoderPage";

interface AugmentedFavourites extends CustomerFavorite {
    image_url: string;
}

export const UserFavourites: React.FC = () => {
    const navigate = useNavigate();
    const userFavourites = useSelector(selectCustomerFavorites);
    const {data: foodItems, isLoading} = useMenuItemsQuery();
    const [favouritesToShow, setFavouritesToShow] = useState<AugmentedFavourites[]>([]);
    useEffect (()=> {
        if (foodItems && foodItems.length && userFavourites.length) {
            const augmented = userFavourites.map(favourite => ({...favourite, image_url: foodItems.find(item => item.id == favourite.dish_item_id)?.dishImageURL || ""}))
            setFavouritesToShow(augmented)
        }
    }, [userFavourites, foodItems]);
    if (isLoading) {
        return <LoaderPage text="loading favourites.."/>
    }
    return (
        <Stack padding={2} paddingLeft={3} paddingRight={3}>
            <Chip
                    avatar={<ArrowCircleLeftIcon/>}
                    label="Back"
                    variant="outlined"
                    sx={{
                        width: '100px',
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                    onClick={()=>{navigate("/profile")}}
                />
            <List>
                        {!userFavourites.length ? <>No favourites to show..</>: (favouritesToShow.map((item, index) => (
                            <ListItem alignItems="flex-start" disableGutters={true} sx={{backgroundColor: '#ffffff!important'}} divider={true} onClick={()=>{navigate(ITEM_DETAIL_PATH.replace(":itemId", item.dish_item_id))}} secondaryAction={<KeyboardDoubleArrowRightIcon sx={{color: '#6750A4', flex: 'auto', alignSelf:'center'}}/>}>
                                <ListItemAvatar><Avatar src={item.image_url} variant="square"><LocalPizzaRoundedIcon/></Avatar></ListItemAvatar>
                                <ListItemText
                                    sx={{backgroundColor:'#ffffff', color:'black'}}
                                    primary={<MenuItemTitle sx={{display: 'flex', alignItems:'center', lineHeight:'20px'}}><VegNonVegIcon foodType={'veg'}/> {item.dish_item_name}</MenuItemTitle>} 
                                    secondary={
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <MenuItemPrice> {item.dish_item_price} </MenuItemPrice>
                                        </div>
                                    }
                                    />
                                
                            </ListItem>

                        )))}
            </List>
        </Stack>
    )
}