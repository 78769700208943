import {
	Divider,
	Stack,
	Typography,
	styled
} from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { MENU_ITEM_CUSTOMIZATION_PATH } from '../../../../constants/pathConstants';
import { useFoodItemById } from '../../../../hooks/useFoodItemById';
import { CustomizationStep } from "../../../../interfaces/FoodItem";
import { selectCustomizedFoodState } from '../../../../redux/FoodItemCustomization/customizationSelectors';
import { calculateTotalSum } from '../../../../utils/priceCalculatorUtil';
import { CustomizeFormOptions } from './CustomizeFormOptions';
import CustomizeSubmitSection from './CustomizeSubmitSection';
import { SelectedItemsSection } from './SelectedItemsSection';

export const CustomizeBoldTitle = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '20px',
	fontStyle: 'normal',
	fontWeight: 700,
	lineHeight: 'normal',
	color: '#1C1B1F',
}));
export const CustomizePageTitle = styled(Typography)(({ theme }) => ({
	fontFamily: 'Google Sans',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: 'normal',
	color: '#79747E',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#B3261E',
}));

export const ScrollToFieldError:React.FC = () => {
	const { submitCount, isValid, errors } = useFormikContext()
  
	useEffect(() => {
		if (isValid) return
		if (!errors) return
		const fieldErrorNames = Object.keys(errors)[0];
		console.log(fieldErrorNames)
		if (fieldErrorNames.length <= 0) return

		const element = document.querySelector(
			`input[name='${fieldErrorNames}']`
		)
		if (!element) return
		element.scrollIntoView({ behavior: "smooth", block: "center" })
	}, [submitCount])
  
	return <React.Fragment></React.Fragment>
}


const FoodCustomizationForm: React.FC = () => {
	const dispatch = useDispatch();
	// const formikContext = useFormikContext();
	const { itemId, stepId, choiceId } = useParams();
	const foodItem = useFoodItemById(itemId || "") 
	const [ totalPriceWithCurrency, setTotalPriceWithCurrency ] = useState<string>();
	// const [foodItem, setFoodItem] = useState<FoodItem | undefined>(undefined);
	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState<{ [field: string]: any } >({});
	const [customizationStep, setCustomizationStep] = useState<CustomizationStep | undefined>(undefined);
	const [customizationSteps, setCustomizationSteps] = useState<CustomizationStep[] | undefined>(undefined);
    const selectedCustomization = useSelector(selectCustomizedFoodState);
	const [currentValidationSchema, setCurrentValidationSchema] = useState<Record<string, any> | undefined>(undefined);
	// const [currenValidationSchemaMap, setCurrentValidationSchemaMap] = useState<Record<string, Record<string, any>> | undefined>(undefined);
	const [divRefs, setDivRefs] = useState<React.RefObject<HTMLDivElement>[]>([]); // State to store the divRefs

	useEffect(() => {
		if (foodItem && itemId) {
			const addOnPrice: string[] = [foodItem.dishPriceText]
			if (selectedCustomization.choices[itemId] && selectedCustomization.choices[itemId].length) {
				selectedCustomization.choices[itemId].forEach( step => {
					step.stepOptions.forEach( choice => {
						choice.items.forEach( item => {
							addOnPrice.push(item.price);
						})
					})
				});
			}
			setTotalPriceWithCurrency(calculateTotalSum(addOnPrice));
		}
	}, [selectedCustomization.choices, foodItem]);

	useEffect(() => {
		if (itemId && stepId && customizationSteps) {
			const lastInvalidStep = getLastInvalidStep();
			if (lastInvalidStep) navigate(MENU_ITEM_CUSTOMIZATION_PATH.replace(":itemId", itemId).replace(":stepId", lastInvalidStep))
		}
	}, [itemId, stepId, customizationSteps, selectedCustomization.choices])
	
  

	useEffect(() => {
		if (choiceId) {
			scrollToDiv(choiceId)
		}
	  }, [choiceId, divRefs]);

	useEffect(() => {
		//if(foodItems != null && itemId != null && stepId != null) {
		if (foodItem && itemId != null && stepId != null) {
			// setFoodItem(foodItems.filter(item => item.id == itemId)[0])
			const customizationOptions = foodItem.customizeOptions ? [...foodItem.customizeOptions] : undefined;
			// const customizationOptions = foodItems.filter(item => item.id == itemId)[0].customizeOptions;
			if (!customizationOptions) return;
			setCustomizationSteps(customizationOptions)
			const customizationStepCopy = customizationOptions.filter(customizationOption => customizationOption.step.toString() == stepId)[0]
			setCustomizationStep(customizationStepCopy);
			const refs = customizationStepCopy.stepOptions.map(() => React.createRef<HTMLDivElement>());
			setDivRefs(refs);
			const validationSchemaMapConst = createValidationSchemaMap(customizationOptions, customizationStepCopy);
			// setCurrentValidationSchemaMap(validationSchemaMapConst)
			
			// dispatch(removeCurrentPage({mainItemId: itemId, stepId}));
			if (validationSchemaMapConst) {
				const currentSchema = validationSchemaMapConst[customizationStepCopy.step.toString()];
				setCurrentValidationSchema(currentSchema);
			}
		}
	}, [itemId, stepId, foodItem])

	useEffect(() => {
		if (currentValidationSchema && itemId && customizationStep) {
			const initialState = getInitialValuesFromState(itemId in selectedCustomization.choices ? selectedCustomization.choices[itemId] : [], customizationStep)
			setInitialValues(initialState)
		}
	}, [currentValidationSchema])

	const getLastInvalidStep = () : string | undefined => {
		for (let i = 0; i < customizationSteps!.length; i++) {
			const step = customizationSteps![i];
			if (step.step.toString() == stepId) break;
			if (!selectedCustomization.seenPages[itemId!] || !selectedCustomization.seenPages[itemId!].filter(page => page == step.step.toString()).length) return step.step.toString();
		};
		return undefined;
	}

	const getValueFromReduxStore = (chosenCustomizationSteps: CustomizationStep[], stepId: string, choiceId: string, isList: boolean): string | string[] => {
		const step = chosenCustomizationSteps.filter(chosenCustomizationStep => chosenCustomizationStep.step.toString() == stepId);
		if (!step.length) return isList ? [] : '';
		const chosenItem = step[0].stepOptions.filter(choice => choice.id == choiceId);
		if (!chosenItem.length) return isList ? [] : '';
		if (!chosenItem[0].items.length) return isList ? [] : '';
		return isList ? chosenItem[0].items.map(item => item.id) : chosenItem[0].items[0].id;
	}

	const getInitialValuesFromState = (chosenCustomizationSteps: CustomizationStep[], customizationStep: CustomizationStep) => {
		const values: { [field: string]: any } = {};
		customizationStep.stepOptions.forEach((choice) => {
			values[choice.id] = getValueFromReduxStore(chosenCustomizationSteps, stepId!, choice.id, !(choice.maxSelect == 1 && choice.minSelect == 1));
		})
		return values
	}

	const createValidationSchemaMap = (customizationSteps: CustomizationStep[], customizationStep: CustomizationStep) => {
		const schemaObjMap: Record<string, Record<string, any>> = {};
		for (var i = 0; i < customizationSteps.length; i++) {
			const currentStep = customizationSteps[i];
			const schemaObj: Record<string, any> = {};
			currentStep.stepOptions.forEach((choice) => {
				if (choice.minSelect == 1 && choice.maxSelect == 1) {
					schemaObj[choice.id] = Yup.string().required('Select an option')
				} else {
					schemaObj[choice.id] = Yup.array()
						.of(Yup.string())
						.min(choice.minSelect, `Select at least ${choice.minSelect} option(s)`)
						.max(choice.maxSelect, `Select up to ${choice.maxSelect} option(s)`)
				}
			})
			schemaObjMap[currentStep.step] = schemaObj;
		}	
		return schemaObjMap;
	};

	const handleIntentionalScrollOnSameRoute = (id: string) => {
		scrollToDiv(id);
	}

	const scrollToDiv = (id: string) => {
		const targetRef = divRefs.find((ref) => ref.current?.id === id);
		if (targetRef) {
		  targetRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleSubmit = () => {
		console.log("hi submitted")
	}
	  
	return ( customizationStep == null || stepId == null || customizationSteps == null || itemId == null || foodItem == null ? <>hu</>:
		<Formik
			// initialValues={{}}
			initialValues={initialValues}
			validationSchema={Yup.object().shape(currentValidationSchema!)} // Using the first step's options for simplicity
			onSubmit={handleSubmit}
			validateOnChange={true}
			validateOnBlur={true}
			enableReinitialize={true}
			validateOnMount={true}
		>
			<React.Fragment>
				<Stack padding={3} spacing={3} sx={{overflowY: 'scroll'}}>
					<CustomizePageTitle variant='h4'>{foodItem?.dishName} &#8226; {totalPriceWithCurrency}</CustomizePageTitle>
					<CustomizeBoldTitle variant='h2' gutterBottom={true}>Customize as per your taste</CustomizeBoldTitle>
					{selectedCustomization.choices[itemId!] && <><SelectedItemsSection mainItemId={itemId!} sameRouteScrollHandler={handleIntentionalScrollOnSameRoute}/><Divider sx={{marginY: 2}}/></>}

						<Form>
							<Stack key={customizationStep.step} spacing={3}>
								{ customizationStep.stepOptions.map((choice, i) => ( 
									<React.Fragment>
										<div id={choice.id} ref={divRefs[i]} key={choice.id}>
											<CustomizeFormOptions choice={choice} key={choice.id} stepId={customizationStep.step.toString()} mainItemId={itemId!}  />
										</div>
										{/* <ErrorMessage name={choice.id}>
											{(msg) => <FormHelperText error><ErrorText>{msg}</ErrorText></FormHelperText>}
										</ErrorMessage> */}
									</React.Fragment> ))}
							</Stack>
							<ScrollToFieldError/>
						</Form>
				</Stack>
				<CustomizeSubmitSection customizationSteps={customizationSteps} step={stepId} itemId={itemId} foodItem={foodItem}/>
			</React.Fragment>
		</Formik>
  );
};

export default FoodCustomizationForm;