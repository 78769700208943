import { useMutation, useQueryClient } from 'react-query';

import { v4 as uuidv4 } from 'uuid';
import { useSnackbarContext } from '../restaurantAdmin/contexts/SnackbarContext';
import axiosInstance from '../common/axiosInstance';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface MutationProps {
    variables: any;
    invalidate?: boolean;
    optimisticUpdate?: boolean;
    key: string[]
}
export const useBaseMutation = (
    method: 'put' | 'delete',
    endpoint: string,
    transformData?: (data: any) => any,
    redirectUrl?: string
) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { setSnackbarMessage } = useSnackbarContext();

    const mutationFn = async (mutationProps: MutationProps) => {
        setIsSubmitting(true);
        let response;
        const { variables } = mutationProps;
        if (method === 'put') {
            const transformedData = transformData ? transformData(variables) : variables;
            response = await axiosInstance.put(endpoint, transformedData);
        } else { // method === 'delete'
            response = await axiosInstance.delete(endpoint, { data: variables });
        }
        setIsSubmitting(false);
        return response.data;
    };

    const mutation =  useMutation(mutationFn, {
        onMutate: async (mutationProps) => {
            const { variables, key } = mutationProps
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(key);

            // // Snapshot the previous value
            // const previousData = queryClient.getQueryData(queryKey);

            // // Optimistically update to the new value
            // if (method === 'put') {
            //     queryClient.setQueryData(queryKey, (oldData: any) => {
            //         return { ...oldData, ...variables };
            //     });
            // }
            // // For DELETE, handle accordingly if needed

            // return { previousData };
        },
        onError: (error, mutationProps, context: any) => {
            // Rollback to the snapshot value
            // if (context?.previousData) {
            //     queryClient.setQueryData(queryKey, context.previousData);
            // }
            setSnackbarMessage({ key: uuidv4(), message: 'Operation failed', type: 'error' });
        },
        onSuccess: (data, mutationProps, context) => {
            // Invalidate query to refetch the updated data
            const {key, variables} = mutationProps;
            if (mutationProps.invalidate)
                queryClient.invalidateQueries(key);
            else if(mutationProps.optimisticUpdate)
                queryClient.setQueryData(key, variables);
            setSnackbarMessage({ key: uuidv4(), message: 'Operation successful', type: 'success' });

            if (method === 'delete' && redirectUrl) {
                navigate(redirectUrl);
            }
            return data;
        },
        onSettled: () => {
            setIsSubmitting(false);
        },
    });
    return {...mutation, isSubmitting};
};
