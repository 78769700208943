import { Typography, TextField, Stack } from "@mui/material"
import { useFormikContext } from "formik";
import { RestaurantFormData } from "../hooks/useRestaurantForm";
import { useEffect, useState } from "react";

export const RestaurantPrefixComponent: React.FC = () => {
    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<RestaurantFormData>();
    const [formattedPrefix, setFormattedPrefix] = useState("");

    useEffect(() => {
        const updatedPrefix = values.restaurant_prefix.replace(/[^a-zA-Z0-9]/g, '-')
        setFormattedPrefix(updatedPrefix)
        setFieldValue('restaurant_prefix_final', updatedPrefix);
    }, [values.restaurant_prefix])

    return(
        <Stack>
            <TextField
            label="Restaurant Prefix"
            name="restaurant_prefix"
            value={values.restaurant_prefix}
            onChange={handleChange}
            error={touched.restaurant_prefix && Boolean(errors.restaurant_prefix)}
            helperText={touched.restaurant_prefix && errors.restaurant_prefix}
            />
            {formattedPrefix && <Typography variant='h6'><b>Your site would look like: &nbsp;&nbsp;&nbsp;</b><u>{formattedPrefix}.dine.menufy.link</u></Typography>}
        </Stack>
    );
}