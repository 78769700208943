import { SvgIcon } from "@mui/material";

const VegSvgIcon = ( {marginLeft = 0}) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <rect
            x="1.52701"
            y="1.92032"
            width="17.7997"
            height="17.7997"
            rx="3.34938"
            stroke="#218D0F"
            strokeWidth="1.97346"
          />
          <circle cx="10.4269" cy="10.8201" r="5.13088" fill="#218D0F" />
        </svg>
    );
};

const NonVegSvgIcon = ( {marginLeft = 0}) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <rect
            x="1.52701"
            y="1.92032"
            width="17.7997"
            height="17.7997"
            rx="3.34938"
            stroke="#B3251E"
            strokeWidth="1.97346"
          />
          <circle cx="10.4269" cy="10.8201" r="5.13088" fill="#B3251E" />
        </svg>
    );
};


export const VegNonVegIcon: React.FC<{ foodType: 'veg'| 'non-veg' }> = ({foodType}) => {
    return (
        <SvgIcon>
            {foodType === 'veg' ? <VegSvgIcon/> : <NonVegSvgIcon/>}
        </SvgIcon>
    );
};
