import React, { useState } from 'react';
import { TableRow, TableCell, Box, Collapse } from '@mui/material';
import { TableInfo } from '../pages/ListTableInfo';
import QRCodeGenerator from '../../../components/QRCodeGenerator';
import { useFetchRestaurantInfo } from '../../restaurant/hooks/useFetchRestaurantInfo';

interface TableRowProps {
    table: TableInfo;
}

export const TableRowWithQRCodeComponent: React.FC<TableRowProps> = ({ table }) => {
    const [showQR, setShowQR] = useState(false);
    const { data, isLoading, error } = useFetchRestaurantInfo(table.restaurant_id);
    if (isLoading) return <div>Loading...</div>;
    let restaurantPrefix, restaurantLogo;

    if (data && data.length === 1) {
        restaurantPrefix = data[0].restaurant_prefix_final;
        restaurantLogo = data[0].restaurant_qr_logo;
    }
    
    const url = `https://${restaurantPrefix}.dine.menufy.link?storeId=${table.store_id}&tableId=${table.table_id}&tableNumber=${table.table_number}`;



    const handleRowClick = () => {
        setShowQR(!showQR);
    };

    return (
        <>
            <TableRow hover onClick={handleRowClick}>
                <TableCell>{table.restaurant_name}</TableCell>
                <TableCell>{table.store_name}</TableCell>
                <TableCell>{table.table_number}</TableCell>
                <TableCell><a href={url}>{url}</a></TableCell>
                <TableCell>
                    { showQR ? <QRCodeGenerator url={url} logo={restaurantLogo}/> : <span>Click to view</span> }
                </TableCell>
                    
            </TableRow>
            {/* <TableRow> */}
                {/* <TableCell style={{ paddingBottom:0, paddingTop: 0 }} colSpan={4}> */}
                    {/* <Collapse in={showQR} timeout="auto" unmountOnExit> */}
                        {/* <Box display="flex" justifyContent="center" alignItems="center" width="100%" p={2}>
                            <div style={{ transform: 'scale(1.5)' }}> {/* Scale up the QR code */}
                                {/* {showQR && <QRCodeGenerator url={url} logo={restaurantLogo} />} */}
                            {/* </div>
                        </Box> */}
                    {/* </Collapse> */}
                {/* </TableCell> */}
            {/* </TableRow> */}
        </>
    );
};
