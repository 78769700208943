import { MenuItem, TextField } from "@mui/material";
import { StoreFormData } from "../hooks/useStoreForm";
import { useField, useFormikContext } from "formik";

export interface Currency {
    label: string;
    value: string;
}
export const StoreCurrencyComponent: React.FC = () => {
    const { values, setFieldValue, touched, errors } = useFormikContext<StoreFormData>();

    
    const currencies: Currency[] = [
        {
          value: 'USD',
          label: '$',
        },
        {
          value: 'EUR',
          label: '€',
        },
        {
          value: 'GBP',
          label: '£',
        },
        {
          value: 'AED',
          label: 'AED',
        },
        {
            value: 'INR',
            label: '₹',
        },
    ];

    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedCurrency = currencies.find(currency => currency.value === event.target.value);
        if (selectedCurrency) {
            setFieldValue('store_currency', selectedCurrency.value);
            setFieldValue('store_currency_symbol', selectedCurrency.label);
            console.log(values)
        }
    }
      
    
    return (
        <TextField
            id="select-currency"
            select
            label="Select Currency"
            name="store_currency"
            value={values.store_currency}
            onChange={handleSelectChange}
            helperText={touched.store_currency && errors.store_currency ? "Please select your currency": ''}
            error={touched.store_currency && Boolean(errors.store_currency)}
            fullWidth
        >
          {currencies.map((option) => (
            <MenuItem 
                key={option.value} 
                value={option.value}
                >
               {option.label} - {option.value}
              
            </MenuItem>
          ))}
        </TextField>
    )
}