import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Button, CardMedia, Stack, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MENU_ITEM_CUSTOMIZATION_PATH, MENU_PAGE_ROOT_PATH } from "../../../../constants/pathConstants";
import { useFoodItemById } from "../../../../hooks/useFoodItemById";
import { FoodMenuItem } from "../../../../interfaces/FoodItem";
import { CartItem, addCartItem } from "../../../../redux/cartItems/cartItemsSlice";
import { getCartItemFromFoodItem } from "../../../../utils/CartItemsUtils";
import { MenuItemPrice } from "./MenuFoodItemCard";
import { useCartSession } from "../../../../hooks/useCartSession";
import { AddToCartPayload, persistCartItem } from "../../../../redux/cartItems/cartItemsThunks";
import { AppDispatch } from "../../../../redux/stores/mainStore";
import useCookie from "../../../../hooks/useCookie";
import { useAddToCart } from "../../../../hooks/useAddToCart";
import { FoodMenuItemInput } from "../../../restaurantAdmin/pages/menu/hooks/useMenuForm";
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import { useAddToFavourite } from "../../../../hooks/useAddToFavourite";


const ItemDetailTitle = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontFamily: 'Google Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    color: '#1C1B1F',
    textAlign: 'left'
}));

const ItemDescription = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#625B71',
}));



const ItemDetailPage = () => {
    const { id } = useParams();
    const foodItem  = useFoodItemById(id || "");
    const dispatch = useDispatch<AppDispatch>();
    // const [foodItem, setFoodItem] = useState<FoodItem|undefined>(undefined);
    const navigate = useNavigate();
    const { user } = useAuthenticator((context) => [context.route]);
    const email: string = user.attributes?.email || '';
    const name: string = user.attributes? user.attributes['given_name'] : "John Doe";

    const {data: cartSessionInfo, isLoading, error} = useCartSession();
    const {getCookie} = useCookie();
    const table_id = getCookie('tableId') || "";
    const {addToCartHandler, isCartSessionLoading} = useAddToCart(email, name);
    const [isFavorite, handleFavoriteClick] = useAddToFavourite();


    if (isLoading) return <>wait...</>
    // const navigate = useNavigate();
    
    const addToCartButtonHandler = (id: string, item: FoodMenuItem) => {
        addToCartHandler(id, item);
        navigate('/menu');
        // if (item.customizeOptions && item.customizeOptions.length > 0) {
        //     navigate(MENU_ITEM_CUSTOMIZATION_PATH.replace(':itemId', item.id).replace(":stepId", item.customizeOptions[0].step.toString()))
        // }
        // else {
        //     const cartItem: CartItem = getCartItemFromFoodItem(item, email, name)
        //     const mainItemId = item.id;


        //     const payload: AddToCartPayload = {
        //         // You need to fill these details based on your app's state or arguments
        //         cart_session_id: cartSessionInfo?.cart_session_id || "",
        //         store_id: cartSessionInfo?.store_id || "",
        //         email_id: email,
        //         table_id: table_id,
        //         cart_item_id: cartItem.cartItemId,
        //         cartItem: cartItem,
        //         // timeStamp is optional, add if needed
        //     };
        
        //     // Dispatch the async thunk to call the API
        //     dispatch(persistCartItem(payload));
        //     console.log('calliong persist item...')
        //     dispatch(addCartItem({mainItemId, cartItem}));
        //     navigate(MENU_PAGE_ROOT_PATH)
        // }
        
    }
    // useEffect(() => {
    //     if(foodItems != null && id != null) {
    //         setFoodItem(foodItems.filter(item => item.id == id)[0])
    //         if (!foodItem) {
    //             console.log("bhakk bsdk")
    //         }
    //     }
    // }, [foodItems, id]
    // const isFavorite = (foodItem: FoodMenuItem) => {
    //     return true;
    // }
    return (
        <Stack direction="column" sx={{overflowY: 'auto', height: '100%'}}>
            {id && foodItem && (
                <>
                    <CardMedia sx= {{ height: '50%', borderBottom: '1px solid #625B71'}} component="img" image={foodItem?.dishImageURL} title={foodItem?.dishImageAltText} />
                    {isFavorite(foodItem) ? <FavoriteRoundedIcon
                                sx={{
                                  position: 'absolute',
                                  top: 8, // Adjust the position as needed
                                  right: 8, // Adjust the position as needed
                                  color: '#ff02a7ba', // Adjust color as needed
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: for better visibility
                                  borderRadius: '50%',
                                  padding: '4px',
                                }}
                                onClick={() => handleFavoriteClick(foodItem, 'remove')}
                              />: <FavoriteBorderRoundedIcon
                                sx={{
                                  position: 'absolute',
                                  top: 8, // Adjust the position as needed
                                  right: 8, // Adjust the position as needed
                                  color: 'white', // Adjust color as needed
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: for better visibility
                                  borderRadius: '50%',
                                  padding: '4px',
                                }}
                                onClick={() => handleFavoriteClick(foodItem, 'add')}
                              />}
                    <Stack direction="column" padding={3} gap={1} sx={{position: 'relative', flex:"1"}}>
                        <ItemDetailTitle variant='subtitle1'> {foodItem?.dishName} </ItemDetailTitle>
                        <MenuItemPrice variant='subtitle2'>{ foodItem?.dishPriceText } </MenuItemPrice>
                        <ItemDescription variant='body1' sx={{flex: "1", marginBottom:'1rem'}} gutterBottom>{ foodItem?.dishDescription }</ItemDescription>
                        <Box sx={{ boxSizing: 'border-box', position: 'sticky', bottom: '0'}}>
                            <Button sx={{ height: '50px', padding: '10px 24px', borderRadius: '25px', position: 'relative', bottom: '10px', width: '100%',
                                backgroundColor: '#6750A4', color: 'white', textTransform: 'none', ':hover': {backgroundColor: '#6750A4', color: 'white'} }} onClick={()=>{ addToCartButtonHandler(id, foodItem) }} disabled={isCartSessionLoading}>ADD</Button>
                        </Box>
                    </Stack>
                </>) }
        </Stack>
    )
}
export default ItemDetailPage