// hooks/useItemsQuery.ts
import { useQuery } from 'react-query';
import axios from 'axios';
import { useMenuItemsQuery } from './useMenuItemsQuery';
import { useOffersQuery } from './useOffersQuery';
import { OfferCard } from '../interfaces/offers';


export const useOfferIdCards = () : OfferCard[] => {
    const { data: foodItems} = useMenuItemsQuery() || [];
    const {data: offers} = useOffersQuery() || [];
    if (!foodItems || !offers) return [];
    const allOffers = new Set();
    for (let i = 0; i < foodItems.length; i++) { 
        for(let j = 0;  j < (foodItems[i].homePageOffers?.length || -1); j++) {
            allOffers.add(foodItems[i].homePageOffers![j]);
        }
    }
    return offers.filter(offer => allOffers.has(offer.offerId));
}
