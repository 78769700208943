import { useLocation } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/BreadCrumbsComponent";
import ListOperations from "../../../components/ListOperations"

export const StoreOperations = () => {

    const storeOperations = [
        { name: "List Stores", link: "/stores/list" },
        { name: "Create New Store", link: "/stores/create" },
    ];
    
    return (
        <ListOperations title="Stores" operations={storeOperations} />
    )
}