import { AppBar, Box, Container, CssBaseline, Drawer, Stack, Toolbar, Typography } from "@mui/material"
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RestaurantAdminLogin = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const checkUserAuthentication = async () => {
            try {
                await Auth.currentAuthenticatedUser();
                navigate('/'); // Redirect to home if user is already logged in
            } catch (error) {
                console.log('Not authenticated:', error);
            }
        };

        checkUserAuthentication();
    }, [navigate]);
    return (
        <Stack>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                    Admin Portal
                    </Typography>
                </Toolbar>
            </AppBar>

            <Stack padding={10}>
                <Container>
                        <span>Hey There, do have a look at our plans or if you are the admin log in here</span>
                        <Typography
                            style={{
                                fontStyle: 'normal',
                                fontFamily: 'Google Sans',
                                fontWeight: 700,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#1C1B1F',
                                marginTop:'50px',
                                textAlign:'left'
                            }}>Login Using</Typography>
                        <a href = {process.env.REACT_APP_LOGIN_URL}  ><button type="button" className="google-button">
                            <span className="google-button__text">Google</span>
                        </button>
                        </a>
                </Container>
            </Stack>
        </Stack>
    )
}