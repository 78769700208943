import { DineInProfilePageNavBar } from "./DineInProfilePageNavBar"
import { DineInStickyFooter } from "../common/DineInStickyFooter"
import { DineInUserProfileOptions } from "./DineInUserProfileOptions"
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";

export const DineInProfilePage = () => {
    const { route, user } = useAuthenticator((context) => [context.route]);
    return(
        <Stack>
            <DineInProfilePageNavBar user={user}/>
            <DineInStickyFooter page='profile'/>
        </Stack>
    )
}
