import { Box, Divider, IconButton, Stack } from "@mui/material"
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik"

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useEffect } from "react";
import { DishesListItem } from "./DishesListItem";
import { FoodMenuItemInput, getNewMenuItem } from "../../../hooks/useMenuForm";
import { useNavigate, useParams } from "react-router-dom";


export const DishesSideList: React.FC = () => {

    const navigate = useNavigate();
    const { id, foodMenuItemId } = useParams<{ id: string, foodMenuItemId: string | undefined }>();
    const {values, setFieldValue} = useFormikContext<{foodMenuItemsInputs: FoodMenuItemInput[]}>();

    const reRankAndSort = async (foodMenuItemsInputs: FoodMenuItemInput[]) => {
        for(let i = 0; i < foodMenuItemsInputs.length; i++) {
            foodMenuItemsInputs[i].rank = i;
        }
        foodMenuItemsInputs.sort((a,b)=> a.rank - b.rank);
    }
    const handleAdd = async () => {
        // arrayHelpers.push({ name: 'Add', id: uuidv4() });

        const last = values.foodMenuItemsInputs.length;
        const newItem = getNewMenuItem(last);
        const copyList = [newItem].concat(...values.foodMenuItemsInputs);
        reRankAndSort(copyList);
        await setFieldValue('foodMenuItemsInputs', copyList);
        navigate(`/menu/stores/${id}/editItem/${newItem.id}`);
    };

    const handleRemove = async () => {
        const index = values.foodMenuItemsInputs.findIndex(item => item.id === foodMenuItemId);
        if (index >= 0) {
            const copyList = [...values.foodMenuItemsInputs.filter(item => item.id != foodMenuItemId)];
            reRankAndSort(copyList);
            await setFieldValue('foodMenuItemsInputs', copyList);
            navigate(`/menu/stores/${id}`);
        }
    };


    const movePosition = (index: number, direction: 'up' | 'down') => {
        const nextIndex = index + (direction == 'up' ? -1 : 1);
        const copyList = [...values.foodMenuItemsInputs]
        if (nextIndex >= copyList.length || nextIndex < 0) return;
        const currentValue = copyList[index];
        const nextValue = copyList[nextIndex];

        currentValue.rank = nextIndex;
        nextValue.rank = index;

        copyList[nextIndex] = currentValue;
        copyList[index] = nextValue;
        copyList.sort((a, b)=> a.rank - b.rank);
        setFieldValue('foodMenuItemsInputs', copyList);
    }


    useEffect(() => {
        console.log(values);
    }, [values]);
    return (
        <Stack spacing={1}>
            <h4>Dishes</h4>
                <FieldArray
                    name="foodMenuItemsInputs"
                    render={arrayHelpers => (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, marginBottom: 2 }}>
                                <IconButton
                                    aria-label="add"
                                    onClick={handleAdd}
                                    sx={{ border: '1px solid black' }}
                                >
                                    <AddRoundedIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="add"
                                    onClick={handleRemove}
                                    sx={{ border: '1px solid black' }}
                                >
                                    <RemoveRoundedIcon />
                                </IconButton>
                            </Box>
                            <Stack spacing={0} divider={<Divider sx={{marginY: 2}}/>}>
                                {values.foodMenuItemsInputs && values.foodMenuItemsInputs.map((item: any, index: any) => (
                                    <DishesListItem
                                        key={item.id}
                                        index={index}
                                        movePosition={movePosition}
                                        onClick={() => navigate(`/menu/stores/${id}/editItem/${item.id}`)}
                                        foodMenuItemInput={item} />
                                    // <CategoryListItem
                                    //     key={item.id}
                                    //     index={index}
                                    //     isEditMode={item.id == selectedItem}
                                    //     onClick={() => handleItemClick(item.id, index)}
                                    //     categoryFormValue={item} />
                                ))}
                            </Stack>
                        </>
                    )} />
        </Stack>
    )
}