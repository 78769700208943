import { Grid, Skeleton, Stack } from "@mui/material"

export const CreateUpdateStoreSkeleton = () => {
    return(
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Skeleton variant="rectangular" width={400} height={40} />
                <Skeleton variant="rectangular" width={400} height={40} />
                <Skeleton variant="rectangular" width={400} height={40} />
            </Grid>
            <Grid item xs={6}>
                <Stack spacing={2}>
                    <Skeleton variant="rectangular" width={400} height={40} />
                    <Skeleton variant="rectangular" width={400} height={40} />
                    <Skeleton variant="rectangular" width={400} height={40} />
                </Stack>
            </Grid>
        </Grid>
    )
}