// export const ListRestaurantPage = () => {
    
//     return (
//         <>
//             here is the list
//         </>
//     )
// }


import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box } from '@mui/material';
import { useFetchUserRestaurants } from '../hooks/useFetchUserRestaurants';
import { ListRestaurantSkeloton } from './ListRestaurantSkeleton';
import { ListStoreSkeleton } from '../../stores/pages/ListStoresSkeletonPage';

export interface RestaurantsInfo {
    restaurant_name: string;
    restaurant_id: string;
}

const ListRestaurantPage = () => {
    
    const [searchText, setSearchText] = useState('');
    const { data, isLoading } = useFetchUserRestaurants();

    const navigate = useNavigate();
    const userRestaurants = data as RestaurantsInfo[];


    // Filter restaurants by search text
    const filteredRestaurants = useMemo(() => {
        if (userRestaurants) {
            return userRestaurants.filter(restaurant => 
                restaurant.restaurant_name.toLowerCase().includes(searchText.toLowerCase()));
        } else return [];
    }, [userRestaurants, searchText]);

    // Handle row click
    const handleRowClick = (restaurantId: string) => {
        navigate(`/restaurants/${restaurantId}`);
    };

    if (isLoading) {
        return <ListStoreSkeleton count={30}/>
    }

    return (
        <>
            <Box mb={2}>
                <TextField 
                    label="Search by Name" 
                    variant="outlined" 
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Restaurant Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRestaurants.map((restaurant) => (
                            <TableRow key={restaurant.restaurant_id} hover onClick={() => handleRowClick(restaurant.restaurant_id)}>
                                <TableCell component="th" scope="row">
                                    {restaurant.restaurant_name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ListRestaurantPage;
