
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { FoodMenuItem } from '../../../../../interfaces/FoodItem';
import { useMenuItemsQuery } from '../../../../../hooks/useMenuItemsQuery';
import { getFoodItemsBySection } from '../../../../../hooks/useFoodItemSections';
import { parseValueFromString } from '../../../../../utils/priceCalculatorUtil';
import { useParams } from 'react-router-dom';

export interface FoodMenuItemInput extends FoodMenuItem {
  rank: number;
  dishRating: number;
  dishRatedBy: number;
  food_image_input_url?: string;
  food_image_input_url_key?: string;
  dishPrice: number;
}

export const customizeOptionSchema = Yup.object().shape({
    step: Yup.number().required('Step is required'),
    stepOptions: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        title: Yup.string().required('title is needed'),
        minSelect: Yup.number().required('minSelect is needed'),
        maxSelect: Yup.number().required('maxSelect is needed'),
        truncateAt: Yup.string().required('truncate is needed'),
        items: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string().required('name is needed'),
            price: Yup.string().required('price text is needed'),
            priceNumber: Yup.number().required('price is needed'),
            dishType: Yup.string().required('dish type is needed'),
          })
        ).required('atleast one Item are required'),
      })
    ).required('Step options are required'),
});

export const getNewMenuItem = (rank: number): FoodMenuItemInput => {
    return {
        id: uuidv4(),
        dishName: 'New Item here',
        sections: [],
        dishType: 'veg',
        dishPriceText: '0',
        dishRatingText: '0',
        dishRatedByText: '0',
        dishRating: 0,
        dishRatedBy: 0,
        dishPrice: 0,
        dishDescription: 'some description',
        dishImageURL: '',
        dishImageAltText: 'some image',
        customizeOptions: [],
        rank,
        food_image_input_url: "",
        food_image_input_url_key: ""
    }
}

export const menuItemSchema = Yup.object().shape({
    id: Yup.string().required('ID is required'),
    dishName: Yup.string().required('Dish name is required'),
    sections: Yup.array().of(Yup.string()).min(1, 'The dish must be present in one category atleast').required('Sections are required'),
    dishType: Yup.string().oneOf(['veg', 'non-veg'], 'Dish type must be either "veg" or "non-veg"').required('Dish type is required'),
    dishPriceText: Yup.string().required('Dish price text is required'),
    dishPrice: Yup.string().required('Dish price is required'),
    dishRating: Yup.number().required('Dish rating number is required'),
    dishRatedBy: Yup.number().required('Dish rated by number is required'),
    dishRatingText: Yup.string().required('Dish rating text is required'),
    dishRatedByText: Yup.string().required('Dish rated by is required'),
    dishDescription: Yup.string().required('Dish description is required'),
    dishImageURL: Yup.string().url('Must be a valid URL').optional(),
    dishImageAltText: Yup.string().required('Dish image alt text is required'),
    food_image_input_url: Yup.string().optional(),
    food_image_input_url_key: Yup.string().optional(),
    landingPageTags: Yup.array().of(
      Yup.object().shape({
        group: Yup.string().required(),
        groupId: Yup.string().required(),
        subgroup: Yup.string().optional(),
        subgroupId: Yup.string().optional(),
      })
    ).optional(),
    customizeOptions: Yup.array().of(customizeOptionSchema).optional(),
});

export const storeDineInMenuSchema = Yup.array().of(menuItemSchema);

export const useStoreDineInMenuForm = (
    onSubmit: (values: FoodMenuItemInput[], formikHelpers: FormikHelpers<FoodMenuItemInput[]>) => void | Promise<any>,
    menuData: FoodMenuItemInput[] | null,
) => {
    const [initialFormValues, setInitialFormValues] = useState<FoodMenuItemInput[]>([]);
  
    useEffect(() => {
        if (menuData) {
            setInitialFormValues(menuData);
        }
    }, [menuData]);
  
    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: storeDineInMenuSchema,
        onSubmit: onSubmit,
        enableReinitialize: true,
        validateOnChange: true
    });
    return formik;
};

export interface CategoryFormValue {
  name: string;
  id: string;
}

export interface FoodItemMenuFormValues {
  categories: Array<CategoryFormValue>;
  foodMenuItemsInputs: Array<FoodMenuItemInput>;
}


const validationSchema =  Yup.object().shape({
  categories: Yup.array().of(
      Yup.object().shape({
          name: Yup.string().required('Category name is required'),
          id: Yup.string().required('Category id is required'),
      })
  )
  .min(1, 'At least one category is required')
  .test('unique-names', 'Category names must be unique', categories => {
      const names = (categories || []).map(cat => cat.name);
      return new Set(names).size === names.length;
  }),
  foodMenuItemsInputs: storeDineInMenuSchema,
});


export const getCategories = (foodMenuItems: FoodMenuItem[]) => {
  const sectionsGot = getFoodItemsBySection(foodMenuItems);
  const sections = sectionsGot.map(sectionGot => ({name: sectionGot.title, id: uuidv4()}))
  return sections;
}


export const useFoodMenuForm  =  (): [FoodItemMenuFormValues, typeof validationSchema] => {

  const [initialValues, setInitialValues] = useState<FoodItemMenuFormValues>({ categories: [], foodMenuItemsInputs: [] });
  const { id: storeId } = useParams() || "";
  const {data: foodItems, isLoading, status} = useMenuItemsQuery(storeId);

  
  useEffect(() => {
      if (foodItems && foodItems.length) {
          // const sectionsGot = getFoodItemsBySection([...foodItems]);
          console.log(foodItems);
          setInitialValues({
              categories: getCategories([...foodItems]), //sectionsGot.map(sectionGot => ({name: sectionGot.title, id: uuidv4()})),  
              foodMenuItemsInputs: foodItems.map((f, i) => ({...f, rank : i, food_image_input_url: '', food_image_input_url_key: '', dishPrice: parseValueFromString(f.dishPriceText), dishRatedBy: 0, dishRating: 0, dishRatingText: f.dishRatingText || '0', dishRatedByText: f.dishRatedByText || '0'
            //   customizeOptions: f.customizeOptions?.map(option => ({
            //     ...option,
            //     stepOptions: option.stepOptions.map(stepOption => ({
            //       ...stepOption,
            //       items: stepOption.items.map(item => ({...item, priceNumber: parseValueFromString(item.price)}))
            //     }))
            //   })) || []
            }))
      })
    }
  }, [foodItems])
  return [initialValues, validationSchema]

};