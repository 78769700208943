import { Stack, TableContainer, Box, TextField, Table, TableHead, TableCell, TableRow, TableBody,Paper, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material"
import { useFetchStoreUsers,  } from "../hooks/useFetchStoreUsers";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { StoreColleagueFormData, UserRole, useAddColleagueForm } from "../hooks/useAddColleagueForm";
import { usePutUserToStore } from "../hooks/usePutUserToStore";
import { useFetchStoreInfo } from "../../stores/hooks/useFetchStoreInfo";
import { useBreadcrumb } from "../../../contexts/BreadcrumbContext";
import { useFetchUserStores } from "../../stores/hooks/useFetchUserStores";
import { StoresInfo } from "../../stores/pages/ListStoresPage";
export interface StoreUsers {
    email: string;
    user_email: 'MANAGER' | 'WAITER' | 'KITCHEN';
    role: string;
    user_nick_name: string;
    updated_by: string;
    updated_on: string;
}

export const AddListUsers: React.FC = () => {
    const {id: storeId} = useParams() || null;
    const {data: storeData, isLoading: isStoreDataLoading} = useFetchStoreInfo(storeId);
    const {setBreadcrumbName, setHideBreadCrumbLabelsForLocation} = useBreadcrumb();
    const { data, isLoading } = useFetchStoreUsers(storeId);
    const [storeUsers, setStoreUsers] = useState<StoreUsers[] | undefined>(undefined);
    
    const { data: userStores, isLoading: isUserStoresLoading } = useFetchUserStores();
    const [storeInfo, setStoreInfo] = useState<StoresInfo | undefined>(undefined);
    const { mutate: putUserToStore, isSubmitting, isSuccess } = usePutUserToStore();


    useEffect(() => {
        if(isSuccess)
            formik.resetForm();
    }, [isSuccess]);

    useEffect(() => {
        if (userStores && userStores.length && storeId) {
            const userStoresInfo = userStores as StoresInfo[];
            const userStoreInfo = userStoresInfo.filter(userStore => userStore.store_id === storeId);
            if (userStoreInfo && userStoreInfo.length) setStoreInfo(userStoreInfo[0]);
        }
    }, [userStores, storeId])

    useEffect(() => {
        if (storeId && storeData && storeData.length == 1) {
            setBreadcrumbName(storeId, storeData[0].store_name);
            setHideBreadCrumbLabelsForLocation(["stores"])
        }
    }, [storeId, isLoading])

    const handleSubmit = async (values: StoreColleagueFormData) => {
        if (storeId) {
            const trimmedValues = {
                ...values,
                user_email: values.user_email.trim(),
            };
            putUserToStore({
                variables: trimmedValues,
                key: ['storeUsers', storeId], // Assuming storeId is available in this component
                // invalidate: true,
                invalidate: true,
            });
        }
    }

    const formik = useAddColleagueForm(handleSubmit);

    const updateRole = async (val: any) => {
        await formik.setFieldValue('role', val);
    }

    useEffect(() => {
        if(storeId) {
            formik.setFieldValue('store_id', storeId);
        }
    }, [storeId, formik.submitCount]);

    const [searchText, setSearchText] = useState('');

    const handleCategoryChange = async (event: SelectChangeEvent) => {
        await updateRole(event.target.value);
        console.log(formik.errors);
        console.log(formik.values);
        console.log(formik);
    };

    // Filter restaurants by search text
    const filteredUsers = useMemo(() => {
        if (storeUsers) {
            return storeUsers.filter(user => 
                user.user_nick_name.toLowerCase().includes(searchText.toLowerCase())
            )
        } else return [];
    }, [storeUsers, searchText]);

    useEffect(() => {
        if (data && data.length) {
            console.log(data);
            setStoreUsers(data as StoreUsers[]);
        }
    }, [data]);
    
    if (isLoading || isUserStoresLoading || isStoreDataLoading) return <span> loading...</span>
    
    return (
        <Stack>
            <Box mb={2}>
                <TextField 
                    label="Search by Name" 
                    variant="outlined" 
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User nick name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Added by</TableCell>
                            <TableCell>Added On</TableCell>
                            <TableCell>Add/Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {user.user_nick_name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.user_email}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.role}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.updated_by}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {user.updated_on}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>
                                <TextField 
                                    name="user_nick_name"
                                    label="nick name"
                                    value={formik.values.user_nick_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="standard"
                                    error={formik.touched.user_nick_name && Boolean(formik.errors.user_nick_name)}
                                    helperText={formik.touched.user_nick_name && formik.errors.user_nick_name}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField 
                                    name="user_email"
                                    label="user email"
                                    value={formik.values.user_email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="standard"
                                    error={formik.touched.user_email && Boolean(formik.errors.user_email)}
                                    helperText={formik.touched.user_email && formik.errors.user_email}
                                />
                            </TableCell>
                            <TableCell>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="role-label-id">Role</InputLabel>
                                        <Select
                                            labelId="role-label-id"
                                            id="demo-simple-select-standard"
                                            value={formik.values.role}
                                            onChange={handleCategoryChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.role && Boolean(formik.errors.role)}
                                            // helperText={formik.touched.role && formik.errors.role}
                                            label="Role"
                                        >
                                            {Object.values(UserRole).filter(v=> v != UserRole.ADMIN).map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>
                            </TableCell>
                            {/* Assuming "Added By" and "Added On" are managed by the backend, so no input fields here */}
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => formik.handleSubmit()}
                                    disabled={!formik.isValid || isSubmitting || (storeInfo?.role != UserRole.MANAGER && storeInfo?.role != UserRole.ADMIN)}
                                >
                                    Add
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
}