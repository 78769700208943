import { Divider, Grid, Skeleton, Stack } from "@mui/material"
import React from "react"

export type MenuPageSkeletonProps = {
    count: number;
}
export const MenuPageSkeleton: React.FC<MenuPageSkeletonProps> = ({ count }) => {
    return (
        <React.Fragment>
            <Stack spacing={2} padding={3}>
                <Skeleton variant="rectangular" height={150} />
                {Array.from(new Array(count)).map((_, index) => (
                    <Stack sx={{border: 'none'}} divider={<Divider sx={{marginY: 2}}/>} spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Skeleton variant="rounded" height={14} width={14} />
                                <Skeleton variant="text" sx={{fontSize: '16px'}}  />
                                <Skeleton variant="text" sx={{fontSize: '16px'}}  />
                                <Skeleton variant="text" sx={{fontSize: '16px'}}  />
                                <Skeleton variant="rectangular" height={20} width={100}  />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rounded" width={150} height={150} />
                            </Grid>
                        </Grid>
                    </Stack>
                ))}
            </Stack>
        </React.Fragment>
    );
}