import React, { useEffect } from "react";
import { TextField, Typography, styled, Box } from "@mui/material";
import { SectionCategory } from "./CategoryList";
import { Field, useField, useFormikContext } from "formik";
import { CategoryFormValue } from "../../../hooks/useMenuForm";

export interface SideListItemProps {
    sectionInfo: SectionCategory;
    editMode?: boolean;
    onClick: () => void;
    index: number
}

const CategoryListText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '16px', // Increased font size for better visibility
    fontWeight: 500, // Make it slightly bolder
    textAlign: 'left',
    color: '#1C1B1F',
    padding: theme.spacing(2), // Add padding for more space
    cursor: 'pointer', // Change cursor to indicate clickability
    '&:hover': {
        backgroundColor: theme.palette.action.hover, // Add hover effect
    }
}));

interface CategoryListItemProps {
    index: number; // Index in the Formik array
    isEditMode: boolean;
    onClick: () => void;
    categoryFormValue: CategoryFormValue
}
  
export const CategoryListItem: React.FC<CategoryListItemProps> = ({ index, isEditMode, onClick, categoryFormValue }) => {

    const [field, , helpers] = useField(`categories.${index}.name`);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        helpers.setValue(event.target.value);
    };
    return (
        <Box sx={{ width: '100%' }} onClick={!isEditMode ? onClick : undefined}> 
            {isEditMode ? (
                // Render a Formik Field as a TextField for editing
                <TextField
                    {...field}
                    onChange={handleChange}
                    label={`Category ${index + 1} Name`}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />
            ) : (
                <CategoryListText>
                    {categoryFormValue.name}
                </CategoryListText>
            )}
        </Box>
    );

};