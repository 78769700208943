import React, { useEffect, useState } from 'react'
import { selectCustomizedFoodState } from '../../../../redux/FoodItemCustomization/customizationSelectors';
import { useSelector } from 'react-redux';
import { string } from 'yup';
import { AddOnItem } from '../../../../interfaces/FoodItem';
import { MENU_ITEM_CHOICES_CUSTOMIZATION_PATH } from '../../../../constants/pathConstants';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Box, Stack, Typography, styled } from '@mui/material';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { useLocation, useNavigate } from 'react-router-dom';

interface SelectedItem {
    stepId: string;
    choiceId: string;
    pageSectionUrl: string;
    item: AddOnItem;
}

export interface SelectedItemsSectionProps {
    mainItemId: string;
    sameRouteScrollHandler: Function
}

const SelectedItemText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Google Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#79747E',
}));

export const SelectedItemsSection: React.FC<SelectedItemsSectionProps> = ({mainItemId, sameRouteScrollHandler}) => {
    const selectedCustomization = useSelector(selectCustomizedFoodState);
    const [itemsChosen, setItemschosen] = useState<SelectedItem[]>([]);
    const location = useLocation();
    const pathName = location.pathname;
    const navigate = useNavigate();
    const getPageSectionUrl = (mainItemId: string, stepId: string, choiceId: string) => {
        return MENU_ITEM_CHOICES_CUSTOMIZATION_PATH.replace(':itemId', mainItemId).replace(":stepId", stepId).replace(":choiceId", choiceId);
    }

    const handleClick = (url: string, choiceId: string) => {
        console.log(url);
        if(pathName == url) {
            sameRouteScrollHandler(choiceId);
        }
        else navigate(url);
    }

    useEffect(()=> {
        const listItems: SelectedItem[] = [];
        selectedCustomization.choices[mainItemId].forEach(step => {
            const stepId = step.step.toString();
            step.stepOptions.forEach(stepOption => {
                const choiceId = stepOption.id;
                stepOption.items.forEach(item => {
                    const selectedItem = item;
                    listItems.push({stepId, choiceId, item: selectedItem, pageSectionUrl: getPageSectionUrl(mainItemId, stepId, choiceId)});
                })

            })
        })
        setItemschosen(listItems);
    }, [selectedCustomization])
    return(
        <Stack key="customizationSection" sx={{border: '1px solid #E4E4E5', borderRadius:'30px'}} padding={3} spacing={1}>
            {
                itemsChosen.map((item) => (
                <Stack direction="row" sx={{'display': 'flex', 'justifyContent': 'space-between', 'alignItems': 'center'}} >
                    <Box sx={{'display': 'flex', 'alignItems': 'center'}}><FiberManualRecordRoundedIcon sx={{color: '#D9D9D9', height:'12px', 'width': '12px'}}/><SelectedItemText>&nbsp;&nbsp;&nbsp;{item.item.name}</SelectedItemText></Box>
                    <Box sx={{'backgroundColor': '#FFD8E4', 'height': '27px', 'width': '27px', 'boxShadow':'0px 1.9285714626312256px 3.857142925262451px 1.4464285373687744px rgba(0, 0, 0, 0.15)', 
                        'borderRadius': '8px', 'display': 'flex', 'justifyContent': 'center','alignItems': 'center'}}><CreateOutlinedIcon onClick={() =>handleClick(item.pageSectionUrl, item.choiceId)}/></Box>
                </Stack>
                ))
            }
        </Stack>
    )
}
