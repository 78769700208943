import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { FieldInputProps, useField, useFormikContext } from "formik"
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { FoodMenuItemInput } from "../../hooks/useMenuForm";
import { useEffect } from "react";
import { usePutStoreMenuItems } from "../../hooks/usePutStoreMenuItems";
import { useParams } from "react-router-dom";

export interface CategoryNameSaveSectionProps {
    index: number
}

export const CategoryNameSaveSection: React.FC<CategoryNameSaveSectionProps> = ({ index }) => {

    const [dishNameField, , dishNameHelpers] = useField(`foodMenuItemsInputs.${index}.dishName`);

    const [dishTypeField, , dishTypeHelpers] = useField(`foodMenuItemsInputs.${index}.dishType`);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dishNameHelpers.setValue(event.target.value);
    };

    const handleCategoryChange = (event: SelectChangeEvent) => {
        dishTypeHelpers.setValue(event.target.value);
    };
    const {id} = useParams();
    const { errors, values } = useFormikContext<{ foodMenuItemsInputs: FoodMenuItemInput[] }>(); 
    const { mutate: updateStoreInfo, isSubmitting } = usePutStoreMenuItems();

    if (!id) return <>waiting for id..</>
    
    const validateAndSubmit = () => {
        updateStoreInfo({variables: {"storeId": id, "foodMenuItemsInput": values.foodMenuItemsInputs}, key: ['storeMenu', id], invalidate: true})
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <InputLabel id="label">Category</InputLabel>
                <Select labelId="label" variant="standard" id="select" value={dishTypeField.value} onChange={handleCategoryChange} sx={{minWidth: 120}}>
                    <MenuItem value="veg">veg</MenuItem>
                    <MenuItem value="non-veg">non-veg</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={6}>
                <InputLabel id="dishName">Dish Name</InputLabel>
                <TextField
                    id="dishName"
                    defaultValue="Dish Name"
                    value={dishNameField.value}
                    onChange={handleChange}
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3}>
                <Button variant="contained" type="submit" onClick={validateAndSubmit} disabled={Object.keys(errors).length > 0 || isSubmitting}>{ isSubmitting ? "Saving..": "Save" }<SaveRoundedIcon/></Button>
            </Grid>
        </Grid>
    )
}